import React, { useContext, useState } from 'react'
import LogoTelkomsel from 'Assets/images/logo-telkomsel.png'
import LogoIndosat from 'Assets/images/logo-indosat.png'
import LogoXL from 'Assets/images/logo-xl.png'
import LogoTri from 'Assets/images/logo-tri.png'
import moment from 'moment'
import { toValue } from 'Utils/conversion'
import BadgeContent from './badgeContent'
import { AuthContext } from 'Context/authContext'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react'

const CardContent = ({ data }) => {
    const { token, newLang } = useContext(AuthContext)
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <div
                className='w-full border border-[#D7D7D7] p-4 rounded-xl cursor-pointer hover:border-gray-500 hover:bg-gray-100'
                onClick={onOpen}
            >
                <div className='grid grid-cols-2'>
                    <div className='flex justify-start'>
                        {data?.brand === 'TELKOMSEL' ? (
                            <img src={LogoTelkomsel} alt='' className='w-12' />
                        ) : data?.brand === 'XL' ? (
                            <img src={LogoXL} alt='' className='w-12' />
                        ) : data?.brand === 'INDOSAT' ? (
                            <img src={LogoIndosat} alt='' className='w-12' />
                        ) : data?.brand === 'TRI' ? (
                            <img src={LogoTri} alt='' className='w-12' />
                        ) : (
                            ''
                        )}
                        <div className='flex-col pl-2'>
                            <div className='leading-[18px] text-[12px] font-[700]'>
                                {data?.category}
                            </div>
                            <div className='leading-[18px] text-[12px] font-[400]'>
                                {moment(data?.payment_at).format('LL')}
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-end'>
                        <BadgeContent status={data?.status} />
                    </div>
                </div>
                <hr className='divide-y divied-dashed mt-2 mb-2' />
                <div className='grid grid-cols-3'>
                    <div className='justify-start'>
                        <div className='leading-[18px] text-[12px] font-[700]'>
                            {data?.brand}
                        </div>
                        <div className='leading-[18px] text-[12px] font-[400]'>
                            {data?.destination_number}
                        </div>
                    </div>
                    <div className='justify-start'>
                        <div className='leading-[18px] text-[12px] font-[400]'>
                            {newLang?.totalPrice}
                        </div>
                        <div className='leading-[18px] text-[12px] font-[700]'>
                            Rp {toValue(data.price)}
                            ,00
                        </div>
                    </div>
                    {/* <div className='flex justify-end'>
                        <div className='w-[112px] h-[29px]  bg-[#213267] rounded-full flex justify-center items-center'>
                            <div className='leading-[18px] text-[12px] font-[700] text-[#FFFFFF]'>
                                Beli Lagi
                            </div>
                        </div>
                    </div> */}
                </div>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Detail Order</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <div className='flex flex-col'>
                                <div className='flex flex-col'>
                                    <h2 className='font-semibold bg-slate-200 p-2'>
                                        Detail Transaction
                                    </h2>
                                    <div className='divider m-0'></div>
                                    <div className='flex justify-between'>
                                        <span className='font-medium'>
                                            Status
                                        </span>
                                        {/* <span>{data?.status}</span> */}
                                        <BadgeContent status={data?.status} />
                                    </div>
                                    <div className='flex justify-between'>
                                        <span className='font-medium'>
                                            Date
                                        </span>
                                        <span>{moment(data?.inquiry_at).format('DD MMMM YYYY, HH:mm')}</span>
                                    </div>
                                    <div className='flex justify-between'>
                                        <span className='font-medium'>
                                            Category
                                        </span>
                                        <span>{data?.category}</span>
                                    </div>
                                </div>
                                {/*  */}
                                <div className='divider m-0'></div>
                                {/*  */}
                                <div className='flex flex-col'>
                                    <h2 className='font-semibold bg-slate-200 p-2'>
                                        Detail Product
                                    </h2>
                                    <div className='divider m-0'></div>
                                    <div className='flex justify-between'>
                                        <span className='font-medium'>
                                            Type
                                        </span>
                                        <span>{data?.product_name}</span>
                                    </div>
                                    <div className='flex justify-between'>
                                        <span className='font-medium'>
                                            Number
                                        </span>
                                        <span>{data?.destination_number}</span>
                                    </div>
                                    <div className='flex justify-between'>
                                        <span className='font-medium'>
                                            Price
                                        </span>
                                        <span>Rp {toValue(data?.sell_price)}</span>
                                    </div>
                                </div>
                                {/*  */}
                                <div className='divider m-0'></div>
                                {/*  */}
                                <div className='flex flex-col'>
                                    <h2 className='font-semibold bg-slate-200 p-2'>
                                        Detail Payment
                                    </h2>
                                    <div className='divider m-0'></div>
                                    <div className='flex justify-between'>
                                        <span className='font-medium'>
                                            Unique Code
                                        </span>
                                        <span>{data?.unique_code}</span>
                                    </div>
                                    <div className='flex justify-between'>
                                        <span className='font-medium'>
                                            Platform Price
                                        </span>
                                        <span>Rp {toValue(data?.platform_fee)}</span>
                                    </div>
                                    <div className='flex justify-between'>
                                        <span className='font-medium'>
                                            Platform Service Fee
                                        </span>
                                        <span>Rp {toValue(data?.payment_service_fee)}</span>
                                    </div>
                                    <div className='flex justify-between'>
                                        <span className='font-medium'>
                                            Total Payment
                                        </span>
                                        <span>Rp {toValue(data?.amount)}</span>
                                    </div>
                                </div>
                                {/*  */}
                                <div className='divider m-0'></div>
                                {/*  */}
                                <div className='flex flex-col'>
                                    <h2 className='font-semibold bg-slate-200 p-2'>
                                        How To Pay
                                    </h2>
                                    <div className='divider m-0'></div>
                                    <div className='flex justify-between'>
                                        <span className='font-medium'>
                                            {data?.payment_method_data?.payment_method}
                                        </span>
                                    </div>
                                    <div className='flex justify-between'>
                                        <span className='font-medium'>
                                            Bank
                                        </span>
                                        <span>{data?.payment_channel_data?.bank_name}</span>
                                    </div>
                                    <div className='flex justify-between'>
                                        <span className='font-medium'>
                                            Account Name
                                        </span>
                                        <span>{data?.payment_channel_data?.account_name}</span>
                                    </div>
                                    <div className='flex justify-between'>
                                        <span className='font-medium'>
                                            Account Number
                                        </span>
                                        <span>{data?.payment_channel_data?.account_number}</span>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </div>
        </>
    )
}

export default CardContent
