import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    filter: {
        set_category: true,
        category: 'Pulsa',

        // set_payment_status: isFilter,
        // payment_status: filterStatus,

        set_status: false,
        status: 'success',
    },
}

// Success
// Failed
// Reject
// Waiting for payment
// Waiting for approvement 
// Pending

export const transactionFilter = createSlice({
    name: 'profileFilter',
    initialState,
    reducers: {
        changeFilter: (state, action) => {
            let isStatus = true
            let status = 'semua'
            switch (action.payload.status) {
                case 'all':
                    isStatus = false
                    status = 'semua'
                    break;
                case 'success':
                    isStatus = true
                    status = 'sukses'
                    break;
                case 'waiting payment':
                    isStatus = true
                    status = 'waiting for payment'
                    break;
                case 'cancelled':
                    isStatus = true
                    status = 'reject'
                    break;
                case 'failed':
                    isStatus = true
                    status = 'failed'
                    break;
            }
            state.filter = {
                ...state.filter,
                set_status: isStatus,
                status: status,
            }
        },
        changeCategory: (state, action) => {
            state.filter = {
                ...state.filter,
                category: action.payload.category,
            }
        },
    },
})

export const { changeFilter, changeCategory } = transactionFilter.actions

export default transactionFilter.reducer
