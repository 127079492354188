import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    is_include : false,
    cigars: [],
}

export const cigar = createSlice({
    name: 'cigar',
    initialState,
    reducers: {
        addCigar: (state, action) => {
            state.cigars = [action.payload, ...state.cigars]
            state.is_include = true
        },
        deleteCigar: (state, action) => {
            state.cigars = state.cigars.filter(cigar => action.payload != cigar.id)
            if (state.cigars.length == 0) state.is_include = false
        },
    },
})

export const { addCigar, deleteCigar } = cigar.actions

export default cigar.reducer