import React, { useContext } from 'react'
import AboutUs1 from 'Assets/images/aboutus-1.svg'
import AboutUs2 from 'Assets/images/aboutus-2.svg'
import { AuthContext } from 'Context/authContext'
import { useQuery } from '@tanstack/react-query'
import { fetchAboutUs } from 'Api/legal'
import { useNavigate } from 'react-router-dom'

const AboutUs = () => {
    const { token } = useContext(AuthContext)
    const navigate = useNavigate()

    const { data: aboutUsData, isLoading: isLoadingAboutUs } = useQuery(
        ['about-us'],
        () => fetchAboutUs({ token }),
        {
            onSuccess: (data) => {
            },
        }
    )

    return (
        <div className='flex flex-wrap min-h-screen pt-[100px] md:pt-[200px] max-w-screen-xl m-auto space-y-4 p-5 md:p-0'>
            {/* <div className='w-full md:w-1/2 flex justify-center'>
                <img src={AboutUs1} alt='' />
            </div>
            <div className='w-full md:w-1/2 flex flex-col'>
                <span className='text-black text-2xl font-bold mb-5'>
                    About Us
                </span>
                <p className='text-black text-base font-normal'>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. At
                    voluptates vero nihil nostrum porro est tempora dolores quo
                    minus ducimus, eligendi, dolorem atque temporibus provident
                    molestiae fugiat consequuntur molestias harum optio
                    distinctio officiis. Possimus voluptatibus nesciunt
                    distinctio aliquid excepturi natus ea! Cumque sit asperiores
                    doloremque ipsa.
                </p>
            </div>

            <div className='w-full md:w-1/2 flex flex-col'>
                <span className='text-black text-2xl font-bold mb-5'>
                    About Us
                </span>
                <p className='text-black text-base font-normal'>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. At
                    voluptates vero nihil nostrum porro est tempora dolores quo
                    minus ducimus, eligendi, dolorem atque temporibus provident
                    molestiae fugiat consequuntur molestias harum optio
                    distinctio officiis. Possimus voluptatibus nesciunt
                    distinctio aliquid excepturi natus ea! Cumque sit asperiores
                    doloremque ipsa.
                </p>
            </div>
            <div className='w-full md:w-1/2 flex justify-center'>
                <img src={AboutUs1} alt='' />
            </div> */}
            {!isLoadingAboutUs && (
                <div
                    className=''
                    dangerouslySetInnerHTML={{
                        __html: aboutUsData?.data?.content,
                    }}
                />
            )}
        </div>
    )
}

export default AboutUs
