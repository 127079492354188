import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    passenger: {},
    is_saved: false
}

export const passenger = createSlice({
    name: 'passenger',
    initialState,
    reducers: {
        savePassenger: (state, action) => {
            state.passenger = { ...action.payload }
            state.is_saved = true
        },
    },
})

export const { savePassenger } = passenger.actions

export default passenger.reducer
