import React, { useContext } from 'react'
import LogoTelkomsel from 'Assets/images/logo-telkomsel.png'
import LogoIndosat from 'Assets/images/logo-indosat.png'
import LogoXL from 'Assets/images/logo-xl.png'
import LogoTri from 'Assets/images/logo-tri.png'
import moment from 'moment'
import { toValue } from 'Utils/conversion'
import BadgeContent from './badgeContent'
import { AuthContext } from 'Context/authContext'

const CardContent = ({data}) => {
    const { token, newLang } = useContext(AuthContext)

    return (
        <div className='w-full border border-[#D7D7D7] p-4 rounded-xl'>
            <div className='grid grid-cols-2'>
                <div className='flex justify-start'>
                    {data?.brand === 'TELKOMSEL' ? (
                        <img src={LogoTelkomsel} alt='' className='w-12' />
                    ) : data?.brand === 'XL' ? (
                        <img src={LogoXL} alt='' className='w-12' />
                    ) : data?.brand === 'INDOSAT' ? (
                        <img src={LogoIndosat} alt='' className='w-12' />
                    ) : data?.brand === 'TRI' ? (
                        <img src={LogoTri} alt='' className='w-12' />
                    ) : (
                        ''
                    )}
                    <div className='flex-col pl-2'>
                        <div className='leading-[18px] text-[12px] font-[700]'>
                            {data?.transaction_type}
                        </div>
                        <div className='leading-[18px] text-[12px] font-[400]'>
                            {moment(data?.created_at).format('LL')}
                        </div>
                    </div>
                </div>
                <div className='flex justify-end'>
                    <BadgeContent status={data?.status} />
                </div>
            </div>
            <hr className='divide-y divied-dashed mt-2 mb-2' />
            <div className='grid grid-cols-3'>
                <div className='justify-start'>
                    <div className='leading-[18px] text-[12px] font-[700]'>
                        {data?.brand}
                    </div>
                    <div className='leading-[18px] text-[12px] font-[400]'>
                        {data?.destination_number}
                    </div>
                </div>
                <div className='justify-start'>
                    <div className='leading-[18px] text-[12px] font-[400]'>
                        {newLang?.totalPrice}
                    </div>
                    <div className='leading-[18px] text-[12px] font-[700]'>
                        Rp {toValue(data.amount)}
                        ,00
                    </div>
                </div>
                {/* <div className='flex justify-end'>
                        <div className='w-[112px] h-[29px]  bg-[#213267] rounded-full flex justify-center items-center'>
                            <div className='leading-[18px] text-[12px] font-[700] text-[#FFFFFF]'>
                                Beli Lagi
                            </div>
                        </div>
                    </div> */}
            </div>
        </div>
    )
}

export default CardContent
