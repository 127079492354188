import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    is_include: false,
    foreigns: [],
}

export const foreign = createSlice({
    name: 'foreign',
    initialState,
    reducers: {
        addForeign: (state, action) => {
            state.foreigns = [action.payload, ...state.foreigns]
            state.is_include = true
        },
        deleteForeign: (state, action) => {
            state.foreigns = state.foreigns.filter(
                (foreign) => action.payload != foreign.id
            )
            if (state.foreigns.length == 0) state.is_include = false
        },
    },
})

export const { addForeign, deleteForeign } = foreign.actions

export default foreign.reducer
