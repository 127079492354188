import React from 'react'
import NotFoundSvg from 'Assets/images/404.svg'

const NotFound = () => {
    return (
        <div className='pt-[100px] md:pt-[200px] max-w-screen-xl flex flex-col m-auto mb-[100px] min-h-screen text-center justify-center'>
            <div className='mx-auto'>
                <img className='w-[#450px]' src={NotFoundSvg} alt='' />
            </div>
            <span className='text-5xl font-bold'>oops!</span>
            <span className='text-xs mt-4'>Page not found </span>
        </div>
    )
}

export default NotFound
