import React, { useContext, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { isMobile } from 'react-device-detect'
import { AuthContext } from 'Context/authContext'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchChangeProfile, fetchProfile } from 'Api/profile'
import Toast from 'Utils/toast'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'

const schema = yup
    .object({
        firstName: yup.string().required('Firstname is Required'),
    })
    .required()

const Firstname = () => {
    const { token, newLang } = useContext(AuthContext)
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const { data: profile, isLoading: isLoadingProfile } = useQuery(
        ['profile', token],
        () => fetchProfile({ token }),
        {
            onSuccess: (data) => {
                setValue('firstName', data?.data?.first_name)
            },
        }
    )

    const { mutate: mutateUpdateProfile, isLoading: isLoadingUpdateProfile } =
        useMutation(fetchChangeProfile, {
            onSuccess: (data) => {
                Toast('success', data?.message?.response_message)
                navigate('/profile')
            },
        })

    const onSubmit = (data) => {
        const payload = {
            first_name: data.firstName,
            last_name: profile?.data?.last_name,
            profile_picture_image_url: profile?.data?.profile_picture_image_url,
            phone: profile?.data?.phone,
            email: profile?.data?.email,
            region: profile?.data?.region,
            address: profile?.data?.address,
        }
        mutateUpdateProfile({ token, payload })
    }

    return (
        <div
            className={`flex flex-col ${
                isMobile ? 'w-full' : 'w-3/4'
            } gap-5 p-2`}
        >
            {!isMobile && (
                <h1 className='text-black text-xl font-semibold'>
                    {newLang?.change} {newLang?.firstName}
                </h1>
            )}
            <span className='text-black-three text-ss'>
                {newLang?.youChangeFirstName ??
                    `You can only change your First Name once, make sure the name is
                correct`}
            </span>
            <div className='mt-8 flex flex-col gap-5'>
                <div className={`single-input ${!isMobile && 'max-w-[50%]'}`}>
                    <h5 className='label-text text-black font-semibold mb-2'>
                        {newLang?.firstName}
                    </h5>
                    <InputText
                        className='w-full rounded-xl text-black'
                        placeholder={'Andre'}
                        defaultValue={profile?.data?.first_name}
                        // onChange={(e) => setFirstname(e.target.value)}
                        {...register('firstName', { required: true })}
                    />
                    {errors.firstName && (
                        <p className='text-xs text-red-600'>
                            {errors.firstName.message}
                        </p>
                    )}
                </div>
            </div>
            <button
                className='btn bg-blue-five w-36 text-white'
                onClick={handleSubmit(onSubmit)}
            >
                {newLang?.save}
            </button>
        </div>
    )
}

export default Firstname
