import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/lazy'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

// import required modules
import { FreeMode, Navigation, Thumbs, Lazy } from 'swiper'

const ModalPicture = ({data = [], title, visible, cancel}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null)
    const [images, setImages] = useState([])
    
    useEffect(() => {
        data && setImages(data)
    }, [])

    return (
        <Dialog
            className='p-3 border-0 shadow-none max-w-screen-lg'
            header={title}
            visible={visible}
            style={{ minWidth: '30vw', boxShadow: 'none' }}
            onHide={cancel}
            draggable='false'
        >
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }}
                spaceBetween={10}
                navigation={false}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs, Lazy]}
                className='aspect-[5/3] rounded-xl m-auto cursor-pointer swiper'
            >
                {images?.map((item, idx) => (
                    <SwiperSlide key={idx}>
                        <img className='w-full h-full' src={`${item}`} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={'auto'}
                // freeMode={true}
                // watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs, Lazy]}
                className='max-h-[120px] mt-2'
            >
                {images?.map((item, idx) => (
                    <SwiperSlide
                        className='max-w-[100px] cursor-pointer'
                        key={idx}
                    >
                        <img
                            className='aspect-[5/3] rounded-xl w-full hover:scale-90 ease-in-out duration-300'
                            src={`${item}`}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Dialog>
    )
}

export default ModalPicture
