import React, { useContext, useState } from 'react'
import OtherAdsData from 'Constants/otherAds.json'
import { AuthContext } from 'Context/authContext'
import { Link, useNavigate } from 'react-router-dom'
import { fetchListAdvertise } from 'Api/advertise'
import { useQuery } from '@tanstack/react-query'
import skeletonPreview from 'Assets/images/default_preview_thumbnail.svg'
import Card from './Card'

const OtherAds = () => {
    const { token, newLang } = useContext(AuthContext)
    const navigate = useNavigate()

    const [totalData, setTotalData] = useState(0)

    const [query, setQuery] = useState({
        set_title: false,
        title: '',
        set_category: false,
        // category: 1,
        category: [],
        set_tag: false,
        tags: '',
        set_status: true,
        status: 1,
    })

    const [options, setOptions] = useState({
        limit: 15,
        page: 1,
        order: 'created_at',
        sort: 'DESC',
    })

    const {
        data: advertisesData,
        isLoading: isLoadingAdvertisesData,
        refetch: refetchAdvertisesData,
    } = useQuery(
        ['other-advertises', query, token],
        () => fetchListAdvertise({ token, query, options }),
        {
            onSuccess: (data) => {
                setTotalData(data.total_data)
            },
        }
    )

    const handleSeeMore = async () => {
        await setOptions({ ...options, limit: options.limit + 15 })
        refetchAdvertisesData()
    }

    return (
        <div className='flex flex-col mt-5'>
            <div className='flex justify-between mb-5'>
                <span className='text-2xl font-bold text-black-three'>
                    {newLang?.otherAds}
                </span>
                <Link to={`advertise-list`}>
                    <div
                        className='px-2 py-1 rounded-xl bg-blue-five cursor-pointer hover:scale-105 ease-in-out duration-300'
                        // onClick={() => navigate('advertise-list')}
                    >
                        <span className='text-xs text-white'>{newLang?.seeAll}</span>
                    </div>
                </Link>
            </div>

            {!isLoadingAdvertisesData && (
                <Card data={advertisesData.data} type={'otherAds'} />
            )}
            {options.limit < totalData && (
                <div className='justify-center w-full text-center'>
                    <button
                        className='btn bg-blue-five border-none'
                        onClick={(options) => handleSeeMore(options)}
                    >
                        See more
                    </button>
                </div>
            )}
        </div>
    )
}

export default OtherAds

{
    /* <div className='grid grid-cols-2 md:grid-cols-5 gap-5 m-auto'> */
}
{
    /* <div className='flex flex-wrap m-auto justify-around'>
    {newAdvertisesData.map((item) => (
        <div
            className='w-[170px] md:w-[240px] mb-4 rounded-2xl bg-white shadow-md text-black cursor-pointer hover:scale-105 ease-in-out duration-300'
            onClick={() => navigate(`/${item.slug}`)}
        >
            <div className='card-header relative'>
                <div className='card-image'>
                    <img
                        className='aspect-4/3 object-cover rounded-t-2xl'
                        src={item.imageWebsiteThumbnail || skeletonPreview}
                        alt=''
                    />
                </div>
                <div className='absolute top-2 left-2 shadow-md'>
                    <span className='badge bg-white opacity-80 text-[8px] font-normal text-black border-none'>
                        {item.categoryName}
                    </span>
                </div>
            </div>
            <div className='card-body min-h-[105px] p-3'>
                <div className='card-title'>
                    <span className='text-xs font-bold leading-4'>
                        {item.title}
                    </span>
                </div>
                <div className='card-desc'>
                    <span className='text-ss font-medium leading-4 line-clamp-3'>
                        {item.descriptionPreview}
                    </span>
                </div>
            </div>
        </div>
    ))}
</div> */
}
