import React, { useContext, useEffect, useState } from 'react'
import TypeAdsJson from 'Constants/AdsList/typeAds.json'
import AdsDataJson from 'Constants/AdsList/Ads.json'
import AdTypesJson from 'Constants/AdsList/categorieAds.json'
import AdListBanner from 'Assets/images/ad-list-banner.png'
import { AuthContext } from 'Context/authContext'
import { useQuery } from '@tanstack/react-query'
import { fetchAdvertiseCategory, fetchListAdvertise } from 'Api/advertise'
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom'
import { InputText } from 'primereact/inputtext'
import skeletonPreview from 'Assets/images/default_preview_thumbnail.svg'

const AdsList = () => {
    const { token, newLang } = useContext(AuthContext)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [searchTags, setSearchTags] = useState('')
    const [isLoadingContent, SetIsLoadingContent] = useState(false)

    let idCat = searchParams.get('id')
    let nameCat = searchParams.get('name')
    let category = searchParams.get('cat')
    let tags = searchParams.get('tag')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    useEffect(() => {
        if (idCat && nameCat) {
            SetIsLoadingContent(false)
            setQuery({
                set_home_section: true,
                home_section: idCat,
                set_title: false,
                title: '',
                set_category: false,
                category: [],
                set_status: true,
                status: 1,
                set_tag: false,
                tags: '',
            })
            SetIsLoadingContent(true)
        } else if (category) {
            SetIsLoadingContent(false)
            setQuery({
                set_home_section: false,
                home_section: '',
                set_title: false,
                title: '',
                set_category: true,
                category: [parseInt(...category)],
                set_status: true,
                status: 1,
                set_tag: false,
                tags: '',
            })
            SetIsLoadingContent(true)
        } else if (tags) {
            SetIsLoadingContent(false)
            setQuery({
                set_home_section: false,
                home_section: '',
                set_title: false,
                title: '',
                set_category: false,
                category: [],
                set_status: true,
                status: 1,
                set_tag: true,
                tags: tags,
            })
            SetIsLoadingContent(true)
        }
    }, [])

    let defaultOptions = {
        limit: 20,
        page: 1,
        order: 'created_at',
        sort: 'ASC',
    }

    const [query, setQuery] = useState({
        set_home_section: false,
        home_section: '',
        set_title: false,
        title: '',
        set_category: false,
        category: [],
        set_status: true,
        status: 1,
        set_tag: false,
        tags: '',
    })

    const [options, setOptions] = useState({
        limit: 20,
        page: 1,
        order: 'created_at',
        sort: 'ASC',
    })

    const [selected, setSelected] = useState('all')

    const {
        data: advertisesData,
        isLoading: isLoadingAdvertisesData,
        refetch: refetchAdvertisesData,
    } = useQuery(
        ['advertises', query, token],
        () => fetchListAdvertise({ token, query, options }),
        {
            onSuccess: (data) => {},
        }
    )

    const {
        data: advertiseCategoryList,
        isLoading: isLoadingAdvertiseCategoryList,
    } = useQuery(
        ['advertises-category', token],
        () => fetchAdvertiseCategory({ token }),
        {
            onSuccess: (data) => {},
        }
    )

    let newAdvertisesData = []

    !isLoadingAdvertisesData &&
        advertisesData.data?.map((item) => {
            newAdvertisesData.push({
                id: item.id,
                categoryName: item.category_name,
                title: item.title,
                descriptionPreview: item.description_preview,
                imageWebsiteThumbnail: item.image_website?.thumbnail,
                slug: item.slug,
            })
        })

    const handleSearch = async (e) => {
        await setQuery({ ...query, set_title: true, title: e })
        refetchAdvertisesData()
    }

    const handleChangeSelect = async (e) => {
        setSelected(e)
        switch (e) {
            case 'all':
                const newOptionsAll = {
                    ...defaultOptions,
                    order: 'created_at',
                    sort: 'ASC',
                }
                await setOptions(newOptionsAll)
                refetchAdvertisesData()
                break
            case 'new':
                const newOptionsNew = {
                    ...defaultOptions,
                    order: 'created_at',
                    sort: 'DESC',
                }
                await setOptions(newOptionsNew)
                refetchAdvertisesData()
                break
            // case 'likes':
            //     await setOptions({
            //         ...defaultOptions,
            //         order: 'likes',
            //         sort: 'DESC',
            //     })
            //     refetchAdvertisesData()
            //     break
            // case 'views':
            //     await setOptions({
            //         ...defaultOptions,
            //         order: 'views',
            //         sort: 'DESC',
            //     })
            //     refetchAdvertisesData()
            //     break
            case 'title':
                const newOptionsTitle = {
                    ...defaultOptions,
                    order: 'title',
                    sort: 'ASC',
                }
                await setOptions(newOptionsTitle)
                refetchAdvertisesData()
                break
            default:
                break
        }
        // await setOptions({...options})
    }

    const handleFilterCategory = async (item) => {
        if (query.category.length == 0) {
            let newCategory = [...query.category, item.id]
            await setQuery({
                ...query,
                set_category: true,
                category: newCategory,
            })
            refetchAdvertisesData()
        } else {
            let find = query.category.indexOf(item.id)
            if (find > -1) {
                query.category.splice(find, 1)
            } else {
                query.category.push(item.id)
            }
            if (query.category.length == 0) {
                await setQuery({
                    ...query,
                    set_category: false,
                    category: [],
                })
            } else {
                refetchAdvertisesData()
            }
        }
        // let newCategory = [...query.category, item.id]
        // await setQuery({ ...query, set_category: true, category: newCategory })
        // refetchAdvertisesData()
        // await setCategoryIds(newCategory)
    }

    const handleSearchTags = async (e) => {
        await setQuery({ ...query, set_tag: true, tags: e.target.value })
        refetchAdvertisesData()
    }


    return (
        <div className='flex flex-wrap pt-[100px] md:pt-[200px] max-w-screen-xl min-h-screen m-auto p-5 md:p-0'>
            <div className='w-1/4 hidden md:flex flex-col gap-4'>
                <span className='text-black font-bold text-xl mb-4'>
                    {newLang?.moreDetailedSearch ?? `More detailed search`}
                </span>
                <div
                    tabindex='0'
                    className='collapse collapse-arrow collapse-open border border-base-300 bg-white rounded-box text-black'
                >
                    <div className='collapse-title text-base font-bold bg-gray-400'>
                        {newLang?.category ?? `Categories`}
                    </div>
                    <div className='collapse-content flex flex-col gap-2'>
                        {!isLoadingAdvertiseCategoryList &&
                            advertiseCategoryList.data?.map((item) => (
                                <div className='form-control' key={item.id}>
                                    <label className='label cursor-pointer justify-start'>
                                        <input
                                            type='checkbox'
                                            className='checkbox rounded-lg'
                                            onChange={() =>
                                                handleFilterCategory(item)
                                            }
                                            defaultChecked={query.category?.includes(
                                                item.id
                                            )}
                                        />
                                        <span className='label-text ml-2'>
                                            {item.title}
                                        </span>
                                    </label>
                                </div>
                            ))}
                    </div>
                </div>
                <div
                    tabindex='0'
                    className='collapse collapse-arrow collapse-open border border-base-300 bg-white rounded-box text-black'
                >
                    <div className='collapse-title text-base font-bold bg-gray-400'>
                        {newLang?.tag ?? 'Tags'}
                    </div>
                    <div className='collapse-content flex flex-col p-4 '>
                        <InputText
                            placeholder='search by tags'
                            onChange={handleSearchTags}
                            defaultValue={tags && tags}
                        />
                    </div>
                </div>
            </div>
            <div className='w-full md:w-3/4 flex flex-col text-black gap-3 md:pl-4'>
                {/* <img src={AdListBanner} alt='' /> */}
                <div className='hidden md:flex items-center justify-between space-x-3'>
                    <input
                        type='text'
                        placeholder='Search'
                        className='input w-full rounded-md border-gray-500 bg-white'
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    <span className='whitespace-nowrap'>{newLang?.sortBy ?? 'Sort By'}</span>
                    <select
                        className='select max-w-xs rounded-md border-gray-500 bg-white'
                        defaultValue={selected}
                        onChange={(e) => handleChangeSelect(e.target.value)}
                    >
                        {idCat != null && nameCat != null ? (
                            <>
                                <option value='title'>name / title</option>
                            </>
                        ) : (
                            <>
                                <option value='all'>All</option>
                                <option value='new'>New</option>
                                {/* <option value='likes'>Most Like</option>
                        <option value='views'>Most View</option> */}
                                <option value='title'>name / title</option>
                            </>
                        )}
                        {/* <option value='all'>All</option> */}
                        {/* <option value='new'>New</option> */}
                        {/* <option value='likes'>Most Like</option>
                        <option value='views'>Most View</option> */}
                        {/* <option value='title'>name / title</option> */}
                    </select>
                </div>
                <span>
                    {newLang?.showing ?? 'Showing'}{' '}
                    {!isLoadingAdvertisesData &&
                        `${advertisesData.data?.length}`}{' '}
                    {newLang?.outOf ?? 'out of'}{' '}
                    {!isLoadingAdvertisesData && `${advertisesData.total_data}`}{' '}
                    {idCat && nameCat && (
                        <span className=''>for all {nameCat}</span>
                    )}
                </span>
                <div className='flex flex-wrap basis-full gap-2 md:gap-6 md:p-2'>
                    {newAdvertisesData.map((item) => (
                        <div
                            className='card card-compact relative w-[167px] md:w-[214px] h-[220px] md:h-[260px] bg-base-100 shadow-md text-black cursor-pointer hover:scale-105 ease-in-out duration-300'
                            onClick={() => navigate(`/${item.slug}`)}
                        >
                            <div className='absolute top-2 left-2'>
                                <span className='badge bg-white opacity-80 text-[8px] font-normal text-black border-none'>
                                    {item.categoryName}
                                </span>
                            </div>
                            <figure className='bg-white'>
                                <img
                                    className='aspect-video object-cover h-[100px] md:h-[140px]'
                                    src={item.imageWebsiteThumbnail || skeletonPreview}
                                    alt=''
                                />
                            </figure>
                            <div className='card-body bg-white'>
                                <h2 className='card-title text-sm font-bold line-clamp-2'>
                                    {item.title}
                                </h2>
                                <p className='text-ss line-clamp-2'>
                                    {item.descriptionPreview}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AdsList
