import React, { useContext, useEffect, useState } from 'react'
import GopaySvg from 'Assets/images/gopay.svg'
import BcaSvg from 'Assets/images/bca.svg'
import BriVaSvg from 'Assets/images/bri-va.svg'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { InputNumber } from 'primereact/inputnumber'
import { AuthContext } from 'Context/authContext'
import { useMutation, useQueries, useQuery } from '@tanstack/react-query'
import {
    fetchInquiry,
    fetchPaymentChannel,
    fetchPaymentMethod,
    fetchPrefix,
    fetchPriceList,
    fetchTypes,
} from 'Api/payment'
import Toast from 'Utils/toast'
import { toValue } from 'Utils/conversion'
import { fetchPoint } from 'Api/profile'

const schema = yup
    .object({
        number: yup.string().required('Number is Required'),
        brand: yup.string().required('Service is Required'),
    })
    .required()

const Internet = () => {
    const navigate = useNavigate()
    const { token, newLang } = useContext(AuthContext)
    const [nominal, setNominal] = useState()
    const [brand, setBrand] = useState('')
    const [number, setNumber] = useState('')
    const [paymentChannel, setPaymentChannel] = useState()
    const [price, setPrice] = useState()
    const [sku, setSku] = useState()
    const [detail, setDetail] = useState(false)
    const [methodId, setMethodId] = useState()

    const [paymentChannelState, setPaymentChannelState] = useState([])

    const [filter, setFilter] = useState({
        set_category: true,
        category: 'pascabayar',
        set_brand: true,
        brand: 'INTERNET PASCABAYAR',
        set_product_name: false,
        product_name: '',
        set_type: false,
        type: '',
        set_seller_name: false,
        seller_name: '',
        set_is_available: false,
        is_available: false,
    })

    const [options, setOptions] = useState({
        limit: 100,
        page: 1,
        order: 'created_at',
        sort: 'DESC',
    })

    useEffect(() => {
        const isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'))
        !isLoggedin && navigate('/')
    }, [])

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    // Price List
    const { data: priceListData, isLoading: isLoadingPriceList } = useQuery(
        ['price-list', token],
        () => fetchPriceList({ token, filter, options }),
        {
            onSuccess: (data) => {},
        }
    )

    // Inquiry
    const {
        isLoading: isLoadingInquiry,
        mutate: mutateInquiry,
        data: inquiryData,
    } = useMutation(fetchInquiry, {
        onSuccess: (data) => {
            if (data?.data?.biller_rc == '00') {
                setDetail(true)
                setNominal(data?.data?.amount)
                mutateMethod({ token })
            } else {
                if (data?.message?.response_code == '10') {
                    Toast('error', `${data?.message?.response_message}`)
                } else {
                    Toast(
                        'error',
                        `${data?.data?.biller_status} - ${data?.data?.biller_data?.message}`
                    )
                }
            }
        },
    })

    // Payment Method
    const {
        isLoading: isLoadingMethod,
        mutate: mutateMethod,
        data: methodData,
    } = useMutation(fetchPaymentMethod, {
        onSuccess: (data) => {},
    })

    // Payment Channel
    const channelData = useQueries({
        queries:
            methodData?.data?.map((id) => {
                return {
                    queryKey: ['payment-channel', id],
                    queryFn: () =>
                        fetchPaymentChannel({
                            token,
                            id,
                            nominal: parseFloat(nominal),
                        }),
                    onSuccess: (data) => {
                        if (data?.message?.response_code == '00') {
                            setPaymentChannelState(data.data)
                        } else {
                            setPaymentChannelState([])
                        }
                    },
                }
            }) ?? [],
    })

    // Point
    const { data: point, isLoading: isLoadingPoint } = useQuery(
        ['point', token],
        () => fetchPoint({ token }),
        {
            onSuccess: (data) => {},
        }
    )

    // const handleChange = (value) => {
    //     setNumber(value)
    // }

    // const handleChangeType = async (value) => {
    //     setBrand(value)
    // }

    const handleCheked = async (item) => {
        await setNominal(item.sell_price)
        await setPrice(item.sell_price)
        await setSku(item.buyer_sku_code)
        // await setPaymentGateway()
        // mutateMethod({ token })
    }

    const handleChangeRadio = (item, obj) => {
        setMethodId(obj.method_id)
        // setChannelId(item.id)
        if (item == 'point') {
            setPaymentChannel({
                name: `Point ${point.data}`,
            })
        } else {
            setPaymentChannel(item)
        }
    }

    const onSubmit = (data) => {
        navigate('/detail-payment', {
            state: {
                channel: paymentChannel,
                data: inquiryData.data,
                method_id: methodId,
            },
        })
    }

    const handleCekTagihan = async () => {
        const payload = {
            type_transaction: 'postpaid',
            buyer_sku_code: brand,
            // buyer_sku_code: 'internet',
            destination_number: number,
        }
        await mutateInquiry({ token, payload })
    }

    return (
        <div className='flex flex-col px-5 gap-4'>
            <div className='phone w-full flex flex-col'>
                <h2 className='text-xl font-bold'>
                    1. {newLang?.internet ?? 'Internet'}
                </h2>
                <div className='w-full flex items-end gap-2'>
                    <div className='w-1/2'>
                        <div className='form-control w-full'>
                            <label className='label'>
                                <span className='label-text'>
                                    {newLang?.selectInternetService ??
                                        'Select Internet Service'}
                                </span>
                            </label>
                            <select
                                className='select border-2 border-slate-300 w-full'
                                {...register('brand', { required: true })}
                                onChange={(e) => setBrand(e.target.value)}
                            >
                                <option selected>none</option>
                                {!isLoadingPriceList &&
                                    priceListData?.data?.map((item, idx) => {
                                        return (
                                            <option
                                                key={idx}
                                                value={item.buyer_sku_code}
                                            >
                                                {item.product_name}
                                            </option>
                                        )
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <div className='form-control w-full'>
                            <label className='label'>
                                <span className='label-text'>
                                    {newLang?.customerNumber ??
                                        'Customer number'}
                                </span>
                            </label>
                            <input
                                type='number'
                                placeholder='Type here'
                                className='input input-bordered w-full'
                                onChange={(e) => setNumber(e.target.value)}
                                // onKeyDown={(e) => handleKeyPress(e)}
                                pattern='[0-9.]+'
                                {...register('number', {
                                    required: true,
                                    onChange: (e) => setNumber(e.target.value),
                                })}
                            />
                            {errors.number && (
                                <p className='text-xs text-red-600'>
                                    {errors.number.message}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className='w-full mt-4'>
                    <button
                        className='btn bg-blue-five text-white rounded-full min-w-[200px]'
                        onClick={() => handleCekTagihan()}
                        disabled={!!!brand || !!!number || isLoadingInquiry}
                    >
                        {newLang?.chechBill ?? 'check bill'}
                    </button>
                </div>

                {detail && !isLoadingInquiry && (
                    <>
                        <div className='w-full mt-4'>
                            <h2 className='text-base font-bold text-black-four'>
                                {newLang?.paymentDetail ?? 'Payment Detail'}
                            </h2>
                        </div>
                        <div className='w-full mt-4 flex'>
                            <div className='w-1/2 flex flex-col gap-2'>
                                <div className='w-full flex justify-between'>
                                    <span className='text-xs'>
                                        {newLang?.name ?? 'Name'}
                                    </span>
                                    <span className='text-xs font-bold'>
                                        {
                                            inquiryData.data?.biller_data
                                                ?.customer_name
                                        }
                                    </span>
                                </div>
                                <div className='w-full flex justify-between'>
                                    <span className='text-xs'>
                                        {newLang?.customerNumber ??
                                            'Customer number'}
                                    </span>
                                    <span className='text-xs font-bold'>
                                        {
                                            inquiryData.data?.biller_data
                                                ?.customer_no
                                        }
                                    </span>
                                </div>
                                {inquiryData.data?.biller_data?.admin && (
                                    <div className='w-full flex justify-between'>
                                        <span className='text-xs'>
                                            {newLang?.admin ?? 'Admin'}
                                        </span>
                                        <span className='text-xs font-bold'>
                                            Rp{' '}
                                            {toValue(inquiryData.data?.admin)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='w-full h-0.5 bg-slate-300 my-4' />
                        <div className='w-full flex'>
                            {inquiryData.data?.biller_data?.desc?.detail?.map(
                                (item, idx) => {
                                    return (
                                        <div className='w-1/2 flex flex-col gap-2'>
                                            <h3>Lembar {idx + 1}</h3>
                                            <div className='w-full flex justify-between'>
                                                <span className='text-xs'>
                                                    {newLang?.paymentTotal ??
                                                        'Total pay'}
                                                </span>
                                                <span className='text-xs font-bold'>
                                                    Rp{' '}
                                                    {toValue(
                                                        item.nilai_tagihan
                                                    )}
                                                </span>
                                            </div>
                                            {item.admin && (
                                                <div className='w-full flex justify-between'>
                                                    <span className='text-xs'>
                                                        {newLang?.adminFee ??
                                                            'Admin Fee'}
                                                    </span>
                                                    <span className='text-xs font-bold'>
                                                        Rp {toValue(item.admin)}
                                                    </span>
                                                </div>
                                            )}
                                            <div className='w-full flex justify-between'>
                                                <span className='text-xs'>
                                                    {newLang?.period ??
                                                        'Period'}
                                                </span>
                                                <span className='text-xs font-bold'>
                                                    {item.periode}
                                                </span>
                                            </div>
                                            {item.denda && (
                                                <div className='w-full flex justify-between'>
                                                    <span className='text-xs'>
                                                        {newLang?.fine ??
                                                            'Fine'}
                                                    </span>
                                                    <span className='text-xs font-bold'>
                                                        Rp {toValue(item.denda)}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                            )}
                        </div>
                        <div className='w-full px-4 mt-4'>
                            <div className='w-full bg-slate-100 rounded-2xl p-2 flex justify-between align-middle'>
                                <span className='text-xs font-bold'>
                                    {newLang?.totalPayment ?? 'Total payment'}
                                </span>
                                <span className='text-sm font-semibold'>
                                    Rp {toValue(inquiryData.data?.amount)}
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </div>
            {!isLoadingMethod && (
                <div className='metode w-full flex flex-col gap-4'>
                    <h2 className='text-xl font-bold'>
                        2.{' '}
                        {newLang?.selectPaymentMethod ??
                            'Select Payment method'}
                    </h2>

                    {channelData?.map((item, idx) => {
                        const obj = {
                            method_id: methodData?.data[idx]?.id,
                            name: methodData?.data[idx]?.payment_method,
                            channels: item?.data?.data,
                        }
                        return (
                            <div
                                className='payment w-full flex flex-col gap-4'
                                key={idx}
                            >
                                <h3 className='text-xs font-bold'>
                                    {obj.name}
                                </h3>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                                    {obj.channels?.map((item, idx) => {
                                        return (
                                            <div
                                                className='form-control border-2 rounded-xl p-2 w-full md:w-auto'
                                                key={idx}
                                            >
                                                <label className='label cursor-pointer gap-2 justify-evenly'>
                                                    <div className='w-2/12 text-center'>
                                                        <input
                                                            type='radio'
                                                            name='radio-6'
                                                            className='radio w-5 h-5 checked:bg-blue-four'
                                                            onChange={() =>
                                                                handleChangeRadio(
                                                                    item,
                                                                    obj
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className='w-10/12'>
                                                        <div className='flex gap-2'>
                                                            <div className='flex flex-row gap-2 items-center'>
                                                                <img
                                                                    className='w-10'
                                                                    src={
                                                                        item
                                                                            ?.payment_gateway
                                                                            ?.manual_transfer
                                                                            ?.bank_logo_url
                                                                    }
                                                                    alt=''
                                                                />
                                                                <span className='label-text text-base font-normal'>
                                                                    {item.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        )
                                    })}
                                </div>
                                <h3 className='text-xs font-bold'>Point</h3>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                                    <div
                                        className='form-control border-2 rounded-xl p-2 w-full md:w-auto'
                                        key={idx}
                                    >
                                        <label className='label cursor-pointer gap-2 justify-evenly'>
                                            <div className='w-2/12 text-center'>
                                                <input
                                                    type='radio'
                                                    name='radio-6'
                                                    className='radio w-5 h-5 checked:bg-blue-four'
                                                    onChange={() =>
                                                        handleChangeRadio(
                                                            'point',
                                                            {
                                                                method_id:
                                                                    'point',
                                                            }
                                                        )
                                                    }
                                                    disabled={point.data <= 0}
                                                />
                                            </div>
                                            <div className='w-10/12'>
                                                <div className='flex gap-2'>
                                                    <div className='flex flex-row gap-2 items-center'>
                                                        <span className='label-text text-base font-bold'>
                                                            Point
                                                        </span>
                                                        <span className='label-text text-base font-normal'>
                                                            {toValue(
                                                                point.data
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
            <div className='mt-5'>
                <button
                    className='btn bg-blue-five text-white rounded-full min-w-[200px]'
                    onClick={handleSubmit(onSubmit)}
                    disabled={!!!number || !!!nominal || !!!paymentChannel}
                >
                    {newLang?.buyNow ?? 'Buy Now'}
                </button>
            </div>
        </div>
    )
}

export default Internet
