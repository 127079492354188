import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'

const Modal = ({ visible, cancel, children, title }) => {
    return (
        <>
            <Dialog className='p-3 border-0 shadow-none'
                header={title}
                visible={visible}
                style={{ minWidth: '30vw', boxShadow: "none" }}
                onHide={cancel}
                draggable="false"
            >
                {children}
            </Dialog>
        </>
    )

   
}

export default Modal