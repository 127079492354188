import { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import illuDownload from 'Assets/images/illuDownload.svg'
import { useLocation } from 'react-router-dom'
import AppStoreLogo from 'Assets/images/appstore.svg'
import PlayStoreLogo from 'Assets/images/playstore.svg'
import { AuthContext } from 'Context/authContext'

const Footer = () => {
    const [showedPopup, setShowedPopup] = useState(true)
    const location = useLocation()
    const isPopup = localStorage.getItem('mobilePopupDownload')
    const { newLang } = useContext(AuthContext)
    // useEffect(() => {
    //     const isPopup = localStorage.getItem('mobilePopupDownload')
    //     // console.log(isPopup)
    //     if(!isPopup) {
    //         localStorage.setItem('mobilePopupDownload', 'true');
    //         setShowedPopup(true)
    //     } else {
    //         localStorage.setItem('mobilePopupDownload', 'false');
    //     }
    // }, [])

    return (
        <>
            <div
                className={`${
                    isMobile && 'hidden'
                } w-full bg-blue-four flex justify-center items-center py-8 px-4 text-white relative`}
            >
                <span className='text-sm font-black'>
                    NIHAO ID © 2020 {newLang?.allRight ?? 'all right reserved'}
                </span>
            </div>
            {/* {showedPopup && ( */}
            {location.pathname === '/' && showedPopup && isMobile && (
                <div className='w-full bg-white rounded-tl-xl rounded-tr-xl p-7 flex-col space-y-2 shadow-2xl shadow-black fixed bottom-0 m-auto z-10'>
                    <div className='p-4 text-center'>
                        <span className='text-black text-base font-semibold'>
                            {newLang?.downloadOurApp ??
                                `Download our application for better expreince on
                            mobile platform`}
                        </span>
                    </div>
                    <img className='m-auto' src={illuDownload} alt='' />
                    {/* <div className='flex flex-col items-center p-2 gap-2'> */}
                    <div className='flex justify-around p-2 gap-2'>
                        <div
                            className='cursor-pointer'
                            onClick={() =>
                                window.open(
                                    'https://apps.apple.com/us/app/nihao-id/id6443483686'
                                )
                            }
                        >
                            <img
                                className='w-[200px]'
                                src={AppStoreLogo}
                                alt=''
                            />
                        </div>
                        <div
                            className='cursor-pointer'
                            onClick={() =>
                                window.open(
                                    'https://play.google.com/store/apps/details?id=id.nihao.nihao'
                                )
                            }
                        >
                            <img
                                className='w-[200px]'
                                src={PlayStoreLogo}
                                alt=''
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <button
                            className='btn bg-[#213267] text-white text-xs rounded-full w-full px-1 py-2 min-h-[1rem] normal-case'
                            onClick={() =>
                                window.open(
                                    'https://nihaoid.oss-ap-southeast-5.aliyuncs.com/nihao/app-release.apk'
                                )
                            }
                        >
                            {newLang?.download ?? 'Download'} NIHAO ID
                        </button>
                        <button
                            className='btn bg-white text-blue-five text-xs rounded-full w-full px-1 py-2 min-h-[1rem] normal-case hover:bg-white'
                            onClick={() => setShowedPopup(false)}
                        >
                            {newLang?.stayHere ?? 'Stay here'}
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default Footer
