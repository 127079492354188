import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    is_include: false,
    imports: [],
}

export const imports = createSlice({
    name: 'import',
    initialState,
    reducers: {
        addImport: (state, action) => {
            state.imports = [action.payload, ...state.imports]
            state.is_include = true
        },
        deleteImport: (state, action) => {
            state.imports = state.imports.filter(
                (item) => action.payload != item.id
            )
            if (state.imports.length == 0) state.is_include = false
        },
    },
})

export const { addImport, deleteImport } = imports.actions

export default imports.reducer
