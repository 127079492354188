import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    is_include: false,
    animals: [],
}

export const animals = createSlice({
    name: 'animal',
    initialState,
    reducers: {
        addAnimal: (state, action) => {
            state.animals = [action.payload, ...state.animals]
            state.is_include = true
        },
        deleteAnimal: (state, action) => {
            state.animals = state.animals.filter(animal => action.payload != animal.id)
            if (state.animals.length == 0) state.is_include = false
        },
    },
})

export const { addAnimal, deleteAnimal } = animals.actions

export default animals.reducer