import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import skeletonPreview from 'Assets/images/default_preview_thumbnail.svg'

import { FreeMode, Navigation, Thumbs } from 'swiper'

const ThumbnailFake = ({ data = [] }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null)

    return (
        <>
            {/* // <div className='max-w-[360px] max-h-[475px] md:max-w-[504px] md:max-h-[475px]'> */}
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }}
                spaceBetween={10}
                navigation={false}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className='max-h-[365px] rounded-xl m-auto'
            >
                {/* {images?.map((item, idx) => ( */}
                <SwiperSlide className='w-[360px]'>
                    <img className='w-full' src={skeletonPreview} />
                </SwiperSlide>
                {/* ))} */}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={'auto'}
                // freeMode={true}
                // watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className='max-h-[120px] mt-2'
            >
                <SwiperSlide className='max-w-[100px] cursor-pointer'>
                    <img
                        className='rounded-xl w-full hover:scale-90 ease-in-out duration-300'
                        src={skeletonPreview}
                    />
                </SwiperSlide>
                <SwiperSlide className='max-w-[100px] cursor-pointer'>
                    <img
                        className='rounded-xl w-full hover:scale-90 ease-in-out duration-300'
                        src={skeletonPreview}
                    />
                </SwiperSlide>
                <SwiperSlide className='max-w-[100px] cursor-pointer'>
                    <img
                        className='rounded-xl w-full hover:scale-90 ease-in-out duration-300'
                        src={skeletonPreview}
                    />
                </SwiperSlide>
            </Swiper>
            {/* // </div> */}
        </>
    )
}

export default ThumbnailFake