import React, { useContext, useState, useEffect } from 'react'
import eyeSvg from 'Assets/images/eye.svg'
import heartSvg from 'Assets/images/heart.svg'
import heartWhiteSvg from 'Assets/images/heart-white.svg'
import shareSvg from 'Assets/images/share.svg'
import AdsDataJson from 'Constants/relatedAds.json'
import ThumbnailGallery from 'Components/ThumbnailGallery'
import { AuthContext } from 'Context/authContext'
import {
    fetchAdvertise,
    fetchIsLike,
    fetchLike,
    fetchListAdvertise,
    fetchUnlike,
    fetchViewAds,
} from 'Api/advertise'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { Skeleton } from 'primereact/skeleton'
import { useSelector } from 'react-redux'
import Card from 'Components/Card'
import Toast from 'Utils/toast'
import ThumbnailFake from 'Components/ThumbnailFake'
import Modal from 'Components/Modals'
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import CopyIconSvg from 'Assets/images/copy-icon.svg'
import contactSocial from 'Assets/images/social/phone.png'
import emailSocial from 'Assets/images/social/email.png'
import facebookSocial from 'Assets/images/social/facebook.png'
import instagramSocial from 'Assets/images/social/instagram.png'
import wechatSocial from 'Assets/images/social/wechat.png'
import whatsappSocial from 'Assets/images/social/whatsapp.png'

const AdsDetail = () => {
    const { token, newLang } = useContext(AuthContext)
    let { slug } = useParams()
    const navigate = useNavigate()
    // const isLoggedin = useSelector((state) => state.authenticated.isLogin)
    const isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'))
    const [id, setId] = useState('')
    const [modal, setModal] = useState({ open: false })
    const [refCode, setRefCode] = useState('ADF12144123')
    const [searchParams] = useSearchParams()
    
    useEffect(() => {
        window.scrollTo(0, 0)
        const defaultLang = localStorage.getItem('lang')
        let urlLang = searchParams.get('language')
        if(urlLang && defaultLang != urlLang) {
            localStorage.setItem('lang', urlLang)
            window.location.reload()
        }
    }, [])

    const [query, setQuery] = useState({
        set_title: false,
        title: '',
        set_category: false,
        // category: 1,
        category: [],
        set_tag: false,
        tags: '',
        set_status: true,
        status: 1,
        set_deleted: true,
    })

    const [options, setOptions] = useState({
        limit: 10,
        page: 1,
        order: 'created_at',
        sort: 'ASC',
    })

    const {
        data: advertisesData,
        isLoading: isLoadingAdvertisesData,
        refetch: refetchAdvertisesData,
    } = useQuery(
        ['advertises', slug],
        () => fetchAdvertise({ token, slug: slug}),
        {
            onSuccess: (data) => {
                let id = data?.data?.id
                setId(id)
                isLoggedin && mutateViewAds({ id })
                data.message.response_code == '10' && navigate('*')
            },
        }
    )

    // IS LIKE
    const {
        data: isLike,
        isLoading: isLoadingIsLike,
        refetch: refetchIsLike,
    } = useQuery(['is-like', token], () => fetchIsLike({ token, id }), {
        onSuccess: (data) => {},
        enabled: !!id,
    })

    // LIKE
    const { mutate: mutateLike, isLoading: isLoadingLike } = useMutation(
        fetchLike,
        {
            onSuccess: (data) => {
                refetchIsLike()
                if (data.message.response_code == '32') {
                    Toast('warning', data?.message?.response_message)
                } else if (data.message.response_code == '00') {
                    Toast('success', data?.message?.response_message)
                }
            },
        }
    )

    // UnLike
    const { mutate: mutateUnlike, isLoading: isLoadingUnlike } = useMutation(
        fetchUnlike,
        {
            onSuccess: (data) => {
                refetchIsLike()
                if (data.message.response_code == '32') {
                    Toast('warning', data?.message?.response_message)
                } else if (data.message.response_code == '00') {
                    Toast('success', data?.message?.response_message)
                }
            },
        }
    )

    const { data: advertiseListData, isLoading: isLoadingAdvertiseListData } =
        useQuery(
            ['advertises', query, token],
            () => fetchListAdvertise({ token, query, options }),
            {
                onSuccess: (data) => {},
            }
        )

    const { mutate: mutateViewAds, isLoading: isLoadingViews } = useMutation(
        fetchViewAds,
        {
            enabled: !!id,
        },
        {
            onSuccess: (data) => {
                refetchAdvertisesData()
            },
        }
    )

    const handleLike = (like) => {
        like ? mutateUnlike({ token, id }) : mutateLike({ token, id })
    }

    return (
        <>
            <div className='flex pt-[100px] md:pt-[200px] max-w-screen-xl m-auto p-4 md:p-0'>
                <div className='flex flex-col w-full'>
                    <div className='flex flex-col md:flex-row gap-4'>
                        {/* Thumbnail Gallery */}
                        <div className='w-full md:w-1/3'>
                            {/* Thumbnail Extras */}
                            <div className=''>
                                {!isLoadingAdvertisesData &&
                                advertisesData.data?.image_website?.images
                                    ?.length > 0 ? (
                                    <ThumbnailGallery
                                        data={
                                            advertisesData.data?.image_website
                                                ?.images
                                        }
                                        title={advertisesData.data?.title}
                                    />
                                ) : (
                                    <ThumbnailFake />
                                )}
                            </div>
                            <div className='flex flex-wrap gap-2 items-center mt-2'>
                                {!isLoadingAdvertisesData && !isLoadingViews ? (
                                    <>
                                        <img src={eyeSvg} alt='' />
                                        <span className='text-black-four text-sm'>
                                            {newLang?.seen ?? 'seen'} :{' '}
                                            {`${advertisesData.data?.views}`}
                                        </span>
                                    </>
                                ) : (
                                    <Skeleton width='100%' height='2rem' />
                                )}
                                <span className='text-black-four text-sm'>
                                    |
                                </span>
                                {!isLoadingAdvertisesData && (
                                    <>
                                        <img src={heartSvg} alt='' />
                                        <span className='text-black-four text-sm'>
                                            {newLang?.favorite ?? 'favorite'} :{' '}
                                            {`${advertisesData.data?.likes}`}
                                        </span>
                                    </>
                                )}

                                <div className='flex gap-2'>
                                    <button
                                        className='btn btn-sm bg-blue-five rounded-2xl text-white text-ss font-normal px-4 py-2'
                                        onClick={() => handleLike(isLike.data)}
                                    >
                                        <img
                                            className='mr-2'
                                            src={heartWhiteSvg}
                                            alt=''
                                        />
                                        {newLang?.favorite ?? 'favorite'}
                                    </button>
                                    <button
                                        className='btn btn-sm bg-blue-five rounded-2xl text-white text-ss font-normal px-4 py-2'
                                        onClick={() => setModal({ open: true })}
                                    >
                                        <img
                                            className='mr-2'
                                            src={shareSvg}
                                            alt=''
                                        />
                                        {newLang?.share ?? 'Share'}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Ad Description */}
                        <div className='w-full md:w-2/3 flex flex-col space-y-4 mt-4'>
                            {!isLoadingAdvertisesData ? (
                                <h1 className='text-black text-xl font-bold'>
                                    {advertisesData.data?.title}
                                </h1>
                            ) : (
                                <Skeleton width='50%' height='2rem' />
                            )}

                            <div className='flex items-center gap-2'>
                                {!isLoadingAdvertisesData ? (
                                    <span className='badge text-white p-2 text-[8px] font-normal'>
                                        {advertisesData.data?.category_name}
                                    </span>
                                ) : (
                                    <>
                                        <Skeleton width='70px' />
                                        <Skeleton width='200px' />
                                    </>
                                )}
                                {!isLoadingAdvertisesData && (
                                    <span className='text-black-five text-ss'>
                                        {moment(
                                            `${advertisesData.data?.created_at}`
                                        ).format('MMMM DD YYYY, h:mm a')}
                                    </span>
                                )}
                            </div>
                            {!isLoadingAdvertisesData ? (
                                <div
                                    className='text-black-five text-xs'
                                    dangerouslySetInnerHTML={{
                                        __html: advertisesData.data
                                            ?.descriptions,
                                    }}
                                />
                            ) : (
                                <>
                                    <Skeleton width='200px' />
                                    <Skeleton width='200px' />
                                    <Skeleton width='200px' />
                                    <Skeleton width='200px' />
                                    <Skeleton width='200px' />
                                </>
                            )}

                            {/* Hashtag */}
                            {!isLoadingAdvertisesData && (
                                <div className='flex gap-2 text-white'>
                                    {advertisesData.data?.tags?.map(
                                        (item, idx) => (
                                            <Link
                                                to={`/advertise-list?tag=${item}`}
                                                replace
                                            >
                                                <div
                                                    className='px-4 py-2 bg-blue-five rounded-xl text-[8px] font-bold'
                                                    key={idx}
                                                    // onClick={() => navigate(`advertise-list?tag=${item.tags}`)}
                                                >
                                                    {item}
                                                </div>
                                            </Link>
                                        )
                                    )}
                                </div>
                            )}

                            {/* Kontak */}
                            {!isLoadingAdvertisesData && (
                                <>
                                    {advertisesData.data?.contact ||
                                    advertisesData.data?.contact_email ? (
                                        <>
                                            <span className='text-black-five text-xl font-bold'>
                                                {newLang?.contact ?? 'Contact'}
                                            </span>
                                            <div className='flex gap-2'>
                                                {advertisesData.data
                                                    ?.contact && (
                                                    <img
                                                        className='w-6 hover:scale-125 cursor-pointer ease-in-out duration-300'
                                                        src={contactSocial}
                                                        alt='Phone'
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                window.location
                                                                    .href
                                                            )
                                                            Toast(
                                                                'success',
                                                                'copied'
                                                            )
                                                        }}
                                                    />
                                                )}
                                                {advertisesData.data
                                                    ?.contact_email && (
                                                    <a
                                                        href={
                                                            'mailto:' +
                                                            advertisesData.data
                                                                ?.contact_email
                                                        }
                                                    >
                                                        <img
                                                            className='w-6 hover:scale-125 cursor-pointer ease-in-out duration-300'
                                                            src={emailSocial}
                                                            alt='Email'
                                                            onClick={() =>
                                                                (window.location = `${advertisesData.data?.contact_email}`)
                                                            }
                                                        />
                                                    </a>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        ''
                                    )}

                                    {advertisesData.data?.contact_whatsapp ||
                                    advertisesData.data?.contact_we_chat ||
                                    advertisesData.data?.contact_facebook ||
                                    advertisesData.data?.contact_instagram ? (
                                        <>
                                            <span className='text-black-five text-sm font-bold'>
                                                {newLang?.socialMedia ??
                                                    'Social Media'}
                                            </span>
                                            <div className='flex gap-2'>
                                                {advertisesData.data
                                                    ?.contact_whatsapp && (
                                                    <img
                                                        className='w-6 hover:scale-125 cursor-pointer ease-in-out duration-300'
                                                        src={whatsappSocial}
                                                        alt=''
                                                        onClick={() =>
                                                            window.open(
                                                                advertisesData
                                                                    .data
                                                                    ?.contact_whatsapp,
                                                                '_blank'
                                                            )
                                                        }
                                                    />
                                                )}
                                                {advertisesData.data
                                                    ?.contact_we_chat && (
                                                    <img
                                                        className='w-6 hover:scale-125 cursor-pointer ease-in-out duration-300'
                                                        src={wechatSocial}
                                                        alt=''
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                advertisesData
                                                                    .data
                                                                    ?.contact_we_chat
                                                            )
                                                            Toast(
                                                                'success',
                                                                'copied'
                                                            )
                                                        }}
                                                    />
                                                )}
                                                {advertisesData.data
                                                    ?.contact_facebook && (
                                                    <img
                                                        className='w-6 hover:scale-125 cursor-pointer ease-in-out duration-300'
                                                        src={facebookSocial}
                                                        alt=''
                                                        onClick={() =>
                                                            window.open(
                                                                advertisesData
                                                                    .data
                                                                    ?.contact_facebook,
                                                                '_blank'
                                                            )
                                                        }
                                                    />
                                                )}
                                                {advertisesData.data
                                                    ?.contact_instagram && (
                                                    <img
                                                        className='w-6 hover:scale-125 cursor-pointer ease-in-out duration-300'
                                                        src={instagramSocial}
                                                        alt=''
                                                        onClick={() =>
                                                            window.open(
                                                                advertisesData
                                                                    .data
                                                                    ?.contact_instagram,
                                                                '_blank'
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </>
                            )}
                            {/* Kontak */}
                            {!isLoadingAdvertisesData && (
                                <div className='flex flex-col space-y-2'>
                                    <span className='text-black-five text-xl font-bold'>
                                        {newLang?.address ?? 'Address'}
                                    </span>
                                    <span className='text-black-three text-sm font-semibold'>
                                        {advertisesData.data?.address}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='mt-8 flex flex-col'>
                        <span className='text-black text-xl font-bold mb-4'>
                            {newLang?.relatedAds ?? `Related Ads`}
                        </span>
                        {!isLoadingAdvertiseListData && (
                            <Card
                                data={advertiseListData.data}
                                type={'relatedAds'}
                            />
                        )}
                    </div>
                </div>
            </div>
            {modal.open && (
                <Modal
                    visible={modal.open}
                    cancel={() => setModal({ open: false })}
                    title={newLang?.share ?? 'Share'}
                >
                    <div className='text-center flex flex-col items-center'>
                        <div className='w-full flex items-center'>
                            <div className='w-9/12'>{`${window.location.href}?language=${advertisesData.language}`}</div>
                            <div className='w-3/12'>
                                <img
                                    className='w-5 cursor-pointer'
                                    src={CopyIconSvg}
                                    alt=''
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            `${window.location.href}?language=${advertisesData.language}`
                                        )
                                        Toast('success', 'copied')
                                    }}
                                />
                            </div>
                        </div>

                        <button
                            className='btn rounded-full bg-blue-one text-white w-full mt-5'
                            onClick={() => setModal({ open: false })}
                        >
                            {newLang?.ok ?? 'Ok'}
                        </button>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default AdsDetail
