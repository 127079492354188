import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    is_include : false,
    currencys: [],
}

export const currency = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        addCurrency: (state, action) => {
            state.currencys = [action.payload, ...state.currencys]
            state.is_include = true
        },
        deleteCurrency: (state, action) => {
            state.currencys = state.currencys.filter(currency => action.payload != currency.id)
            if (state.currencys.length == 0) state.is_include = false
        },
    },
})

export const { addCurrency, deleteCurrency } = currency.actions

export default currency.reducer