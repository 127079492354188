import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    additional: {},
}

export const additional = createSlice({
    name: 'additional',
    initialState,
    reducers: {
        saveAdditional: (state, action) => {
            state.additional = { ...action.payload }
        },
    },
})

export const { saveAdditional } = additional.actions

export default additional.reducer
