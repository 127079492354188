import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toValue } from 'Utils/conversion'
import { AuthContext } from 'Context/authContext'
import { fetchPaymentTopup } from 'Api/profile'
import { useMutation } from '@tanstack/react-query'
import Toast from 'Utils/toast'
import { fetchConfirmPayment } from 'Api/payment'

const WaitingPaymentHp = () => {
    const navigate = useNavigate()
    const { token, newLang } = useContext(AuthContext)

    const location = useLocation()
    const { data, channel } = location.state

    useEffect(() => {
        const isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'))
        !isLoggedin && navigate('/')
    }, [])

    const { isLoading: isLoadingConfirmPayment, mutate: mutateConfirmPayment } =
        useMutation(fetchConfirmPayment, {
            onSuccess: (data) => {
                if (data?.message?.response_code == '00') {
                    Toast('success', data?.message?.response_message)
                    navigate('/')
                } else {
                    Toast('warning', data?.message?.response_message)
                }
            },
        })

    const handleConfirmPayment = () => {
        mutateConfirmPayment({ token, id: data.transaction_id })
    }

    return (
        <div className='pt-[100px] md:pt-[200px] max-w-screen-xl m-auto flex flex-col md:flex-row mb-[100px] min-h-screen md:justify-evenly p-4'>
            <div className='w-full md:w-1/2 mx-auto flex flex-col px-10 gap-4'>
                <h2 className='text-xl font-bold'>
                    {newLang?.waitingPayment ?? 'Waiting Payment'}
                </h2>
                <div className='mt-5 bg-[#f4f4f4] rounded-2xl p-4 justify-center flex flex-col gap-4'>
                    {/* <div className='flex flex-col w-full text-center gap-2'>
                        <img className='w-20 m-auto' src={BriVaSvg} alt='' />
                        <span className='text-ss font-bold'>
                            Recipient Bank Name
                        </span>
                    </div>
                    <div className='bg-white rounded-2xl p-4 relative flex flex-col text-center'>
                        <span className='text-base font-bold'>
                            081223456789
                        </span>
                        <span className='text-ss'>
                            Interbank Transfer Code : 123
                        </span>
                        <span className='text-sm absolute right-10 cursor-pointer'>
                            Copy
                        </span>
                    </div> */}
                    <div className='flex justify-between px-2'>
                        <span className='text-ss'>
                            {newLang?.nominal ?? 'Nominal'}
                        </span>
                        <span className='text-ss font-bold'>
                            Rp {toValue(parseInt(data.amount) - (parseInt(data.platform_fee) + parseInt(data.payment_service_fee) + parseInt(data.unique_code)))},00
                        </span>
                    </div>
                    <div className='flex justify-between px-2'>
                        <span className='text-ss'>
                            {newLang?.uniqueCode ?? 'Unique Code'}
                        </span>
                        <span className='text-ss font-bold'>
                            Rp {toValue(data.unique_code)},00
                        </span>
                    </div>
                    <div className='flex justify-between px-2'>
                        <span className='text-ss'>
                            {newLang?.paymentServiceFee ?? 'Payment service fee'}
                        </span>
                        <span className='text-ss font-bold'>
                            Rp {toValue(data.payment_service_fee)},00
                        </span>
                    </div>
                    <div className='flex justify-between px-2'>
                        <span className='text-ss'>
                            {newLang?.platformFee ?? 'Platform Fee'}
                        </span>
                        <span className='text-ss font-bold'>
                            Rp {toValue(data.platform_fee)},00
                        </span>
                    </div>
                    <div className='flex justify-between bg-[#D7D7D7] rounded-2xl px-4 py-2 text-black-three'>
                        <span className='text-xs font-bold'>
                            Total Transfer
                        </span>
                        <span className='text-xs font-bold'>
                            Rp {toValue(data.amount)},00
                        </span>
                    </div>
                    <div className='flex flex-col gap-2'>
                        {Object.entries(channel.payment_gateway).map(
                            ([key, value]) => {
                                if (value) {
                                    return (
                                        <div className='collapse collapse-arrow collapse-open border border-base-300 bg-base-100 rounded-box'>
                                            <input type='checkbox' />
                                            <div className='collapse-title text-xl font-medium'>
                                                {key.replace('_', ' ')}
                                            </div>
                                            <div className='collapse-content flex flex-col gap-2'>
                                                {Object.entries(value).map(
                                                    ([key, value]) => {
                                                        if (
                                                            key ==
                                                            'bank_qr_code_url'
                                                        ) {
                                                            return (
                                                                <img
                                                                    className='w-full m-auto'
                                                                    src={value}
                                                                    alt=''
                                                                />
                                                            )
                                                        } else if (
                                                            key == 'qr_code'
                                                        ) {
                                                            return (
                                                                <img
                                                                    className='w-full m-auto'
                                                                    src={value}
                                                                    alt=''
                                                                />
                                                            )
                                                        } else if (
                                                            key ==
                                                            'bank_logo_url'
                                                        ) {
                                                            return (
                                                                <img
                                                                    className='w-[50%] m-auto'
                                                                    src={value}
                                                                    alt=''
                                                                />
                                                            )
                                                        } else if (
                                                            key ==
                                                                'code_bank' &&
                                                            value
                                                        ) {
                                                            return (
                                                                <div className='bg-gray-100 px-2 py-1 rounded-2xl'>
                                                                    <div className='flex justify-between'>
                                                                        <span className='font-medium'>
                                                                            {key.replace(
                                                                                '_',
                                                                                ' '
                                                                            )}
                                                                        </span>
                                                                        <span className=''>
                                                                            {
                                                                                value
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        } else if (
                                                            key ==
                                                            'account_name'
                                                        ) {
                                                            return (
                                                                <div className='bg-gray-100 px-2 py-1 rounded-2xl'>
                                                                    <div className='flex justify-between'>
                                                                        <span className='font-medium'>
                                                                            {key.replace(
                                                                                '_',
                                                                                ' '
                                                                            )}
                                                                        </span>
                                                                        <span className=''>
                                                                            {
                                                                                value
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        } else if (
                                                            key ==
                                                            'account_number'
                                                        ) {
                                                            return (
                                                                <div className='bg-gray-100 px-2 py-1 rounded-2xl'>
                                                                    <div className='flex justify-between'>
                                                                        <span className='font-medium'>
                                                                            {key.replace(
                                                                                '_',
                                                                                ' '
                                                                            )}
                                                                        </span>
                                                                        <span className=''>
                                                                            {
                                                                                value
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        )}
                    </div>
                    <button
                        className='btn rounded-full min-w-[200px] hover:text-black-six'
                        onClick={() => handleConfirmPayment()}
                    >
                        {newLang?.confirmTransaction ?? 'Confirm Transaction'}
                    </button>
                    <button
                        className='btn bg-transparent border-none text-blue-five rounded-full min-w-[200px] hover:text-black-six'
                        onClick={() => navigate('/')}
                    >
                        {newLang?.cancelTransaction ?? 'Cancel Transaction'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default WaitingPaymentHp
