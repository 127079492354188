import React, { useContext, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { isMobile } from 'react-device-detect'
import { AuthContext } from 'Context/authContext'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchChangeProfile, fetchProfile } from 'Api/profile'

const Username = () => {
    const { token, newLang } = useContext(AuthContext)
    const [username, setUsername] = useState()

    const { data: profile, isLoading: isLoadingProfile } = useQuery(
        ['profile', token],
        () => fetchProfile({ token }),
        {
            onSuccess: (data) => {
                setUsername(data?.data?.username)
            },
        }
    )

    // const { mutate: mutateUpdateProfile, isLoading: isLoadingUpdateProfile } = useMutation(
    //     fetchChangeProfile({ token, payload }),
    //     {
    //         onSuccess: (data) => {
    //         },
    //     }
    // )

    const handleUpdate = () => {
        const payload = {
            first_name: profile?.data?.first_name,
            last_name: profile?.data?.last_name,
            profile_picture_image_url: profile?.data?.profile_picture_image_url,
            phone: profile?.data?.phone,
            email: profile?.data?.email,
            region: profile?.data?.region,
            address: profile?.data?.address,
        }

    }

    return (
        <div
            className={`flex flex-col ${
                isMobile ? 'w-full' : 'w-3/4'
            } gap-5 p-2`}
        >
            {!isMobile && (
                <h1 className='text-black text-xl font-semibold'>
                    {newLang?.change} {newLang?.username}
                </h1>
            )}
            <span className='text-black-three text-ss'>
                You can only change your User Name once, make sure the name is
                correct
            </span>
            <div className='mt-8 flex flex-col gap-5'>
                <div className={`single-input ${!isMobile && 'max-w-[50%]'}`}>
                    <h5 className='label-text text-black font-semibold mb-2'>
                        User Name
                    </h5>
                    <InputText
                        className='w-full rounded-xl text-black'
                        placeholder={'Andre0312'}
                        defaultValue={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
            </div>
            <button
                className='btn bg-blue-five w-36 text-white'
                onClick={() => handleUpdate()}
            >
                Save
            </button>
        </div>
    )
}

export default Username
