import Footer from 'Components/Footer'
import Header from 'Components/Header'
import { Outlet } from 'react-router-dom'

export const MainLayout = () => {
    return (
        <div>
            <Header />
            <Outlet />
            <Footer />
        </div>
    )
}

export default MainLayout
