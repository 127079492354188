import React, { useContext, useEffect, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { useDispatch, useSelector } from 'react-redux'
import { savePassenger } from 'Store/passenger'
import { Calendar } from 'primereact/calendar'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import optionsNationality from 'Constants/nationality.json'
import optionsJob from 'Constants/job.json'
import optionsPlaceOfArrival from 'Constants/place-of-arrival.json'
import { Dropdown } from 'primereact/dropdown'
import Toast from 'Utils/toast'
import { AuthContext } from 'Context/authContext'

const schema = yup
    .object({
        fullName: yup.string().required('Full name is required'),
        email: yup.string(),
        passport: yup.string().min(9, 'Minimum 9 Character'),
        nationality: yup.object().shape({
            name: yup.string().required('Nationality is Required'),
        }),
        dateOfBirth: yup.string().required('Date Of Birth is Required'),
        occupationJob: yup.object().shape({
            name: yup.string().required('Occupation Job is Required'),
        }),
        address: yup.string().required('Address is Required'),
        placeOfArrival: yup.object().shape({
            name: yup.string().required('Place of Arrival is Required'),
        }),
        flightVoyageNumber: yup
            .string()
            .required('Flight / Voyage / Other Carrier Number is Required'),
        dateOfArrival: yup.string().required('Date Of Arrival is Required'),
        weChatNumber: yup.string()
    })
    .required()

const InformationOfPassengger = () => {
    const isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'))
    const [loading, setLoading] = useState(false)
    const { newLang } = useContext(AuthContext)

    const {
        register: registerPassenger,
        handleSubmit: handleSubmitPassenger,
        setValue,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schema),
    })

    const dispatch = useDispatch()

    const { passenger, is_saved } = useSelector((state) => state.passenger)

    if (is_saved) {
        let newDate = (ogdate) => {
            let arr = ogdate.split('-')
            return `${arr[1]}/${arr[0]}/${arr[2]}`
        }

        const newDateOfBirth =
            passenger.date_of_birth && newDate(passenger.date_of_birth)
        const newDateOfArrival =
            passenger.date_of_arrival && newDate(passenger.date_of_arrival)

        setValue('fullName', passenger.full_name)
        setValue('email', passenger.email)
        setValue('passport', passenger.passport_number)
        setValue('nationality', {
            name: passenger.nationality,
            code: passenger.nationality,
        })
        setValue('dateOfBirth', new Date(newDateOfBirth))
        setValue('occupationJob', {
            name: passenger.occupation_job,
            code: passenger.occupation_job,
        })
        setValue('address', passenger.address_in_indonesia)
        setValue('placeOfArrival', {
            name: passenger.place_of_arrival,
            code: passenger.place_of_arrival,
        })
        setValue('dateOfArrival', new Date(newDateOfArrival))
        setValue('flightVoyageNumber', passenger.flight_voyage_number)
        setValue('weChatNumber', passenger.we_chat_number)
    }

    const [image, setImage] = useState(null)
    const [time, setTime] = useState(null)

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]))
        }
    }

    const onSubmit = async (data) => {
        const submitPayload = {
            full_name: data.fullName,
            email: data.email,
            passport_number: data.passport,
            nationality: data.nationality.name,
            date_of_birth: moment(data.dateOfBirth).format('DD-MM-YYYY'),
            occupation_job: data.occupationJob.name,
            address_in_indonesia: data.address,
            place_of_arrival: data.placeOfArrival.name,
            flight_voyage_number: data.flightVoyageNumber,
            date_of_arrival: moment(data.dateOfArrival).format('DD-MM-YYYY'),
            we_chat_number: data.weChatNumber,
        }
        await dispatch(savePassenger(submitPayload))
        Toast('success', 'Saved')
    }

    return (
        <>
            <form onSubmit={handleSubmitPassenger(onSubmit)}>
                <div className='w-full py-4 flex justify-center bg-gray-100 rounded-xl p-2'>
                    <h2 className='text-2xl'>
                        {newLang?.informationOfPassanger ??
                            'Information of Passenger'}
                    </h2>
                </div>
                <div className='grid grid-cols-2 gap-4 py-4'>
                    <div className='flex flex-col gap-2 w-full'>
                        <label>
                            {newLang?.passport ?? 'Passport'}{' '}
                            {newLang?.number ?? 'Number'} :
                        </label>
                        <InputText
                            {...registerPassenger('passport', {
                                required: true,
                            })}
                            name='passport'
                            className='rounded-xl w-full'
                            placeholder={`${newLang?.passport ?? 'Passport'}${
                                newLang?.number ?? 'Number'
                            }`}
                        />
                        {errors.passport && (
                            <p className='text-xs text-red-600'>
                                {errors.passport.message}
                            </p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        <label>{newLang?.fullName ?? 'Full Name'} :</label>
                        <InputText
                            {...registerPassenger('fullName', {
                                required: true,
                            })}
                            name='fullName'
                            className='rounded-xl w-full'
                            placeholder={newLang?.fullName ?? 'Full Name'}
                        />
                        {errors.fullName && (
                            <p className='text-xs text-red-600'>
                                {errors.fullName.message}
                            </p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        <Controller
                            name='dateOfBirth'
                            control={control}
                            rules={{
                                required: 'Date Of Birth is required',
                            }}
                            render={({ field, fieldState }) => (
                                <>
                                    <label htmlFor={field.name}>
                                        {newLang?.dateOfBirth ??
                                            'Date Of Birth'}{' '}
                                        :
                                    </label>
                                    <Calendar
                                        inputId={field.name}
                                        value={field.value}
                                        onChange={field.onChange}
                                        dateFormat='dd/mm/yy'
                                        maxDate={new Date()}
                                    />
                                    {errors.dateOfBirth && (
                                        <p className='text-xs text-red-600'>
                                            {errors.dateOfBirth.message}
                                        </p>
                                    )}
                                </>
                            )}
                        />
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        <label htmlFor='username'>
                            {newLang?.address ?? 'Address'} :
                        </label>
                        <InputText
                            {...registerPassenger('address', {
                                required: true,
                            })}
                            name='address'
                            className='rounded-xl w-full'
                            placeholder={newLang?.address ?? 'Address'}
                        />
                        {errors.address && (
                            <p className='text-xs text-red-600'>
                                {errors.address.message}
                            </p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        <label htmlFor='username'>
                            {newLang?.email ?? 'Email'} (
                            {newLang?.optional ?? 'Optional'}) :
                        </label>
                        <InputText
                            {...registerPassenger('email')}
                            name='email'
                            className='rounded-xl w-full'
                            placeholder={newLang?.email ?? 'Email'}
                        />
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        <label>{newLang?.nationality ?? 'Nationality'} :</label>
                        <Controller
                            name='nationality'
                            control={control}
                            render={({ field, fieldState }) => (
                                <Dropdown
                                    id={field.name}
                                    value={field.value}
                                    optionLabel='name'
                                    placeholder={`${
                                        newLang?.select ?? 'Select'
                                    } ${newLang?.nationality ?? 'Nationality'}`}
                                    options={optionsNationality}
                                    focusInputRef={field.ref}
                                    onChange={(e) => field.onChange(e.value)}
                                />
                            )}
                        />
                        {errors.nationality && (
                            <p className='text-xs text-red-600'>
                                {errors.nationality.name.message}
                            </p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        <label>
                            {newLang?.occupation ?? 'Occupation'} (
                            {newLang?.job ?? 'Job'}) :
                        </label>
                        <Controller
                            name='occupationJob'
                            control={control}
                            render={({ field, fieldState }) => (
                                <Dropdown
                                    id={field.name}
                                    value={field.value}
                                    optionLabel='name'
                                    placeholder={`${
                                        newLang?.select ?? 'Select'
                                    } ${newLang?.job ?? 'job'}`}
                                    options={optionsJob}
                                    focusInputRef={field.ref}
                                    onChange={(e) => field.onChange(e.value)}
                                />
                            )}
                        />
                        {errors.occupationJob && (
                            <p className='text-xs text-red-600'>
                                {errors.occupationJob.name.message}
                            </p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        <label>
                            {newLang?.placeOfArrival ?? 'Place Of Arrival'} :
                        </label>
                        <Controller
                            name='placeOfArrival'
                            control={control}
                            render={({ field, fieldState }) => (
                                <Dropdown
                                    id={field.name}
                                    value={field.value}
                                    optionLabel='name'
                                    placeholder={`${
                                        newLang?.select ?? 'Select'
                                    } ${
                                        newLang?.placeOfArrival ??
                                        'Place Of Arrival'
                                    }`}
                                    options={optionsPlaceOfArrival}
                                    focusInputRef={field.ref}
                                    onChange={(e) => field.onChange(e.value)}
                                />
                            )}
                        />
                        {errors.placeOfArrival ? (
                            <p className='text-xs text-red-600'>
                                {errors.placeOfArrival.name.message}
                            </p>
                        ) : (
                            <p className='text-xs text-blue-600'>
                                Make sure you choose the right place of arrival!
                            </p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        <Controller
                            name='dateOfArrival'
                            control={control}
                            rules={{
                                required: 'Date Of Arrival is required',
                            }}
                            render={({ field, fieldState }) => (
                                <>
                                    <label htmlFor={field.name}>
                                        {newLang?.dateOfArrival ??
                                            'Date Of Arrival'}{' '}
                                        ({newLang?.time ?? 'time'}) :
                                    </label>
                                    <Calendar
                                        inputId={field.name}
                                        value={field.value}
                                        onChange={field.onChange}
                                        dateFormat='dd/mm/yy'
                                    />
                                    {errors.dateOfArrival ? (
                                        <p className='text-xs text-red-600'>
                                            {errors.dateOfArrival.message}
                                        </p>
                                    ) : (
                                        <small id='username-help'>
                                            Last flight arrival stated in the
                                            boarding pass
                                        </small>
                                    )}
                                </>
                            )}
                        />
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        <label htmlFor='username'>
                            {newLang?.flightVoyageNumber ??
                                'Flight Voyage Number'}{' '}
                            :
                        </label>
                        <InputText
                            {...registerPassenger('flightVoyageNumber', {
                                required: true,
                            })}
                            name='flightVoyageNumber'
                            className='rounded-xl w-full'
                            placeholder={
                                newLang?.flightVoyageNumber ??
                                'Flight Voyage Number'
                            }
                        />
                        {errors.flightVoyageNumber ? (
                            <p className='text-xs text-red-600'>
                                {errors.flightVoyageNumber.message}
                            </p>
                        ) : (
                            <p className='text-xs text-blue-600'>
                                Last flight arrival stated in the boarding pass
                            </p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        <label htmlFor='username'>
                            {newLang?.quarantineLetter ?? 'Quarantine Letter'} :
                        </label>
                        <div>
                            <input
                                type='file'
                                className='file-input w-full max-w-xs'
                            />
                            <img
                                alt='preview image'
                                src={passenger?.quarantine}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                        <label>
                            {newLang?.weChat ?? 'We-chat Number'}{' '}
                            {newLang?.number ?? 'Number'} :
                        </label>
                        <InputText
                            {...registerPassenger('weChatNumber')}
                            name='weChatNumber'
                            className='rounded-xl w-full'
                            placeholder={`${
                                newLang?.weChat ?? 'We-chat Number'
                            } ${newLang?.number ?? 'Number'}`}
                        />
                        {errors.weChatNumber && (
                            <p className='text-xs text-red-600'>
                                {errors.weChatNumber.message}
                            </p>
                        )}
                    </div>
                </div>
                <div className='w-full py-4 flex justify-center'>
                    <button
                        className='btn btn-info'
                        onClick={handleSubmitPassenger(onSubmit)}
                    >
                        {newLang?.save ?? 'Save'}
                    </button>
                </div>
            </form>
        </>
    )
}

export default InformationOfPassengger
