import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    options: {
        limit: 10,
        page: 1,
        order: 'created_at',
        sort: 'DESC',
    },
}

export const transactionPointPaging = createSlice({
    name: 'transactionPointPaging',
    initialState,
    reducers: {
        changePointPaging: (state, action) => {
            state.options = {
                ...state.options,
                limit: action.payload.limit,
            }
        },
    },
})

export const { changePointPaging } = transactionPointPaging.actions

export default transactionPointPaging.reducer
