import React, { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import eye from 'Assets/images/eye.svg'
import eyeClose from 'Assets/images/eye-close-line.svg'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { fetchChangePassword } from 'Api/profile'
import { useNavigate } from 'react-router-dom'
import Toast from 'Utils/toast'
import { AuthContext } from 'Context/authContext'
import { useMutation } from '@tanstack/react-query'

const schema = yup
    .object({
        oldPassword: yup.string().required('Old Password is Required'),
        password: yup.string().required('Password is Required'),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
    })
    .required()

const Password = () => {
    const { token, newLang } = useContext(AuthContext)

    const navigate = useNavigate()
    const [showPass, setShowPass] = useState(false)
    const [showCPass, setShowCPass] = useState(false)
    const [showOPass, setShowOPass] = useState(false)

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const { mutate: mutateUpdatePassword, isLoading: isLoadingUpdatePassword } =
        useMutation(fetchChangePassword, {
            onSuccess: (data) => {
                Toast('success', data?.message?.response_message)
                navigate('/profile')
            },
        })

    const onSubmit = (data) => {
        const payload = {
            old_password: data.oldPassword,
            new_password: data.password,
            confirm_new_password: data.confirmPassword,
        }

        mutateUpdatePassword({ token, payload })
    }

    return (
        <div
            className={`flex flex-col ${
                isMobile ? 'w-full' : 'w-3/4'
            } gap-5 p-2`}
        >
            {!isMobile && (
                <h1 className='text-black text-xl font-semibold'>
                    {newLang?.forgotPassword}
                </h1>
            )}
            <span className='text-black-three text-ss'>
                {newLang?.pleaseEnterPassword ??
                    `Please enter input to change the password`}
            </span>
            <div className='mt-8 flex flex-col gap-5'>
                <div className={`single-input ${!isMobile && 'max-w-[50%]'}`}>
                    <h5 className='label-text text-black font-semibold mb-2'>
                        {newLang?.oldPassword}
                    </h5>
                    <InputGroup>
                        <Input
                            className='!text-blue-five !text-base !font-semibold'
                            type={showOPass ? 'text' : 'password'}
                            {...register('oldPassword', { required: true })}
                        />
                        <InputRightElement>
                            <img
                                className='w-5 cursor-pointer'
                                src={showOPass ? eye : eyeClose}
                                alt=''
                                onClick={() => {
                                    setShowOPass(!showOPass)
                                }}
                            />
                        </InputRightElement>
                    </InputGroup>
                    {errors.oldPassword && (
                        <p className='text-xs text-red-600'>
                            {errors.oldPassword.message}
                        </p>
                    )}
                </div>
                <div className={`single-input ${!isMobile && 'max-w-[50%]'}`}>
                    <h5 className='label-text text-black font-semibold mb-2'>
                        {newLang?.newPassword}
                    </h5>
                    <InputGroup>
                        <Input
                            className='!text-blue-five !text-base !font-semibold'
                            type={showPass ? 'text' : 'password'}
                            {...register('password', { required: true })}
                        />
                        <InputRightElement>
                            <img
                                className='w-5 cursor-pointer'
                                src={showPass ? eye : eyeClose}
                                alt=''
                                onClick={() => {
                                    setShowPass(!showPass)
                                }}
                            />
                        </InputRightElement>
                    </InputGroup>
                    {errors.password && (
                        <p className='text-xs text-red-600'>
                            {errors.password.message}
                        </p>
                    )}
                </div>
                <div className={`single-input ${!isMobile && 'max-w-[50%]'}`}>
                    <h5 className='label-text text-black font-semibold mb-2'>
                        {newLang?.confirmPassword}
                    </h5>
                    <InputGroup>
                        <Input
                            className='!text-blue-five !text-base !font-semibold'
                            type={showCPass ? 'text' : 'password'}
                            {...register('confirmPassword', { required: true })}
                        />
                        <InputRightElement>
                            <img
                                className='w-5 cursor-pointer'
                                src={showCPass ? eye : eyeClose}
                                alt=''
                                onClick={() => {
                                    setShowCPass(!showCPass)
                                }}
                            />
                        </InputRightElement>
                    </InputGroup>
                    {errors.confirmPassword && (
                        <p className='text-xs text-red-600'>
                            {errors.confirmPassword.message}
                        </p>
                    )}
                </div>
            </div>
            <button
                className='btn bg-blue-five w-36 text-white'
                onClick={handleSubmit(onSubmit)}
            >
                {newLang?.send}
            </button>
        </div>
    )
}

export default Password
