import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    filter: {
        set_category: true,
        category: 'point',
        set_status: false,
        status: 'success',
    },
}

// Success
// Failed
// Reject
// Waiting for payment
// Waiting for approvement 
// Pending

export const transactionPointFilter = createSlice({
    name: 'transactionPointFilter',
    initialState,
    reducers: {
        changePointFilter: (state, action) => {
            let isStatus = true
            let status = 'semua'
            switch (action.payload.status) {
                case 'all':
                    isStatus = false
                    status = 'semua'
                    break;
                case 'success':
                    isStatus = true
                    status = 'sukses'
                    break;
                case 'waiting payment':
                    isStatus = true
                    status = 'waiting for payment'
                    break;
                case 'cancelled':
                    isStatus = true
                    status = 'failed'
                    break;
            }
            state.filter = {
                ...state.filter,
                set_status: isStatus,
                status: status,
            }
        },
        changePointCategory: (state, action) => {
            state.filter = {
                ...state.filter,
                category: action.payload.category,
            }
        },
    },
})

export const { changePointFilter, changePointCategory } = transactionPointFilter.actions

export default transactionPointFilter.reducer
