import React, { useContext, useState } from 'react'
import InfoRunning from 'Assets/images/info-running.svg'
import { fetchAdvertiseRow } from 'Api/advertise'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'Context/authContext'
import moment from 'moment'
import { Skeleton } from 'primereact/skeleton'

const RunningText = () => {
    const { token } = useContext(AuthContext)
    const navigate = useNavigate()

    const [query, setQuery] = useState({
        set_title: false,
        title: '',
        set_category: false,
        // category: 1,
        category: [],
        set_tag: false,
        tags: '',
        set_status: true,
        status: 1,
    })

    const [options, setOptions] = useState({
        limit: 5,
        page: 1,
        order: 'created_at',
        sort: 'ASC',
    })

    const { data: advertiseRowData, isLoading: isLoadingAdvertiseRowData } =
        useQuery(
            ['row-advertise', query, token],
            () => fetchAdvertiseRow({ token, query, options }),
            {
                onSuccess: (data) => {},
            }
        )

    return (
        <div className='w-full bg-[#E5E5E5] p-4 max-w-screen-xl m-auto my-2 rounded-xl flex'>
            <img src={InfoRunning} alt='Running Text' />
            <marquee
                className='overflow-hidden ml-4'
                behavior='scroll'
                direction='left'
            >
                {!isLoadingAdvertiseRowData ? (
                    advertiseRowData.data?.map((item, idx) => (
                        <span
                            className='text-black-six text-sm font-normal'
                            key={item.id}
                        >
                            {/* {moment(`${item.created_at}`).format(
                                'YYYY,MMM DD HH-mm'
                            )} */}
                            {/* -  */}
                            {`${item.title}`} - {`${item.description}`}
                            {advertiseRowData.data.length == idx ? '' : ' | '}
                        </span>
                    ))
                ) : (
                    <Skeleton width='100%' height='3rem' />
                )}
            </marquee>
        </div>
    )
}

export default RunningText
