import React, { useContext, useState } from 'react'
import FaqSvg from 'Assets/images/faq.svg'
import { AuthContext } from 'Context/authContext'
import { useQuery } from '@tanstack/react-query'
import { fetchFaq } from 'Api/legal'

const Faq = () => {
    const { token } = useContext(AuthContext)

    const [query, setQuery] = useState({
        set_question: false,
        question: '',
        set_answer: false,
        answer: '',
    })

    const [options, setOptions] = useState({
        order: 'order_number',
        sort: 'ASC',
    })

    const { data: faqData, isLoading: isLoadingFaq } = useQuery(
        ['faq', token],
        () => fetchFaq({ token, query, options }),
        {
            onSuccess: (data) => {
            },
        }
    )
    return (
        <div className='pt-[100px] md:pt-[200px] min-h-screen max-w-screen-xl m-auto p-5 md:p-0'>
            <div className='flex flex-col py-5'>
                {/* <div className='flex flex-wrap mb-5 md:mb-0'>
                    <div className='w-full md:w-1/2 flex justify-center'>
                        <img src={FaqSvg} alt='' />
                    </div>
                    <div className='w-full h-full md:w-1/2 flex flex-col m-auto'>
                        <span className='text-black text-2xl font-bold mb-5 text-left'>
                            FAQ
                        </span>
                        <p className='text-black text-base font-normal'>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. At voluptates vero nihil nostrum porro est
                            tempora dolores quo minus ducimus, eligendi, dolorem
                            atque temporibus provident molestiae fugiat
                            consequuntur molestias harum optio distinctio
                            officiis. Possimus voluptatibus nesciunt distinctio
                            aliquid excepturi natus ea! Cumque sit asperiores
                            doloremque ipsa.
                        </p>
                    </div>
                </div> */}
                <div className='flex flex-col justify-center space-y-6'>
                    {!isLoadingFaq &&
                        faqData?.data?.map((item) => (
                            <div
                                tabindex='0'
                                className='collapse collapse-arrow border border-base-300 bg-white rounded-box'
                                key={item.no}
                            >
                                <div className='collapse-title text-xl font-medium text-black'>
                                    {item.question}
                                </div>
                                <div className='collapse-content text-black'>
                                    <div
                                        className=''
                                        dangerouslySetInnerHTML={{
                                            __html: item.answer,
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    {/* <div
                        tabindex='0'
                        className='collapse collapse-arrow border border-base-300 bg-white rounded-box'
                    >
                        <div className='collapse-title text-xl font-medium text-black'>
                            Description 1
                        </div>
                        <div className='collapse-content text-black'>
                            <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Maxime asperiores minima
                                voluptatum, nisi ducimus vero aliquam ea
                                corporis veniam reprehenderit unde, cupiditate
                                quas voluptates, excepturi quaerat. Itaque
                                veniam, illo eos, magnam explicabo tempora
                                accusamus non, ducimus obcaecati expedita libero
                                repellat laborum saepe laudantium rem veritatis
                                iure cum ut dignissimos a.
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Faq
