import React, { useContext, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'Styles/Carousel.css'
import 'swiper/css'
// import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Pagination, Autoplay, Navigation } from 'swiper'
import Vector from 'Assets/images/kategori-pilihan-vector.svg'
import PrevVector from 'Assets/images/arrow-prev-pilihan.svg'
import NextVector from 'Assets/images/arrow-prev-pilihan.svg'
import { useNavigate } from 'react-router-dom'
import { fetchListCatWithTotal } from 'Api/advertise'
import { useQuery } from '@tanstack/react-query'
import { AuthContext } from 'Context/authContext'

const data = [
    {
        name: 'Category 1',
        desc: 'There are 10 ads in this category',
    },
    {
        name: 'Category 2',
        desc: 'There are 10 ads in this category',
    },
    {
        name: 'Category 3',
        desc: 'There are 10 ads in this category',
    },
    {
        name: 'Category 1',
        desc: 'There are 10 ads in this category',
    },
    {
        name: 'Category 2',
        desc: 'There are 10 ads in this category',
    },
    {
        name: 'Category 3',
        desc: 'There are 10 ads in this category',
    },
]

const KategoryPilihan = () => {
    const { token, newLang } = useContext(AuthContext)

    const navigate = useNavigate()

    const { data: categoryData, isLoading: isLoadingCategoryData } = useQuery(
        ['category-total-data', token],
        () => fetchListCatWithTotal({ token }),
        {
            onSuccess: (data) => {},
        }
    )

    return (
        <div className='flex flex-col border-2 border-black-seven/[.55] p-2 md:p-5 rounded-xl w-full md:w-1/2 md:h-[220px]'>
            <span className='text-black-two text-sm font-bold'>
                {newLang?.prefferedCategory ?? 'Preferred Category'}
            </span>
            <div className='flex justify-around py-2 md:py-6 relative'>
                {/* swiper */}
                {!isLoadingCategoryData && (
                    <>
                        <Swiper
                            // pagination={true}
                            slidesPerView={3}
                            // navigation={true}
                            navigation={{
                                prevEl: '.prev',
                                nextEl: '.next',
                            }}
                            // centeredSlides={true}
                            clickable={true}
                            spaceBetween={10}
                            modules={[Pagination, Autoplay, Navigation]}
                            className='mySwiper'
                        >
                            {categoryData.data?.map((item, idx) => (
                                <SwiperSlide
                                    className='w-full h-full group cursor-pointer'
                                    key={idx}
                                >
                                    <div
                                        className='p-2 md:p-4 rounded-[10px] bg-blue-five h-8 md:h-24 w-full relative border-none'
                                        onClick={() =>
                                            navigate(
                                                `advertise-list?cat=${item.id}`
                                            )
                                        }
                                    >
                                        <div className='flex flex-col justify-between text-white space-y-2 md:mt-2 group-hover:mt-0 ease-in-out duration-300'>
                                            <span className='text-ss md:text-base font-bold'>
                                                {item.title}
                                            </span>
                                            <span className='text-ss font-normal hidden md:inline'>
                                                {`${
                                                    newLang?.thereAre ??
                                                    'There are'
                                                } ${item.total_data} ${
                                                    newLang?.adsInCategory ??
                                                    'ads in this category'
                                                }`}
                                            </span>
                                            <div className='hidden h-0.5 w-16 bg-white group-hover:inline ease-in-out duration-300'></div>
                                            <div className='space-y-0 hidden md:inline'>
                                                <img
                                                    className='w-8 absolute top-2 right-2 opacity-20'
                                                    src={Vector}
                                                    alt=''
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className='prev text-black absolute top-1/2 -left-6 md:-left-14 transform -translate-y-1/2 z-10 cursor-pointer select-none'>
                            <div className='w-[30px] h-[30px] md:w-[53px] md:h-[53px] bg-white drop-shadow-lg rounded-full flex justify-center items-center'>
                                <img
                                    className='w-[12px] h-[12px] md:w-[20px] md:h-[20px] rotate-180'
                                    src={PrevVector}
                                    alt='Navigation'
                                />
                            </div>
                        </div>
                        <div className='next text-black absolute top-1/2 -right-6 md:-right-14 transform -translate-y-1/2 z-10 cursor-pointer select-none'>
                            <div className='w-[30px] h-[30px] md:w-[53px] md:h-[53px] bg-white drop-shadow-lg rounded-full flex justify-center items-center'>
                                <img
                                    className='w-[12px] h-[12px] md:w-[20px] md:h-[20px]'
                                    src={PrevVector}
                                    alt='Navigation'
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default KategoryPilihan
