import { InputText } from 'primereact/inputtext'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { addImei, deleteImei } from 'Store/imei'
import { InputNumber } from 'primereact/inputnumber'
import { api } from 'Utils/axios'
import { useMutation } from '@tanstack/react-query'
import { AuthContext } from 'Context/authContext'
import { toast } from 'react-toastify'
import Toast from 'Utils/toast'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'

const schema = yup
    .object({
        imei1: yup.string().required('Imei 1 is Required').nullable(),
        imei2: yup.string().nullable(),
        brand: yup.string().required('Brand is Required'),
        type: yup.string().required('Type is Required'),
        ram: yup.string(),
        storage: yup.string(),
        currency: yup.string().required('Currency is Required'),
        value: yup.string().required('Value is Required').nullable(),
        color: yup.string(),
        owner: yup.string().required('Owner is Required'),
        taxNumber: yup.string(),
    })
    .required()

const Imei = () => {
    const isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'))
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { newLang } = useContext(AuthContext)

    const { passenger, is_saved } = useSelector((state) => state.passenger)
    const { imeis } = useSelector((state) => state.imei)

    useEffect(() => {
        !isLoggedin && navigate('/')
    }, [])

    const {
        register: registerImei,
        handleSubmit: handleSubmitImei,
        setValue,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schema),
    })

    if (is_saved) {
        setValue('owner', passenger.full_name)
    } else {
        Toast('warning', 'fill passanger data')
        setTimeout(() => {
            // window.location.replace('/imei')
            navigate('/imei')
        }, 3000)
    }

    const onSubmitImei = (data) => {
        const submitPayload = {
            imei_1: data.imei1,
            imei_2: data.imei2 || '',
            brand: data.brand,
            type: data.type,
            ram: data.ram,
            storage: data.storage,
            currency: data.currency,
            value: parseInt(data.value),
            color: data.color,
            owner: data.owner,
            tax_number: data.taxNumber,
        }
        dispatch(addImei({ ...submitPayload, id: uuidv4() }))
    }
    const handleDelete = (id) => dispatch(deleteImei(id))

    const handleToPayment = () => {
        navigate('/payment-imei')
    }

    return (
        <div
            className={`pt-[100px] ${
                isLoggedin ? 'md:pt-[200px]' : 'md:pt-[175px]'
            } max-w-screen-xl min-h-screen m-auto p-2`}
        >
            <div className='w-full py-4 flex flex-col justify-center bg-gray-100 rounded-xl p-2'>
                <h2 className='text-2xl text-center'>
                    {newLang?.informationOfPassanger ??
                        'Information of Passenger'}
                </h2>
                <h3 className='text-lg text-center'>
                    {newLang?.handphoneComputerTablet ??
                        'Handphone, Handheld Computer, and Tablet'}
                </h3>
                <span className='text-center'>
                    {newLang?.handphoneComputerTabletDesc ??
                        `Handphones, handheld computers, and tablet computers
                        obtained from abroad that have not registered IMEI`}
                </span>
                <span className='text-center text-red-500'>
                    {newLang?.foreigners ??
                        `Foreigners who are going to stay less than 90 days in
                        Indonesia, will not be necessary to register your IMEI.`}
                </span>
            </div>
            <div className='w-full'>
                <div className='grid grid-cols-2 gap-4'>
                    <div className='flex flex-col gap-2 p-2'>
                        <Controller
                            name='imei1'
                            control={control}
                            render={({ field, fieldState }) => (
                                <>
                                    <label htmlFor='username'>
                                        {newLang?.imei ?? 'Imei'} :
                                    </label>
                                    <InputNumber
                                        id={field.name}
                                        inputRef={field.ref}
                                        value={field.value}
                                        onValueChange={(e) => field.onChange(e)}
                                        useGrouping={false}
                                    />
                                    {errors.imei1 && (
                                        <p className='text-xs text-red-600'>
                                            {errors.imei1.message}
                                        </p>
                                    )}
                                </>
                            )}
                        />
                    </div>
                    <div className='flex flex-col gap-2 p-2'>
                        <Controller
                            name='imei2'
                            control={control}
                            render={({ field, fieldState }) => (
                                <>
                                    <label htmlFor='username'>
                                        {newLang?.imei2 ?? 'Imei 2'} :
                                    </label>
                                    <InputNumber
                                        id={field.name}
                                        inputRef={field.ref}
                                        value={field.value}
                                        onValueChange={(e) => field.onChange(e)}
                                        useGrouping={false}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='flex flex-col gap-2 p-2'>
                        <label htmlFor='username'>
                            {newLang?.brand ?? 'Brand'} :
                        </label>
                        <InputText
                            {...registerImei('brand', {
                                required: true,
                            })}
                        />
                        {errors.brand && (
                            <p className='text-xs text-red-600'>
                                {errors.brand.message}
                            </p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2 p-2'>
                        <label htmlFor='username'>
                            {newLang?.type ?? 'Type'} :
                        </label>
                        <InputText
                            {...registerImei('type', {
                                required: true,
                            })}
                        />
                        {errors.type && (
                            <p className='text-xs text-red-600'>
                                {errors.type.message}
                            </p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2 p-2'>
                        <label htmlFor='username'>
                            {newLang?.ram ?? 'Ram'} :
                        </label>
                        <InputText {...registerImei('ram')} />
                    </div>
                    <div className='flex flex-col gap-2 p-2'>
                        <label htmlFor='username'>
                            {newLang?.storage ?? 'Storage'} :
                        </label>
                        <InputText {...registerImei('storage')} />
                    </div>
                    <div className='flex flex-col gap-2 p-2'>
                        <label htmlFor='username'>
                            {newLang?.currency ?? 'Currency'} :
                        </label>
                        <InputText
                            {...registerImei('currency', {
                                required: true,
                            })}
                        />
                        {errors.currency && (
                            <p className='text-xs text-red-600'>
                                {errors.currency.message}
                            </p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2 p-2'>
                        <Controller
                            name='value'
                            control={control}
                            render={({ field, fieldState }) => (
                                <>
                                    <label htmlFor='username'>
                                        {newLang?.value ?? 'Value'} :
                                    </label>
                                    <InputNumber
                                        id={field.name}
                                        inputRef={field.ref}
                                        value={field.value}
                                        onValueChange={(e) => field.onChange(e)}
                                        useGrouping={false}
                                    />
                                    {errors.value && (
                                        <p className='text-xs text-red-600'>
                                            {errors.value.message}
                                        </p>
                                    )}
                                </>
                            )}
                        />
                    </div>
                    <div className='flex flex-col gap-2 p-2'>
                        <label htmlFor='username'>
                            {newLang?.color ?? 'Color'} :
                        </label>
                        <InputText {...registerImei('color')} />
                    </div>
                    <div className='flex flex-col gap-2 p-2'>
                        <label htmlFor='username'>
                            {newLang?.owner ?? 'Owner'} :
                        </label>
                        <InputText
                            disabled={true}
                            {...registerImei('owner', {
                                required: true,
                            })}
                            value={passenger.full_name}
                        />
                    </div>
                    <div className='flex flex-col gap-2 p-2'>
                        <label htmlFor='username'>
                            {newLang?.taxNumber ?? 'Tax Number'} :
                        </label>
                        <InputText {...registerImei('taxNumber')} />
                    </div>
                </div>
            </div>
            <div className='flex flex-wrap w-full'>
                <div className='w-full py-4 flex justify-center'>
                    <button
                        className='btn btn-info'
                        onClick={handleSubmitImei(onSubmitImei)}
                    >
                        {newLang?.add ?? 'Add'}
                    </button>
                </div>
                <div className='overflow-x-auto w-full'>
                    <table className='table w-full'>
                        <thead>
                            <tr>
                                <th>{newLang?.imei ?? 'Imei'}</th>
                                <th>{newLang?.imei2 ?? 'Imei 2'}</th>
                                <th>{newLang?.brand ?? 'Brand'}</th>
                                <th>{newLang?.type ?? 'Type'}</th>
                                <th>{newLang?.ram ?? 'Ram'}</th>
                                <th>{newLang?.storage ?? 'Storage'}</th>
                                <th>{newLang?.currency ?? 'Currency'}</th>
                                <th>{newLang?.value ?? 'Value'}</th>
                                <th>{newLang?.color ?? 'Color'}</th>
                                <th>{newLang?.owner ?? 'Owner'}</th>
                                <th>{newLang?.taxNumber ?? 'Tax Number'}</th>
                                <th>{newLang?.action ?? 'Action'}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* row 1 */}
                            {imeis.length != 0 &&
                                imeis.map((item, idx) => (
                                    <tr key={idx}>
                                        <th>{item.imei_1}</th>
                                        <td>{item.imei_2}</td>
                                        <td>{item.brand}</td>
                                        <td>{item.type}</td>
                                        <td>{item.ram}</td>
                                        <td>{item.storage}</td>
                                        <td>{item.currency}</td>
                                        <td>{item.value}</td>
                                        <td>{item.color}</td>
                                        <td>{item.owner}</td>
                                        <td>{item.tax_number}</td>
                                        <td>
                                            <button
                                                className='btn btn-outline btn-error btn-sm'
                                                onClick={() =>
                                                    handleDelete(item.id)
                                                }
                                            >
                                                {newLang?.delete ?? 'Delete'}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <div className='w-full py-4 flex justify-center gap-2'>
                    <button className='btn' onClick={() => navigate(-1)}>
                        Prev
                    </button>
                    <button
                        className='btn btn-success'
                        // onClick={handleSubmitCustomsDeclaration}
                        onClick={handleToPayment}
                    >
                        {newLang?.submit ?? 'Submit'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Imei
