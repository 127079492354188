import { api } from "Utils/axios"

export const fetchPrivacy = ({ token }) => {
    return api
        .get(`privacy-policy`, {
            headers: {
                language: localStorage.getItem('lang'),
                'nihao-token': token,
            },
        })
        .then((res) => res?.data)
}

export const fetchAboutUs = ({ token }) => {
    return api
        .get(`about-us`, {
            headers: {
                language: localStorage.getItem('lang'),
                'nihao-token': token,
            },
        })
        .then((res) => res?.data)
}

export const fetchFaq = ({ token, query, options }) => {
    return api
        .post(
            'faq/list',
            {
                filter: {
                    ...query,
                },
                ...options,
            },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchTnC = ({ token, query, options }) => {
    return api
        .post(
            'tnc/list',
            {
                filter: {
                    ...query,
                },
                ...options,
            },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}
