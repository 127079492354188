import React, { useContext, useEffect } from 'react'
import BriVaSvg from 'Assets/images/bri-va.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from 'Context/authContext'
import { toValue } from 'Utils/conversion'
import { fetchPosting } from 'Api/payment'
import { useMutation } from '@tanstack/react-query'
import Toast from 'Utils/toast'

const DetailPaymentInternet = () => {
    const navigate = useNavigate()
    const { token, newLang } = useContext(AuthContext)

    const location = useLocation()
    const { data, channel, method_id } = location.state

    useEffect(() => {
        const isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'))
        !isLoggedin && navigate('/')
    }, [])

    // Posting
    const { isLoading: isLoadingPosting, mutate: mutatePosting } = useMutation(
        fetchPosting,
        {
            onSuccess: (data) => {
                if (data?.message?.response_code == '00') {
                    navigate('/waiting-payment-net', {
                        state: {
                            data: data.data,
                            channel: channel,
                        },
                    })
                }
            },
        }
    )

    const onSubmit = () => {
        const payload = {
            type_transaction: 'postpaid',
            transaction_id: data.transaction_id,
            payment_method: method_id,
            payment_channel: channel.id,
            use_point: false,
        }
        mutatePosting({ token, payload })
    }

    return (
        <div className='pt-[100px] md:pt-[200px] max-w-screen-xl m-auto flex flex-col md:flex-row mb-[100px] min-h-screen md:justify-evenly p-4'>
            <div className='w-full md:w-4/12 flex flex-col'>
                <h2 className='text-xl font-bold'>
                    {newLang?.paymentDetail ?? 'Payment Detail'}
                </h2>
                <span className='mt-4'>
                    {newLang?.yourBill ?? 'Hi, Here is your bill'}
                </span>
                <div className='tabel flex flex-col mt-4 gap-2'>
                    <div className='flex flex-nowrap justify-between items-center'>
                        <span className='text-xs'>
                            {newLang?.operator ?? 'Operator'}
                        </span>
                        <span className='text-xs font-bold'>{data.brand}</span>
                    </div>
                    <div className='flex flex-nowrap justify-between items-center'>
                        <span className='text-xs'>
                            {newLang?.number ?? 'Number'}
                        </span>
                        <span className='text-xs font-bold'>
                            {data.destination_number}
                        </span>
                    </div>
                    <div className='flex flex-nowrap justify-between items-center'>
                        <span className='text-xs'>
                            {newLang?.price ?? 'Price'}
                        </span>
                        <span className='text-xs font-bold'>
                            Rp {toValue(data.amount)},00
                        </span>
                    </div>
                </div>
                <div className='flex flex-nowrap justify-between items-center bg-[#F4F4F4] rounded-xl p-4 mt-4'>
                    <span className='text-xs font-bold'>
                        {newLang?.totalPayment ?? 'Total Payment'}
                    </span>
                    <span className='text-sm font-bold'>
                        Rp {toValue(data.amount)},00
                    </span>
                </div>
            </div>
            <div className='w-full md:w-4/12 flex flex-col mt-5'>
                <h2 className='text-xl font-bold'>
                    {newLang?.paymentMethod ?? 'Payment Method'}
                </h2>
                <div className='form-control border-2 rounded-xl p-2 w-full md:w-auto mt-4'>
                    <label className='label cursor-pointer gap-2 justify-evenly'>
                        <input
                            type='radio'
                            name='radio-6 radio-instant'
                            className='radio w-5 h-5 checked:bg-blue-four'
                            defaultChecked={true}
                        />
                        <div className='flex gap-2'>
                            {/* <img className='w-10' src={BriVaSvg} alt='' /> */}
                            <div className='flex flex-row gap-2'>
                                <img
                                    className='w-10'
                                    src={
                                        channel?.payment_gateway
                                            ?.manual_transfer?.bank_logo_url
                                    }
                                    alt=''
                                />
                                <span className='label-text text-base font-normal'>
                                    {channel.name}
                                </span>
                            </div>
                        </div>
                    </label>
                </div>
                <div className='mt-5'>
                    <button
                        className='btn bg-blue-five text-white rounded-full min-w-[200px]'
                        onClick={() => onSubmit()}
                    >
                        {newLang?.pay ?? 'Pay'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DetailPaymentInternet
