import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import DigiPulsaSVG from 'Assets/images/digi-pulsa.svg'
import Toast from 'Utils/toast'
import { useContext, useEffect } from 'react'
import PpobPulsa from 'Assets/images/ppob-pulsa.svg'
import PpobPaketData from 'Assets/images/ppob-paketData.svg'
import PpobPascaBayar from 'Assets/images/ppob-pascaBayar.svg'
import PpobInternet from 'Assets/images/ppob-internet.svg'
import PpobTokenListrik from 'Assets/images/ppob-tokenListrik.svg'
import PpobTv from 'Assets/images/ppob-tv.svg'
import PpobBpjs from 'Assets/images/ppob-bpjs.svg'
import PpobPdam from 'Assets/images/ppob-pdam.svg'
import PpobEmoney from 'Assets/images/ppob-emoney.svg'
import { AuthContext } from 'Context/authContext'

const DigitalProductLayout = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { newLang } = useContext(AuthContext)

    const data = [
        {
            name: newLang?.phoneAirtime ?? 'Credit',
            path: '/credit',
            img: PpobPulsa,
            title: newLang?.titlePulsa ?? 'Beli Pulsa di Nihao',
            desc: newLang?.expPulsa ?? 'Beli Pulsa di aplikasi ini saja Lebih Mudah, Murah dan Aman',
        },
        {
            name: newLang?.phoneData ?? 'Phone Data',
            path: '/credit-data',
            img: PpobPaketData,
            title: newLang?.titlePhone ?? 'Beli Paket Data di Nihao',
            desc: newLang?.expPhone ?? 'Beli Paket data di aplikasi ini saja Lebih Mudah, Murah dan Aman',
        },
        {
            name: newLang?.postpaid ?? 'Pascabayar',
            path: '/credit-hp',
            img: PpobPascaBayar,
            title: newLang?.titlePostpaid ?? 'Bayar Tagihan Pascabayar',
            desc: newLang?.expPostpaid ?? 'Bayar Tagihan Pascabayar di aplikasi ini saja Lebih Mudah, Murah dan Aman',
        },
        {
            name: newLang?.internet ?? 'Internet',
            path: '/credit-net',
            img: PpobInternet,
            title: newLang?.titleInternet ?? 'Internet',
            desc: newLang?.expInternet ?? 'Bayar Internet di aplikasi ini saja Lebih Mudah, Murah dan Aman',
        },
        {
            name: newLang?.prepaidElectricity ?? 'Token Listrik',
            path: '/credit-token',
            img: PpobTokenListrik,
            title: newLang?.titleTokenListrik ?? 'Beli Token Listrik di Nihao',
            desc: newLang?.expTokenListrik ?? 'Beli Token di aplikasi ini saja Lebih Mudah, Murah dan Aman',
        },
        {
            name: newLang?.tvPascabayar ?? 'Tv Pascabayar',
            path: '/credit-tv',
            img: PpobTv,
            title: newLang?.titleTvPascabayar ?? 'TV Pascabayar',
            desc: newLang?.expTvPascabayar ??'Bayar TV Pascabayar di aplikasi ini saja Lebih Mudah, Murah dan Aman',
        },
        {
            name: newLang?.bpjs ?? 'BPJS',
            path: '/credit-bpjs',
            img: PpobBpjs,
            title: newLang?.titleBpjs ?? 'Bayar BPJS',
            desc: newLang?.expBpjs ?? 'Bayar BPJS di aplikasi ini saja Lebih Mudah, Murah dan Aman',
        },
        {
            name: 'PDAM',
            path: '/credit-pdam',
            img: PpobPdam,
            title: newLang?.titlePdam ?? 'Bayar PDAM',
            desc: newLang?.expPdam ?? 'Bayar PDAM di aplikasi ini saja Lebih Mudah, Murah dan Aman',
        },
        {
            name: newLang?.emoney ?? 'E-Money',
            path: '/credit-emoney',
            img: PpobEmoney,
            title: newLang?.titleEmoney ?? 'E-Money',
            desc: newLang?.expEmoney ?? 'Top up Emoney di aplikasi ini saja Lebih Mudah, Murah dan Aman',
        },
    ]

    useEffect(() => {
        const isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'))
        if (!isLoggedin) {
            Toast('error', 'User not login')
            navigate('/')
        }
    }, [])

    const result = data.filter((item) => item.path == location.pathname)
    const other = data.filter((item) => item.path != location.pathname)

    return (
        <div className='pt-[100px] md:pt-[200px] max-w-screen-xl m-auto flex flex-col md:flex-row mb-[100px] min-h-screen'>
            <div className='w-full md:w-3/12 flex flex-col gap-4 p-4'>
                <div className='flex flex-row gap-4 items-start'>
                    {/* <div className='w-1/2'> */}
                    <img className='min-w-[160px]' src={result[0].img} alt='' />
                    {/* </div> */}
                    <div className='flex flex-col py-5 gap-2'>
                        <h2 className='text-xl font-bold'>{result[0].title}</h2>
                        <span className='text-xs font-normal'>
                            {result[0].desc}
                        </span>
                    </div>
                </div>
                <div className='flex flex-col'>
                    <h3 className='text-xl font-bold'>{newLang?.otherProducts ?? 'Other Products'}</h3>
                    <div className='flex flex-wrap mt-2 gap-2'>
                        {other?.map((item, idx) => (
                            <span
                                className='bg-blue-five rounded-full px-4 py-1 text-white cursor-pointer hover:scale-105 ease-in-out duration-200'
                                key={idx}
                                onClick={() => navigate(item.path)}
                            >
                                {item.name}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
            <div className='w-full md:w-9/12'>
                <Outlet />
            </div>
        </div>
    )
}

export default DigitalProductLayout
