const BadgeContent = ({ status }) => {
    const isStatus = (status) => {
        if (status == 'success') {
            return 'bg-lime-500'
        } else if (status == 'waiting for payment') {
            return 'bg-yellow-500'
        } else if (status == 'failed') {
            return 'bg-red-500'
        } else {
            return 'bg-orange-500'
        }
    }

    return (
        <div
            className={`min-w-[100px] h-6 bg-lime-500 rounded-full flex justify-center items-center ${isStatus(
                status
            )}`}
        >
            <div className='leading-[18px] text-[8px] font-[700] text-[#1E4B23]'>
                {status}
            </div>
        </div>
    )
}
export default BadgeContent
