import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { AuthContext } from 'Context/authContext'
import { fetchTopup, fetchTopupVoucher } from 'Api/profile'
import Toast from 'Utils/toast'
import { useQuery, useMutation, useQueries } from '@tanstack/react-query'
import { fetchPaymentChannel, fetchPaymentMethod } from 'Api/payment'

const schema = yup
    .object({
        nominal: yup.number().required('Nominal is Required'),
        voucher_code: yup.string(),
    })
    .required()

const Topup = () => {
    const navigate = useNavigate()
    const { newLang, token } = useContext(AuthContext)
    const [nominal, setNominal] = useState()
    const [methodId, setMethodId] = useState()
    const [channelId, setChannelId] = useState('')
    const [paymentChannel, setPaymentChannel] = useState()

    useEffect(() => {
        const isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'))
        if (!isLoggedin) {
            Toast('error', 'User not login')
            navigate('/')
        }
    }, [])

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    // Topup
    const { isLoading: isTopupLoading, mutate: mutateTopup } = useMutation(
        fetchTopup,
        {
            onSuccess: (data) => {
                if (data?.message?.response_code == '00') {
                    Toast('success', data?.message?.response_message)
                    navigate('/waiting-payment-topup', {
                        state: {
                            data: paymentChannel,
                            nominal: nominal,
                            id: data.data.id,
                            nominalPayment: data.data,
                        },
                    })
                } else {
                    if (
                        data?.message?.response_code == '10' ||
                        data?.message?.response_code == '70' ||
                        data?.message?.response_code == '71'
                    ) {
                        Toast('error', `${data?.message?.response_message}`)
                    } else {
                        Toast(
                            'error',
                            `${data?.data?.biller_status} - ${data?.data?.biller_data?.message}`
                        )
                    }
                }
            },
        }
    )

    // Topup Voucher
    const { isLoading: isLoadingTopupVoucher, mutate: mutateTopupVoucher } =
        useMutation(fetchTopupVoucher, {
            onSuccess: (data) => {
                if (data?.message?.response_code == '00') {
                    Toast('success', data?.message?.response_message)
                    navigate('/')
                } else {
                    Toast('error', data?.message?.response_message)
                }
            },
        })

    // Payment Method
    const {
        isLoading: isLoadingMethod,
        mutate: mutateMethod,
        data: methodData,
    } = useMutation(fetchPaymentMethod, {
        onSuccess: (data) => {},
    })

    // Payment Channel
    const channelData = useQueries({
        queries:
            methodData?.data?.map((id) => {
                return {
                    queryKey: ['payment-channel', id],
                    queryFn: () => fetchPaymentChannel({ token, id, nominal }),
                }
            }) ?? [],
    })

    const handleChangeRadio = (obj, item) => {
        if (obj == 'voucher') {
            setPaymentChannel('voucher')
        } else {
            // setPaymentChannel(item)
            setMethodId(obj.method_id)
            setChannelId(item.id)
            setPaymentChannel(item)
        }
    }

    const onSubmit = (data) => {
        if(paymentChannel != 'voucher') {
            const payload = {
                point: parseInt(data.nominal),
                payment_method_id: methodId,
                payment_channel: channelId,
            }
            mutateTopup({ token, payload })
        } else {
            const payload = {
                point: parseInt(data.nominal),
                voucher_code: data.voucher_code,
            }
            mutateTopupVoucher({ token, payload })
        }
    }

    const handleKeyPress = async (e) => {
        if (e.key == 'Enter') {
            await setNominal(e.target.value)
            mutateMethod({ token })
        }
    }

    return (
        <div className='pt-[100px] md:pt-[200px] max-w-screen-xl m-auto flex flex-col md:flex-row mb-[100px] min-h-screen md:justify-evenly p-4'>
            <div className='w-full md:w-3/4 mx-auto flex flex-col px-10 gap-4'>
                <div className='phone w-full flex flex-col'>
                    <h2 className='text-xl font-bold'>1. Enter your nominal</h2>
                    <div className='w-full flex items-end gap-2'>
                        <div className='w-full'>
                            <div className='form-control w-full'>
                                <label className='label'>
                                    <span className='label-text'>Nominal</span>
                                </label>
                                <input
                                    type='number'
                                    placeholder='Type here'
                                    className='input input-bordered w-full'
                                    // onChange={(e) => handleChange(e.target.value)}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    pattern='[0-9.]+'
                                    {...register('nominal', { required: true })}
                                />
                                {newLang?.pleaseEnterContinue ??
                                    'Press enter to continue'}
                                {errors.nominal && (
                                    <p className='text-xs text-red-600'>
                                        {errors.nominal.message}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {!isLoadingMethod && !!nominal && (
                    <div className='metode w-full flex flex-col gap-4'>
                        <h2 className='text-xl font-bold'>
                            2. Select Payment method
                        </h2>
                        {channelData?.map((item, idx) => {
                            const obj = {
                                method_id: methodData?.data[idx]?.id,
                                name: methodData?.data[idx]?.payment_method,
                                channels: item?.data?.data,
                            }
                            return (
                                <div
                                    className='payment w-full flex flex-col gap-4'
                                    key={idx}
                                >
                                    <h3 className='text-xs font-bold'>
                                        {obj.name}
                                    </h3>
                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                                        {obj.channels?.map((item, idx) => {
                                            if (item.name.includes('Ali')) {
                                                return (
                                                    <div
                                                        className='form-control border-2 rounded-xl p-2 w-full md:w-auto'
                                                        key={idx}
                                                        onClick={() =>
                                                            Toast(
                                                                'warning',
                                                                'Cannot use Alipay for Topup'
                                                            )
                                                        }
                                                    >
                                                        <label className='label cursor-pointer gap-2 justify-evenly'>
                                                            <div className='w-2/12 text-center'>
                                                                <input
                                                                    type='radio'
                                                                    name='radio-6'
                                                                    className='radio w-5 h-5 checked:bg-blue-four'
                                                                    disabled
                                                                    // onChange={() =>
                                                                    //     Toast(
                                                                    //         'warning',
                                                                    //         'Cannot use Alipay for Topup'
                                                                    //     )
                                                                    // }
                                                                />
                                                            </div>
                                                            <div className='w-10/12'>
                                                                <div className='flex gap-2'>
                                                                    <div className='flex flex-row gap-2 items-center'>
                                                                        <img
                                                                            className='w-10'
                                                                            src={
                                                                                item
                                                                                    ?.payment_gateway
                                                                                    ?.manual_transfer
                                                                                    ?.bank_logo_url
                                                                            }
                                                                            alt=''
                                                                        />
                                                                        <span className='label-text text-base font-normal'>
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div
                                                        className='form-control border-2 rounded-xl p-2 w-full md:w-auto'
                                                        key={idx}
                                                    >
                                                        <label className='label cursor-pointer gap-2 justify-evenly'>
                                                            <div className='w-2/12 text-center'>
                                                                <input
                                                                    type='radio'
                                                                    name='radio-6'
                                                                    className='radio w-5 h-5 checked:bg-blue-four'
                                                                    onChange={() =>
                                                                        handleChangeRadio(
                                                                            obj,
                                                                            item
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div className='w-10/12'>
                                                                <div className='flex gap-2'>
                                                                    <div className='flex flex-row gap-2 items-center'>
                                                                        <img
                                                                            className='w-10'
                                                                            src={
                                                                                item
                                                                                    ?.payment_gateway
                                                                                    ?.manual_transfer
                                                                                    ?.bank_logo_url
                                                                            }
                                                                            alt=''
                                                                        />
                                                                        <span className='label-text text-base font-normal'>
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                    <h3 className='text-xs font-bold'>
                                        {newLang.voucher ?? 'Voucher'}
                                    </h3>
                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                                        <div className='form-control border-2 rounded-xl p-2 w-full md:w-auto'>
                                            <label className='label cursor-pointer gap-2 justify-evenly'>
                                                <div className='w-2/12 text-center'>
                                                    <input
                                                        type='radio'
                                                        name='radio-6'
                                                        className='radio w-5 h-5 checked:bg-blue-four'
                                                        onChange={() =>
                                                            handleChangeRadio(
                                                                'voucher',
                                                                {
                                                                    method_id:
                                                                        'voucher',
                                                                }
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className='w-10/12'>
                                                    <div className='flex gap-2'>
                                                        <div className='flex flex-row gap-2 items-center'>
                                                            <span className='label-text text-base font-normal'>
                                                                Voucher
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={`${paymentChannel == 'voucher' ? 'flex' : 'hidden'}`}>
                                        <div className='w-3/4'>
                                            <input
                                                placeholder='Type Voucher here'
                                                className='input input-bordered w-full'
                                                {...register('voucher_code')}
                                            />
                                        </div>
                                        <div className='w-1/4'>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
                <div className='mt-5'>
                    <button
                        className='btn bg-blue-five text-white rounded-full min-w-[200px]'
                        onClick={handleSubmit(onSubmit)}
                        disabled={!nominal}
                    >
                        TopUp Now
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Topup
