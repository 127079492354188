import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    imeis: [],
}

export const imei = createSlice({
    name: 'imei',
    initialState,
    reducers: {
        addImei: (state, action) => {
            state.imeis = [action.payload, ...state.imeis]
        },
        deleteImei: (state, action) => {
            state.imeis = state.imeis.filter(
                (item) => action.payload != item.id
            )
        },
    },
})

export const { addImei, deleteImei } = imei.actions

export default imei.reducer
