import React, { useContext } from 'react'
import { fetchPrivacy } from 'Api/legal'
import { AuthContext } from 'Context/authContext'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

const PrivacyPolicy = () => {
    const { token } = useContext(AuthContext)
    const navigate = useNavigate()

    const { data: ppData, isLoading: isLoadingPp } = useQuery(
        ['privacy-policy'],
        () => fetchPrivacy({ token }),
        {
            onSuccess: (data) => {
            },
        }
    )

    return (
        <div className='pt-[100px] md:pt-[200px] max-w-screen-xl m-auto p-5 md:p-0'>
            {!isLoadingPp && (
                <div
                    className=''
                    dangerouslySetInnerHTML={{
                        __html: ppData?.data?.content,
                    }}
                />
            )}
        </div>
    )
}

export default PrivacyPolicy
