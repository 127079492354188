import { configureStore } from '@reduxjs/toolkit'
import authenticated from './authenticated'
import family from './family'
import animal from './goods/animal'
import narcotic from './goods/narcotics'
import currency from './goods/currency'
import foreign from './goods/foreign'
import cigar from './goods/cigar'
import good from './goods/good'
import imports from './goods/import'
import carrying from './goods/carrying'
import imei from './imei'
import additional from './additional'
import passenger from './passenger'
import transactionFilter from './profile/transaction/transactionFilter'
import transactionPaging from './profile/transaction/transactionPaging'
import transactionPointFilter from './profile/transactionPoint/transactionFilter'
import transactionPointPaging from './profile/transactionPoint/transactionPaging'
import transactionImeiFilter from './profile/transactionImei/transactionFilter'
import transactionImeiPaging from './profile/transactionImei/transactionPaging'

export const store = configureStore({
    reducer: {
        authenticated,
        family,
        animal,
        narcotic,
        currency,
        foreign,
        cigar,
        good,
        imports,
        carrying,
        imei,
        additional,
        passenger,
        transactionFilter,
        transactionPaging,
        transactionPointFilter,
        transactionPointPaging,
        transactionImeiFilter,
        transactionImeiPaging
    },
})
