import React, { useState, useContext, useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Eye from 'Assets/images/eye.svg'
import EyeClosed from 'Assets/images/eye-close-line.svg'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { setResetPassword } from 'Api/auth'
import { AuthContext } from 'Context/authContext'
import Toast from 'Utils/toast'

const schema = yup
.object({
    password: yup.string().required('Password is required').min(8, 'must be at least 8 characters long'),
    passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})
.required()

const NewPassword = () => {
    const { token } = useContext(AuthContext)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    let tokenForgot = searchParams.get('token')

    useEffect(() => {
        !tokenForgot && navigate('/login') 
    }, [])

    const [type1, setType1] = useState('password')
    const [type2, setType2] = useState('password')

    const handlePassEye1 = () => type1 == 'password' ? setType1('text') : setType1('password') 
    const handlePassEye2 = () => type2 == 'password' ? setType2('text') : setType2('password')

    const {
            register,
        handleSubmit,
        // setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })


    const { mutate: mutateResetPassword } = useMutation(setResetPassword, {
        onSuccess: (data) => {
            if (data?.message?.response_code == '00') {
                Toast('success', data?.message?.response_message)
                navigate('/login')
            } else {
                Toast('warning', data?.message?.response_message)
            }
        },
    })

    const onSubmit = (data) => {
        
        const payload = {
            new_password: data.password,
            confirm_new_password : data.passwordConfirm
        }

        mutateResetPassword({ token, payload, tokenForgot })
    }

    return (
        <>
            <div className='left-form flex flex-col justify-around p-6 md:p-14 w-full md:w-2/5'>
                {/* <Link to='/' className='flex'>
                    <img className='w-2' src={ChevronLeft} alt='' />
                    <span className='text-black font-bold ml-4'>Home</span>
                </Link> */}
                <div className='welcome-text'>
                    <h2 className='text-3xl font-bold text-black'>Make New</h2>
                    <h2 className='text-3xl font-bold text-black'>Password</h2>
                </div>
                <div className='form'>
                    <div className='form-control w-full'>
                        <label className='label'>
                            <span className='label-text text-black font-semibold'>
                                Password
                            </span>
                        </label>
                        <div className='relative'>
                            <input
                                type={type1}
                                placeholder='Type here'
                                className='input input-bordered w-full bg-transparent text-black rounded-lg'
                                {...register('password', { required: true })}
                            />
                            <img
                                className='w-5 absolute top-4 right-5 cursor-pointer'
                                src={type1 == 'password' ? EyeClosed : Eye}
                                alt=''
                                onClick={() => handlePassEye1()}
                            />
                            {errors.password && (
                                <p className='text-xs text-red-600'>
                                    {errors.password.message}
                                </p>
                            )}
                        </div>
                        <label className='label'>
                            <span className='label-text text-black font-semibold'>
                                Confirm Password
                            </span>
                        </label>
                        <div className='relative'>
                            <input
                                type={type2}
                                placeholder='Type here'
                                className='input input-bordered w-full bg-transparent text-black rounded-lg'
                                {...register('passwordConfirm', { required: true })}
                            />
                            <img
                                className='w-5 absolute top-4 right-5 cursor-pointer'
                                src={type2 == 'password' ? EyeClosed : Eye}
                                alt=''
                                onClick={() => handlePassEye2()}
                            />
                            {errors.passwordConfirm && (
                                <p className='text-xs text-red-600'>
                                    {errors.passwordConfirm.message}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className='flex-col'>
                    <button className='btn bg-[#213267] text-white text-xl rounded-full w-full px-1 py-2 min-h-[1rem] normal-case mt-8' onClick={handleSubmit(onSubmit)}>
                        Save
                    </button>
                </div>
            </div>
        </>
    )
}

export default NewPassword
