import './App.css'
import { BrowserRouter } from 'react-router-dom'
import MainRoutes from 'Routes'
// import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { AuthProvider } from 'Context/authContext'
import { store } from 'Store'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ChakraProvider } from '@chakra-ui/react'

const queryClient = new QueryClient()

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter>
                    <ChakraProvider>
                        <AuthProvider>
                            <ToastContainer />
                            <MainRoutes />
                        </AuthProvider>
                        <ReactQueryDevtools />
                    </ChakraProvider>
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    )
}

export default App
