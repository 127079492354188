import { api } from "Utils/axios"

//List Advertise
export const fetchTransaction = ({ token, query, options }) => {
    return api
        .post(
            'transaction/ppob/history',
            {
                filter: {
                    // set_title: false,
                    // title: '',
                    // set_category: false,
                    // category: 1,
                    // set_status: false,
                    // status: 1,
                    // set_deleted: true,
                    ...query,
                },
                // limit: limit,
                // page: page,
                // order: 'created_at',
                // sort: 'ASC',
                ...options,
            },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}
