import React, { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { AuthContext } from 'Context/authContext'

const Notification = () => {
    const { newLang } = useContext(AuthContext)
    return (
        <div
            className={`flex flex-col ${
                isMobile ? 'w-full' : 'w-3/4'
            } gap-5 p-2`}
        >
            {!isMobile && (
                <h1 className='text-black text-xl font-semibold'>
                    {newLang?.notification}
                </h1>
            )}
            <div className='flex w-full md:w-2/3 gap-2'>
                <InputText
                    className='w-2/3'
                    placeholder={`${newLang?.search ?? 'Search'}`}
                    // value={value1}
                    // onChange={(e) => setValue1(e.target.value)}
                />
                <Dropdown
                    // value={selectedCity1}
                    // options={cities}
                    // onChange={onCityChange}
                    // optionLabel='name'
                    placeholder='Semua'
                />
            </div>
            <div className='flex flex-col w-full md:w-2/3 divide-y-2 divide-dashed'>
                {/* list notif */}
                <div className='flex flex-col gap-2 p-2'>
                    {/* info */}
                    <div className='flex items-center gap-2'>
                        <div className='w-2 h-2 bg-blue-five rounded-full'></div>
                        <span className='text-black text-ss'>Info</span>
                    </div>
                    {/* title */}
                    <span className='text-black text-sm font-bold'>
                        Habis lebaran, bisa makin cuan
                    </span>
                    {/* desc */}
                    <span className='text-black text-sm whitespace-nowrap truncate'>
                        Bener dong, makanya yuk nikmati Diskon 10% hingga
                        Rp750ribu dengan Kredivo. Belanja jadi makin puas!
                    </span>
                </div>
                <div className='flex flex-col gap-2 p-2'>
                    {/* info */}
                    <div className='flex items-center gap-2'>
                        <div className='w-2 h-2 bg-blue-five rounded-full'></div>
                        <span className='text-black text-ss'>Info</span>
                    </div>
                    {/* title */}
                    <span className='text-black text-sm font-bold'>
                        Habis lebaran, bisa makin cuan
                    </span>
                    {/* desc */}
                    <span className='text-black text-sm whitespace-nowrap truncate'>
                        Bener dong, makanya yuk nikmati Diskon 10% hingga
                        Rp750ribu dengan Kredivo. Belanja jadi makin puas!
                    </span>
                </div>
                <div className='flex flex-col gap-2 p-2'>
                    {/* info */}
                    <div className='flex items-center gap-2'>
                        <div className='w-2 h-2 bg-blue-five rounded-full'></div>
                        <span className='text-black text-ss'>Info</span>
                    </div>
                    {/* title */}
                    <span className='text-black text-sm font-bold'>
                        Habis lebaran, bisa makin cuan
                    </span>
                    {/* desc */}
                    <span className='text-black text-sm whitespace-nowrap truncate'>
                        Bener dong, makanya yuk nikmati Diskon 10% hingga
                        Rp750ribu dengan Kredivo. Belanja jadi makin puas!
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Notification
