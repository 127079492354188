import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    is_include: false,
    narcotics: [],
}

export const narcotics = createSlice({
    name: 'narcotic',
    initialState,
    reducers: {
        addNarcotic: (state, action) => {
            state.narcotics = [action.payload, ...state.narcotics]
            state.is_include = true
        },
        deleteNarcotic: (state, action) => {
            state.narcotics = state.narcotics.filter(
                (narcotic) => action.payload != narcotic.id
            )
            if (state.narcotics.length == 0) state.is_include = false
        },
    },
})

export const { addNarcotic, deleteNarcotic } = narcotics.actions

export default narcotics.reducer
