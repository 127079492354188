import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/lazy'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

// import required modules
import { FreeMode, Navigation, Thumbs, Lazy } from 'swiper'
import Modal from 'Components/Modals'
import ModalPicture from './Modals/modalPicture'

const ThumbnailGallery = ({ data = [], title }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null)
    const [thumbsSwiper2, setThumbsSwiper2] = useState(null)
    const [images, setImages] = useState([])
    const [modal, setModal] = useState({ open: false })

    useEffect(() => {
        data && setImages(data)
    }, [])

    return (
        <>
            {/* // <div className='max-w-[360px] max-h-[475px] md:max-w-[504px] md:max-h-[475px]'> */}
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }}
                spaceBetween={10}
                navigation={false}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs, Lazy]}
                className='aspect-[5/3] rounded-xl m-auto cursor-pointer swiper'
                onClick={() => setModal({ open: !modal.open })}
            >
                {images?.map((item, idx) => (
                    <SwiperSlide key={idx}>
                        <img className='w-full h-full' src={`${item}`} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={'auto'}
                // freeMode={true}
                // watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs, Lazy]}
                className='max-h-[120px] mt-2'
            >
                {images?.map((item, idx) => (
                    <SwiperSlide
                        className='max-w-[100px] cursor-pointer'
                        key={idx}
                    >
                        <img
                            className='aspect-[4/3] rounded-xl w-full hover:scale-90 ease-in-out duration-300'
                            src={`${item}`}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            {/* // </div> */}
            {modal.open && (
                <ModalPicture
                    data={data}
                    title={title}
                    visible={modal.open}
                    cancel={() => setModal({ open: false })}
                />
            )}
        </>
    )
}

export default ThumbnailGallery

{
    /* <SwiperSlide className='max-w-[100px]'>
                    <img
                        className='rounded-xl h-20 w-full'
                        src='https://swiperjs.com/demos/images/nature-1.jpg'
                    />
                </SwiperSlide>
                <SwiperSlide className='max-w-[100px]'>
                    <img
                        className='rounded-xl h-20 w-full'
                        src='https://swiperjs.com/demos/images/nature-2.jpg'
                    />
                </SwiperSlide>
                <SwiperSlide className='max-w-[100px]'>
                    <img
                        className='rounded-xl h-20 w-full'
                        src='https://swiperjs.com/demos/images/nature-3.jpg'
                    />
                </SwiperSlide>
                <SwiperSlide className='max-w-[100px]'>
                    <img
                        className='rounded-xl h-20 w-full'
                        src='https://swiperjs.com/demos/images/nature-4.jpg'
                    />
                </SwiperSlide>
                <SwiperSlide className='max-w-[100px]'>
                    <img
                        className='rounded-xl h-20 w-full'
                        src='https://swiperjs.com/demos/images/nature-5.jpg'
                    />
                </SwiperSlide>
                <SwiperSlide className='max-w-[100px]'>
                    <img
                        className='rounded-xl h-20 w-full'
                        src='https://swiperjs.com/demos/images/nature-6.jpg'
                    />
                </SwiperSlide>
                <SwiperSlide className='max-w-[100px]'>
                    <img
                        className='rounded-xl h-20 w-full'
                        src='https://swiperjs.com/demos/images/nature-7.jpg'
                    />
                </SwiperSlide>
                <SwiperSlide className='max-w-[100px]'>
                    <img
                        className='rounded-xl h-20 w-full'
                        src='https://swiperjs.com/demos/images/nature-8.jpg'
                    />
                </SwiperSlide>
                <SwiperSlide className='max-w-[100px]'>
                    <img
                        className='rounded-xl h-20 w-full'
                        src='https://swiperjs.com/demos/images/nature-9.jpg'
                    />
                </SwiperSlide>
                <SwiperSlide className='max-w-[100px]'>
                    <img
                        className='rounded-xl h-20 w-full'
                        src='https://swiperjs.com/demos/images/nature-10.jpg'
                    />
                </SwiperSlide> */
}
