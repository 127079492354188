import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ChevronLeft from 'Assets/images/chevron-left.svg'
import eye from 'Assets/images/eye.svg'
import eyeClose from 'Assets/images/eye-close-line.svg'
import Modal from 'Components/Modals'
import { InputText } from 'primereact/inputtext'
// import { Password } from 'primereact/password'
import CheckForgotSvg from 'Assets/images/checkForgot.svg'
import { useQuery, useMutation } from '@tanstack/react-query'
import { setLogin, setForgotPassword } from 'Api/auth'
import { AuthContext } from 'Context/authContext'
import { useSelector, useDispatch } from 'react-redux'
import authenticated, { setIsAuthenticated } from 'Store/authenticated'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Toast from 'Utils/toast'
import {
    Button,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react'

const schema = yup
    .object({
        email: yup.string().required('Email is Required'),
        password: yup.string().required('Password is Required'),
    })
    .required()

const schema2 = yup
    .object({
        emailForgot: yup
            .string()
            .email('Please use email format')
            .required('Email is Required'),
    })
    .required()

const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const isLoggedin = useSelector((state) => state.authenticated.isLogin)
    const isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'))
    const { token, newLang } = useContext(AuthContext)

    useEffect(() => {
        isLoggedin && navigate('/')
    }, [isLoggedin])

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const {
        register: register2,
        handleSubmit: handleSubmitForgot,
        formState: { errors: errors2 },
    } = useForm({
        resolver: yupResolver(schema2),
    })

    const [modal, setModal] = useState(false)
    const Toggle = () => setModal(!modal)
    const [modalSatu, setModalSatu] = useState({ open: false })
    const [modalDua, setModalDua] = useState({ open: false })
    const [showPass, setShowPass] = useState(false)

    const { isLoading: isLoginLoading, mutate: mutateLogin } = useMutation(
        setLogin,
        {
            onSuccess: (data) => {
                if (data?.message?.response_code == '00') {
                    dispatch(setIsAuthenticated())
                    localStorage.setItem('isLoggedin', true)
                    navigate('/')
                } else {
                    Toast('warning', data?.message?.response_message)
                }
            },
        }
    )

    const {
        isLoading,
        mutate: mutateForgotPassword,
        isLoading: isLoadingMutateForgotPassword,
    } = useMutation(setForgotPassword, {
        onSuccess: (data) => {
            if (data?.message?.response_code == '00') {
                Toast('success', data?.message?.response_message)
                setModalSatu({ open: false })
                setModalDua({ open: true })
            } else {
                Toast('warning', data?.message?.response_message)
            }
        },
    })

    const onSubmit = (data) => {
        const payload = {
            email: data.email,
            password: data.password,
            // email: 'risyamaulana25@gmail.com',
            // password: '123qweasd',
        }
        mutateLogin({ payload, token })
    }

    const onSubmitForgot = (data) => {
        const payload = {
            email: data.emailForgot,
        }

        mutateForgotPassword({ token, payload })
    }

    return (
        <>
            <div className='left-form flex flex-col justify-between p-6 md:p-14 w-full md:w-2/5'>
                <Link to='/' className='flex'>
                    <img className='w-2' src={ChevronLeft} alt='' />
                    <span className='text-black font-bold ml-4'>{newLang?.home}</span>
                </Link>
                <div className='welcome-text'>
                    <h2 className='text-3xl font-bold text-black'>
                        {newLang?.helloBuddies}
                    </h2>
                    <h2 className='text-3xl font-bold text-black'>
                        {newLang?.welcome}
                    </h2>
                </div>
                <div className='form'>
                    <div className='form-control w-full'>
                        <label className='label'>
                            <span className='label-text text-black font-semibold'>
                                {newLang?.emailPhoneUsername}
                            </span>
                        </label>
                        {/* <InputText
                            name='name'
                            className='rounded-xl'
                            placeholder='Andre.andrean@gmail.com'
                            autoFocus
                            // onChange={(e) => setValue1(e.target.value)}
                            {...register('email', { required: true })}
                        /> */}
                        <Input
                            name='name'
                            className='rounded-xl'
                            placeholder='Andre.andrean@gmail.com'
                            autoFocus
                            // onChange={(e) => setValue1(e.target.value)}
                            {...register('email', { required: true })}
                        />
                        {errors.email && (
                            <p className='text-xs text-red-600'>
                                {errors.email.message}
                            </p>
                        )}
                        <label className='label'>
                            <span className='label-text text-black font-semibold'>
                                {newLang?.password}
                            </span>
                        </label>
                        <InputGroup>
                            <Input
                                className='!text-blue-five !text-base !font-semibold'
                                type={showPass ? 'text' : 'password'}
                                {...register('password', { required: true })}
                            />
                            <InputRightElement>
                                <img
                                    className='w-5 cursor-pointer'
                                    src={showPass ? eye : eyeClose}
                                    alt=''
                                    onClick={() => {
                                        setShowPass(!showPass)
                                    }}
                                />
                            </InputRightElement>
                        </InputGroup>
                        {errors.password && (
                            <p className='text-xs text-red-600'>
                                {errors.password.message}
                            </p>
                        )}
                    </div>
                    <div className='flex justify-between mt-4 text-black-five'>
                        <span
                            className='font-xs text-semibold cursor-pointer'
                            onClick={() => setModalSatu({ open: true })}
                        >
                            {newLang?.forgotPasswordAsk}
                        </span>
                    </div>
                </div>
                <div className='flex-col'>
                    <button
                        className='btn bg-[#213267] text-white text-xl rounded-full w-full px-1 py-2 min-h-[1rem] normal-case mt-8'
                        // onClick={() => handleLogin()}
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoginLoading}
                    >
                        {newLang?.login}
                    </button>
                    <div className='flex justify-center items-center mt-2'>
                        <span className='text-xs text-black-five'>
                        {newLang?.notHaveAccountYet}
                        </span>
                        <Link to='/register'>
                            <span className='ml-2 font-semibold text-[#213267] text-xs'>
                                {newLang?.registerNow}
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
            {modalSatu.open && (
                <Modal
                    visible={modalSatu.open}
                    cancel={() => setModalSatu({ open: false })}
                    title={'Request forgot password'}
                >
                    <h5 className='mb-3 text-base font-semibold'>
                        Please enter your email address
                    </h5>
                    <InputText
                        className='w-full rounded-xl'
                        placeholder='email'
                        {...register2('emailForgot', { required: true })}
                    />
                    {errors2.emailForgot && (
                        <p className='text-xs text-red-600'>
                            {errors2.emailForgot.message}
                        </p>
                    )}
                    <button
                        className='btn rounded-full bg-blue-one text-white w-full mt-5'
                        onClick={handleSubmitForgot(onSubmitForgot)}
                        disabled={isLoadingMutateForgotPassword}
                    >
                        Send
                    </button>
                </Modal>
            )}
            {modalDua.open && (
                <Modal
                    visible={modalDua.open}
                    cancel={() => setModalDua({ open: false })}
                    title={''}
                >
                    <div className='text-center flex flex-col items-center'>
                        <img className='mb-5' src={CheckForgotSvg} alt='' />
                        <span className='text-ss font-normal'>
                            Password change request has been sent, Please check
                            your inbox in your email.
                        </span>
                        <button
                            className='btn rounded-full bg-blue-one text-white w-full mt-5'
                            onClick={() => setModalDua({ open: false })}
                        >
                            Ok
                        </button>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default Login
