import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    filter: {
        set_status: false,
        status: 'success',
    },
}

// Success
// Failed
// Reject
// Waiting for payment
// Waiting for approvement 
// Pending

export const transactionImeiFilter = createSlice({
    name: 'transactionImeiFilter',
    initialState,
    reducers: {
        changeImeiFilter: (state, action) => {
            let isStatus = true
            let status = 'semua'
            switch (action.payload.status) {
                case 'all':
                    isStatus = false
                    status = 'semua'
                    break;
                case 'success':
                    isStatus = true
                    status = 'succses'
                    break;
                case 'waiting payment':
                    isStatus = true
                    status = 'waiting for payment'
                    break;
                case 'cancelled':
                    isStatus = true
                    status = 'reject'
                    break;
            }
            state.filter = {
                ...state.filter,
                set_status: isStatus,
                status: status,
            }
        },
        changeImeiCategory: (state, action) => {
            state.filter = {
                ...state.filter,
                category: action.payload.category,
            }
        },
    },
})

export const { changeImeiCategory, changeImeiFilter } = transactionImeiFilter.actions

export default transactionImeiFilter.reducer
