import React, { useContext } from 'react'
import { AuthContext } from 'Context/authContext'
import { Link, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { fetchTags } from 'Api/advertise'
import { Skeleton } from 'primereact/skeleton'

const CategoryHome = () => {
    const { token } = useContext(AuthContext)

    const { data: tagsData, isLoading: isLoadingTagsData } = useQuery(
        ['tags', token],
        () => fetchTags({ token }),
        {
            onSuccess: (data) => {
            },
        }
    )

    return (
        <div className='flex my-4 overflow-auto space-x-2 pb-4'>
            {!isLoadingTagsData ? (
                tagsData.data?.map((item, idx) => (
                    <Link
                        to={`advertise-list?tag=${item.tags}`}
                    >
                        <div
                            className={`py-1 px-6 rounded-[10px] bg-[#ff550f] cursor-pointer hover:scale-95 ease-in-out duration-300`}
                            key={idx}
                        >
                            <span className='text-xs md:text-sm text-white font-semibold whitespace-nowrap'>
                                {item.tags}
                            </span>
                        </div>
                    </Link>
                ))
            ) : (
                <div className='flex my-4 space-x-2 pb-4'>
                    <Skeleton width='70px' />
                    <Skeleton width='70px' />
                    <Skeleton width='70px' />
                    <Skeleton width='70px' />
                    <Skeleton width='70px' />
                </div>
            )}
        </div>
    )
}

export default CategoryHome
