import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

const data = [
    {
        name: 'Kategori 1',
        color: '#1399AA',
    },
    {
        name: 'Kategori 1',
        color: '#065535',
    },
    {
        name: 'Kategori 1',
        color: '#ffd700',
    },
    {
        name: 'Kategori 1',
        color: '#ff7373',
    },
]

const expAds = [
    {
        category: 'baju',
        img: 'https://api.lorem.space/image/shoes?w=400&h=225',
        title: 'baju satu',
        desc: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio id commodi aspernatur suscipit, velit debitis consequatur quod consectetur minus quibusdam unde vero soluta autem eaque.',
    },
    {
        category: 'baju',
        img: 'https://api.lorem.space/image/shoes?w=400&h=225',
        title: 'baju dua',
        desc: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio id commodi aspernatur suscipit, velit debitis consequatur quod consectetur minus quibusdam unde vero soluta autem eaque.',
    },
    {
        category: 'topi',
        img: 'https://api.lorem.space/image/shoes?w=400&h=225',
        title: 'topi satu',
        desc: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio id commodi aspernatur suscipit, velit debitis consequatur quod consectetur minus quibusdam unde vero soluta autem eaque.',
    },
]

const SearchPage = () => {
    const navigate = useNavigate()

    useEffect(() => {
      !isMobile && navigate('/')
    }, [])
    
    return (
        <div className='flex items-center pt-[100px] md:pt-[200px] max-w-screen-xl m-auto space-y-4 p-5 md:p-0'>
            <div className='w-full bg-white'>
                <div className='flex flex-col'>
                    {/* kategori Rekomendasi */}
                    <div className='flex flex-col gap-2'>
                        <span className='text-lg font-bold'>
                            Recomended Category
                        </span>
                        <div className='flex flex-wrap my-4 gap-2'>
                            {data.map((item, idx) => (
                                <div
                                    className={`py-1 px-6 rounded-[10px]`}
                                    style={{
                                        background: `${item.color}`,
                                    }}
                                    key={idx}
                                >
                                    <span className='text-xs md:text-sm text-white font-normal whitespace-nowrap'>
                                        {item.name}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Iklan yang banyak di cari */}
                    <div className='flex flex-col gap-2'>
                        <span className='text-lg font-bold'>
                            Most Viewed Ad
                        </span>
                        <div className='flex gap-2 overflow-auto pb-3'>
                            {expAds.map((item) => (
                                <div className='card card-compact min-w-[224px] h-[200px] bg-base-100 shadow-md text-black'>
                                    <figure>
                                        <img src={item.img} alt='' />
                                    </figure>
                                    <div className='card-body'>
                                        <h2 className='card-title text-sm font-bold'>
                                            {item.title}
                                        </h2>
                                        <p className='text-ss line-clamp-3 '>
                                            {item.desc}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchPage
