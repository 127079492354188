import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ChevronLeft from 'Assets/images/chevron-left.svg'
import EyeClosed from 'Assets/images/eye-close-line.svg'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import Toast from 'Utils/toast'
import { useContext } from 'react'
import { AuthContext } from 'Context/authContext'
import { InputText } from 'primereact/inputtext'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { setRegister } from 'Api/auth'
import { useQuery, useMutation } from '@tanstack/react-query'
import { InputTextarea } from 'primereact/inputtextarea'
import { useSelector } from 'react-redux'
import { InputNumber } from 'primereact/inputnumber'

const schema = yup
    .object({
        username: yup.string().matches(/^\S*$/, 'Whitespace is not allowed').required('username is required'),
        password: yup.string().min(8, 'Minimum 8').required('Password is required'),
        email: yup.string().email().required('Email is required'),
        passwordConfirm: yup
            .string()
            .min(8, 'Minimum 8')
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
        referralCode: yup.string(),
        wechat_id: yup.string(),
    })
    .required()

const Register = () => {
    const { token, newLang } = useContext(AuthContext)
    const navigate = useNavigate()
    // const isLoggedin = useSelector((state) => state.authenticated.isLogin)
    const isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'))
    useEffect(() => {
        isLoggedin && navigate('/')
    }, [isLoggedin])

    const {
        register,
        handleSubmit,
        watch,
        // setValue,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schema),
    })

    const [phone, setPhone] = useState('')

    const { isLoading, mutate: mutateRegister } = useMutation(setRegister, {
        onSuccess: (data) => {
            if (data?.message?.response_code == '00') {
                // Toast('success', data?.message?.response_message)
                navigate('/login')
            } else {
                Toast('warning', data?.message?.response_message)
            }
        },
    })

    const onSubmit = (data) => {
        if (phone) {
            const payload = {
                username: data.username,
                first_name: data.firstname,
                last_name: data.lastname,
                phone: phone,
                email: data.email,
                address: data.address,
                wechat_id: data.wechat_id || '',
                password: data.password,
                confirm_password: data.passwordConfirm,
                referral_code: data.referralCode,
                profile_picture_image_url: '',
                region: '',
            }
            mutateRegister({ payload, token })
        } else {
            Toast('warning', 'Phone cannot be empty')
        }
    }

    return (
        <div className='left-form flex flex-col justify-between p-6 md:p-14 w-full md:w-2/5 overflow-auto'>
            <Link to='/login' className='flex'>
                <img className='w-2' src={ChevronLeft} alt='' />
                <span className='text-black font-bold ml-4'>{newLang?.login ?? 'Login'}</span>
            </Link>
            <div className='welcome-text'>
                <h2 className='text-3xl font-bold text-black my-4'>
                    {newLang?.registerInNihao ?? 'Register in Nihao'}
                </h2>
            </div>
            <div className='form'>
                <div className='form-control w-full'>
                    <label className='label label-text text-black font-semibold'>
                        {newLang?.username}
                    </label>
                    <InputText
                        name='username'
                        className={`rounded-xl w-full`}
                        placeholder={newLang?.username ?? 'username'}
                        autoFocus
                        keyfilter={/^\S*$/}
                        // onChange={(e) => setValue1(e.target.value)}
                        {...register('username')}
                    />
                    {errors.username && (
                        <p className='text-xs text-red-600'>
                            {errors.username.message}
                        </p>
                    )}
                    <div className='flex justify-between gap-2'>
                        <div className='flex-col w-6/12'>
                            <label className='label label-text text-black font-semibold'>
                                {newLang?.firstName}
                            </label>
                            <InputText
                                name='name'
                                className='rounded-xl w-full'
                                placeholder={newLang?.firstName ?? 'firstname'}
                                // onChange={(e) => setValue1(e.target.value)}
                                {...register('firstname', { required: true })}
                            />
                            {errors.firstname && (
                                <p className='text-xs text-red-600'>
                                    {errors.firstname.message}
                                </p>
                            )}
                        </div>
                        <div className='flex-col w-6/12'>
                            <label className='label label-text text-black font-semibold'>
                                {newLang?.lastName}
                            </label>
                            <InputText
                                name='lastname'
                                className='rounded-xl w-full'
                                placeholder={newLang?.lastName ?? 'lastname'}
                                // onChange={(e) => setValue1(e.target.value)}
                                {...register('lastname', { required: true })}
                            />
                            {errors.lastname && (
                                <p className='text-xs text-red-600'>
                                    {errors.lastname.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className='flex justify-between'>
                        <div className='flex-col w-1/2'>
                            <label className='label'>
                                <span className='label-text text-black font-semibold'>
                                    {newLang?.phoneNumber}
                                </span>
                            </label>
                            <label className='input-group'>
                                <PhoneInput
                                    className='text-black border-2 border-b-gray-300 p-[10px] w-full mr-2'
                                    placeholder={newLang?.phoneNumber ?? 'Phone Number'}
                                    defaultCountry='ID'
                                    style={{ borderRadius: '0' }}
                                    value={phone}
                                    onChange={setPhone}
                                />
                            </label>
                        </div>
                        <div className='flex-col w-1/2'>
                            <label className='label label-text text-black font-semibold'>
                                {newLang?.email}
                            </label>
                            <InputText
                                type='email'
                                className='w-full'
                                placeholder={newLang?.email ?? 'Email'}
                                style={{ borderRadius: '5px' }}
                                // onChange={(e) => setValue1(e.target.value)}
                                {...register('email', { required: true })}
                            />
                            {errors.email && (
                                <p className='text-xs text-red-600'>
                                    {errors.email.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <label className='label label-text text-black font-semibold'>
                        {newLang?.address}
                    </label>
                    <InputTextarea {...register('address')} placeholder={newLang?.address ?? 'Address'} rows={3} />
                    {/* <Controller
                        control={control}
                        name='wechat_id'
                        render={({ field }) => (
                            <>
                                <label className='label label-text text-black font-semibold'>
                                    {newLang?.wechat ?? 'Wechat Number'}
                                </label>
                                <InputNumber
                                    id={field.name}
                                    inputRef={field.ref}
                                    value={field.value}
                                    onBlur={field.onBlur}
                                    onValueChange={(e) => field.onChange(e)}
                                    useGrouping={false}
                                />
                            </>
                        )}
                    /> */}
                    <label className='label label-text text-black font-semibold'>
                        {newLang?.weChat ?? 'Wechat Number'}
                    </label>
                    <InputText
                        type='text'
                        className='w-full'
                        placeholder={''}
                        style={{ borderRadius: '5px' }}
                        // onChange={(e) => setValue1(e.target.value)}
                        {...register('wechat_id', { required: true })}
                    />
                    <label className='label label-text text-black font-semibold'>
                        {newLang?.password}
                    </label>
                    <div className='input-group relative'>
                        <InputText
                            type='password'
                            className='w-full'
                            placeholder={newLang?.password ?? 'password'}
                            style={{ borderRadius: '5px' }}
                            // onChange={(e) => setValue1(e.target.value)}
                            {...register('password', { required: true })}
                        />
                    </div>
                    {errors.password && (
                        <p className='text-xs text-red-600'>
                            {errors.password.message}
                        </p>
                    )}
                    <label className='label label-text text-black font-semibold'>
                        {newLang?.confirmPassword}
                    </label>
                    <div className='input-group relative'>
                        <InputText
                            type='password'
                            className='w-full'
                            placeholder={newLang?.confirmPassword ?? 'confirm password'}
                            style={{ borderRadius: '5px' }}
                            // onChange={(e) => setValue1(e.target.value)}
                            {...register('passwordConfirm', { required: true })}
                        />
                    </div>
                    {errors.passwordConfirm && (
                        <p className='text-xs text-red-600'>
                            {errors.passwordConfirm.message}
                        </p>
                    )}
                    <label className='label label-text text-black font-semibold'>
                        {newLang?.refferalCode}
                    </label>
                    <InputText
                        type='text'
                        className='w-full'
                        placeholder='ADF12144123'
                        style={{ borderRadius: '5px' }}
                        // onChange={(e) => setValue1(e.target.value)}
                        {...register('referralCode')}
                    />
                    <span className='label-text'>
                        {newLang?.ifDontHaveReferral}
                    </span>
                </div>
            </div>
            <div className='flex-col'>
                <button
                    className='btn bg-[#213267] text-white text-xl rounded-full w-full px-1 py-2 min-h-[1rem] normal-case mt-8'
                    onClick={handleSubmit(onSubmit)}
                >
                    {newLang?.register}
                </button>
            </div>
        </div>
    )
}

export default Register
