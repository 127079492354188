import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Modal from 'Components/Modals'
import ArrowRightSvg from 'Assets/images/arrow-right.svg'
import { InputText } from 'primereact/inputtext'
import { isMobile } from 'react-device-detect'
import { AuthContext } from 'Context/authContext'
import { useQuery } from '@tanstack/react-query'
import { fetchProfile } from 'Api/profile'
import { Skeleton } from 'primereact/skeleton'

const Profile = () => {
    const { token, newLang } = useContext(AuthContext)
    const navigate = useNavigate()

    const [modal, setModal] = useState({ open: false })
    // status (change-phone = change-email)
    // 1 = method verification (phone)
    // 2 = input verification code (phone)
    // 3 = method verification (email)
    // 4 = input verification code (email)
    // 5 = success
    const [phoneStatus, setPhoneStatus] = useState(3)

    const phoneStatusModal = () => {
        switch (phoneStatus) {
            case 1:
                return (
                    <>
                        <div className='flex flex-col items-center'>
                            <h5 className='mb-3 text-base font-semibold'>
                                Select Verification Method
                            </h5>
                            <h5 className='mb-3 text-ss'>
                                Please select one of the methods below to get
                                verification code
                            </h5>
                            <div className='rounded-lg border-2 p-2 flex border-slate-300 w-full justify-between gap-2'>
                                <img src={ArrowRightSvg} alt='' />
                                <div className='main flex flex-col'>
                                    <span className='text-blue-five text-sm font-semibold'>
                                        SMS
                                    </span>
                                    <span className='text-blue-five text-ss'>
                                        *********789
                                    </span>
                                </div>
                                <img
                                    className='ml-auto'
                                    src={ArrowRightSvg}
                                    alt=''
                                />
                            </div>
                        </div>

                        <button
                            class='btn rounded-full bg-blue-one text-white w-full mt-5'
                            onClick={() => {
                                setModal({ open: false })
                            }}
                        >
                            Send
                        </button>
                    </>
                )
            case 2:
                return (
                    <div className='flex flex-col items-center'>
                        <h5 className='mb-3 text-base font-semibold'>
                            Enter Verification Code
                        </h5>
                        <h5 className='mb-3 text-ss'>
                            Verification Code has been sent via SMS to
                            **********789
                        </h5>
                        <InputText
                            className='w-3/4 rounded-xl text-black'
                            placeholder={''}
                            // onChange={(e) => setValue1(e.target.value)}
                        />
                        <div className='flex mt-4'>
                            <span className='text-black mr-2 text-xs'>
                                Not receiving SMS ?{' '}
                            </span>
                            <span className='text-blue-five text-xs font-semibold'>
                                Resending
                            </span>
                        </div>
                    </div>
                )
            case 3:
                return (
                    <>
                        <div className='flex flex-col items-center'>
                            <h5 className='mb-3 text-base font-semibold'>
                                Select Verification Method
                            </h5>
                            <h5 className='mb-3 text-ss'>
                                Please select one of the methods below to get
                                verification code
                            </h5>
                            <div className='rounded-lg border-2 p-2 flex border-slate-300 w-full justify-between gap-2'>
                                <img src={ArrowRightSvg} alt='' />
                                <div className='main flex flex-col'>
                                    <span className='text-blue-five text-sm font-semibold'>
                                        Email to
                                    </span>
                                    <span className='text-blue-five text-ss'>
                                        Andre@gmail.com
                                    </span>
                                </div>
                                <img
                                    className='ml-auto'
                                    src={ArrowRightSvg}
                                    alt=''
                                />
                            </div>
                        </div>

                        <button
                            class='btn rounded-full bg-blue-one text-white w-full mt-5'
                            onClick={() => {
                                setModal({ open: false })
                            }}
                        >
                            Send
                        </button>
                    </>
                )
            case 4:
                return (
                    <div className='flex flex-col items-center'>
                        <h5 className='mb-3 text-base font-semibold'>
                            Enter Verification Code
                        </h5>
                        <h5 className='mb-3 text-ss'>
                            Kode Verifikasi telah dikirim melalui Nomor
                            xxxxxxxx12345
                        </h5>
                        <InputText
                            className='w-3/4 rounded-xl text-black'
                            placeholder={''}
                            // onChange={(e) => setValue1(e.target.value)}
                        />
                        <div className='flex mt-4'>
                            <span className='text-black mr-2 text-xs'>
                                Not receiving SMS ?{' '}
                            </span>
                            <span className='text-blue-five text-xs font-semibold'>
                                Resending
                            </span>
                        </div>
                    </div>
                )
            default:
                break
        }
    }

    const { data: profile, isLoading: isLoadingProfile } = useQuery(
        ['profile', token],
        () => fetchProfile({ token }),
        {
            onSuccess: (data) => {},
        }
    )

    return (
        <>
            <div
                className={`flex flex-col ${isMobile ? 'w-full' : 'w-3/4'} p-2`}
            >
                <h1
                    className={`text-black text-xl font-semibold ${
                        isMobile && 'hidden'
                    }`}
                >
                    {newLang?.profile}
                </h1>
                <div className='mt-8 flex flex-col gap-5'>
                    {/* <div
                        className={`single-input ${!isMobile && 'max-w-[50%]'}`}
                    >
                        <label className='label'>
                            <span className='label-text text-black font-semibold'>
                                Username
                            </span>
                        </label>
                        {!isLoadingProfile ? (
                            <div className='relative'>
                                <input
                                    type='text'
                                    placeholder='Type here'
                                    class='input input-bordered w-full bg-transparent rounded-md text-black disabled:bg-transparent'
                                    disabled='true'
                                    value={profile?.data?.username}
                                />
                                <Link to='/profile/change-username'>
                                    <span className='text-blue-five text-base font-semibold absolute top-3 right-5  cursor-pointer'>
                                        Edit
                                    </span>
                                </Link>
                            </div>
                        ) : (
                            <Skeleton width='300px' />
                        )}
                    </div> */}
                    <div
                        className={`single-input ${!isMobile && 'max-w-[50%]'}`}
                    >
                        <label className='label'>
                            <span className='label-text text-black font-semibold'>
                                {newLang?.firstName}
                            </span>
                        </label>
                        {!isLoadingProfile ? (
                            <div className='relative'>
                                <input
                                    type='text'
                                    placeholder='Type here'
                                    class='input input-bordered w-full bg-transparent rounded-md text-black disabled:bg-transparent'
                                    disabled='true'
                                    value={profile?.data?.first_name}
                                />
                                <Link to='/profile/change-firstname'>
                                    <span className='text-blue-five text-base font-semibold absolute top-3 right-5  cursor-pointer'>
                                        {newLang?.edit ?? 'Edit'}
                                    </span>
                                </Link>
                            </div>
                        ) : (
                            <Skeleton width='300px' />
                        )}
                    </div>
                    <div
                        className={`single-input ${!isMobile && 'max-w-[50%]'}`}
                    >
                        <label className='label'>
                            <span className='label-text text-black font-semibold'>
                                {newLang?.lastName}
                            </span>
                        </label>
                        {!isLoadingProfile ? (
                            <div className='relative'>
                                <input
                                    type='text'
                                    placeholder='Type here'
                                    class='input input-bordered w-full bg-transparent rounded-md text-black disabled:bg-transparent'
                                    disabled='true'
                                    value={profile?.data?.last_name}
                                />
                                <Link to='/profile/change-lastname'>
                                    <span className='text-blue-five text-base font-semibold absolute top-3 right-5  cursor-pointer'>
                                        {newLang?.edit ?? 'Edit'}
                                    </span>
                                </Link>
                            </div>
                        ) : (
                            <Skeleton width='300px' />
                        )}
                    </div>
                    <div
                        className={`single-input ${!isMobile && 'max-w-[50%]'}`}
                    >
                        <label className='label'>
                            <span className='label-text text-black font-semibold'>
                                {newLang?.phoneNumber}
                            </span>
                        </label>
                        {!isLoadingProfile ? (
                            <div className='relative'>
                                <input
                                    type='text'
                                    placeholder='Type here'
                                    class='input input-bordered w-full bg-transparent rounded-md text-black disabled:bg-transparent'
                                    disabled='true'
                                    value={profile?.data?.phone}
                                />
                                <Link to='/profile/change-phone'>
                                    <span className='text-blue-five text-base font-semibold absolute top-3 right-5  cursor-pointer'>
                                        {newLang?.edit ?? 'Edit'}
                                    </span>
                                </Link>
                                {/* <span
                                    className='text-blue-five text-base font-semibold absolute top-3 right-5  cursor-pointer'
                                    onClick={() => setModal({ open: true })}
                                >
                                    Edit
                                </span> */}
                            </div>
                        ) : (
                            <Skeleton width='300px' />
                        )}
                    </div>
                    <div
                        className={`single-input ${!isMobile && 'max-w-[50%]'}`}
                    >
                        <label className='label'>
                            <span className='label-text text-black font-semibold'>
                                {newLang?.email}
                            </span>
                        </label>
                        {!isLoadingProfile ? (
                            <div className='relative'>
                                <input
                                    type='text'
                                    placeholder='Type here'
                                    class='input input-bordered w-full bg-transparent rounded-md text-black disabled:bg-transparent'
                                    disabled='true'
                                    value={profile?.data?.email}
                                />
                                <Link to='/profile/change-email'>
                                    <span className='text-blue-five text-base font-semibold absolute top-3 right-5  cursor-pointer'>
                                        {newLang?.edit ?? 'Edit'}
                                    </span>
                                </Link>
                            </div>
                        ) : (
                            <Skeleton width='300px' />
                        )}
                    </div>
                </div>
            </div>
            {modal.open && (
                <Modal
                    visible={modal.open}
                    cancel={() => setModal({ open: false })}
                    title={''}
                >
                    {phoneStatusModal()}
                </Modal>
            )}
        </>
    )
}

export default Profile
