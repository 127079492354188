import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    options: {
        limit: 10,
        page: 1,
        order: 'created_at',
        sort: 'DESC',
    },
}

export const transactionPaging = createSlice({
    name: 'profileFilter',
    initialState,
    reducers: {
        changePaging: (state, action) => {
            state.options = {
                ...state.options,
                limit: action.payload.limit,
            }
        },
    },
})

export const { changePaging } = transactionPaging.actions

export default transactionPaging.reducer
