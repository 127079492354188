import React, { useState } from 'react'
import {
    Carousel,
    CategoryHome,
    HomeAds,
    IklanVip,
    RunningText,
    TopUpTagihan,
    KategoryPilihan,
    OtherAds,
} from 'Components/index'
import HomeAdsData from 'Constants/homeAds.json'
import { useSelector } from 'react-redux'
import { toValue } from 'Utils/conversion'

const Home = () => {
    // const isLoggedin = useSelector((state) => state.authenticated.isLogin)
    const isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'))
    
    return (
        <>
            <div className={`pt-[100px] ${isLoggedin ? 'md:pt-[200px]' : 'md:pt-[175px]'} max-w-screen-xl m-auto p-2`}>
                <Carousel />
                <RunningText />
                <div className='flex flex-col md:flex-row w-full gap-2'>
                    <TopUpTagihan />
                    <KategoryPilihan />
                </div>
                <CategoryHome />
                {/* <IklanVip /> */}
                {/* {HomeAdsData.map((item) => ( */}
                    <HomeAds />
                {/* ))} */}
                <OtherAds />
            </div>
        </>
    )
}

export default Home
