import { api } from 'Utils/axios'

export const fetchPaymentMethod = ({ token }) => {
    return api
        .get('/payment-method', {
            headers: {
                language: localStorage.getItem('lang'),
                'nihao-token': token,
            },
        })
        .then((res) => res?.data)
}

export const fetchPaymentChannel = ({ token, id, nominal }) => {
    return api
        .post(
            '/payment-channel',
            {
                payment_method_id: id.id,
                amount: parseInt(nominal),
            },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchPrefix = ({ token, number }) => {
    return api
        .post(
            '/biller/prefix-phone-number',
            {
                phone_number: number,
            },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchPriceList = ({ token, filter, options }) => {
    return api
        .post(
            '/biller/price/list',
            {
                filter: {
                    ...filter,
                },
                ...options,
            },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchTypes = ({ token, brand, category }) => {
    return api
        .get(`/biller/price/types?brand=${brand}&category=${category}`, {
            headers: {
                language: localStorage.getItem('lang'),
                'nihao-token': token,
            },
        })
        .then((res) => res?.data)
}

export const fetchBrand = ({ token, category }) => {
    return api
        .get(`/biller/price/brand?category=${category}`, {
            headers: {
                language: localStorage.getItem('lang'),
                'nihao-token': token,
            },
        })
        .then((res) => res?.data)
}

export const fetchInquiry = ({ token, payload }) => {
    return api
        .post(
            '/transaction/ppob/inquiry',
            {
                ...payload,
            },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchInquiryPhone = ({ token, payload }) => {
    return api
        .post(
            '/transaction/ppob/inquiry/phone-postpaid',
            {
                ...payload,
            },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchPosting = ({ token, payload }) => {
    return api
        .post(
            '/transaction/ppob/posting',
            {
                ...payload,
            },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchConfirmPayment = ({ token, id }) => {
    return api
        .put(
            `transaction/ppob/payment/${id}`,
            {},
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchBCAmount = ({ token, id }) => {
    return api
    .get(`/custom-declaration/amount`, {
        headers: {
            language: localStorage.getItem('lang'),
            'nihao-token': token,
        },
    })
    .then((res) => res?.data)
}
