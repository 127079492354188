import React, { useContext } from 'react'
import { InputText } from 'primereact/inputtext'
import { isMobile } from 'react-device-detect'
import { AuthContext } from 'Context/authContext'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchChangeProfile, fetchProfile } from 'Api/profile'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Toast from 'Utils/toast'

const schema = yup
    .object({
        oldPhone: yup.string().required('Old Phone Number is Required'),
        newPhone: yup.string().required('Phone Number is Required'),
    })
    .required()

const Phone = () => {
    const { token, newLang } = useContext(AuthContext)
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const { data: profile, isLoading: isLoadingProfile } = useQuery(
        ['profile', token],
        () => fetchProfile({ token }),
        {
            onSuccess: (data) => {
                setValue('oldPhone', data?.data?.phone)
            },
        }
    )

    const { mutate: mutateUpdateProfile, isLoading: isLoadingUpdateProfile } =
        useMutation(fetchChangeProfile, {
            onSuccess: (data) => {
                Toast('success', data?.message?.response_message)
                navigate('/profile')
            },
        })

    const onSubmit = (data) => {
        const payload = {
            first_name: profile?.data?.first_name,
            last_name: profile?.data?.last_name,
            profile_picture_image_url: profile?.data?.profile_picture_image_url,
            phone: data.newPhone,
            email: profile?.data?.email,
            region: profile?.data?.region,
            address: profile?.data?.address,
        }
        mutateUpdateProfile({ payload, token })
    }

    return (
        <div
            className={`flex flex-col ${
                isMobile ? 'w-full' : 'w-3/4'
            } gap-5 p-2`}
        >
            {!isMobile && (
                <h1 className='text-black text-xl font-semibold'>
                    {newLang?.change} {newLang?.phoneNumber}
                </h1>
            )}
            <span className='text-black-three text-ss'>
                {newLang?.makeSurePhoneNumber ??
                    `Make sure the cellphone number you enter is an active cellphone
                number`}
            </span>
            <div className='mt-8 flex flex-col gap-5'>
                <div className={`single-input ${!isMobile && 'max-w-[50%]'}`}>
                    <h5 className='label-text text-black font-semibold mb-2'>
                        {newLang?.oldPhone}
                    </h5>
                    <InputText
                        className='w-full'
                        defaultValue={profile?.data?.phone}
                        // onChange={(e) => setValue1(e.target.value)}
                    />
                    <label class='label'>
                        <span class='label-text text-black font-semibold'>
                            {newLang?.newPhone}
                        </span>
                    </label>
                    <div className='p-inputgroup'>
                        {/* <span className='p-inputgroup-addon'>
                            <span className='input-bordered bg-slate-200 label-text'>
                                +62
                            </span>
                        </span>
                        <InputText
                            placeholder='0812234657812'
                            value={`812234651234`}
                        /> */}
                        <PhoneInput
                            className='text-black border-2 border-b-gray-300 p-[10px] w-full mr-2'
                            // placeholder='Enter phone number'
                            defaultCountry='ID'
                            style={{ borderRadius: '0' }}
                            onChange={(value) => setValue('newPhone', value)}
                            // {...register('phonenumber', {
                            //     required: true,
                            // })}
                        />
                    </div>
                    <span className='text-black text-ss'>
                        {newLang?.weWillSendPhoneNumber ??
                            `We will send a verification code to this new mobile
                        number`}
                    </span>
                </div>
            </div>
            <button
                className='btn bg-blue-five w-36 text-white'
                onClick={handleSubmit(onSubmit)}
            >
                {newLang?.save}
            </button>
        </div>
    )
}

export default Phone
