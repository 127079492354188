import React, { useContext, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { isMobile } from 'react-device-detect'
import { AuthContext } from 'Context/authContext'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchChangeProfile, fetchProfile } from 'Api/profile'
import Toast from 'Utils/toast'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup
    .object({
        email: yup
            .string()
            .email('Email not Validated')
            .required('Email is Required'),
    })
    .required()

const Email = () => {
    const { token, newLang } = useContext(AuthContext)

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const { data: profile, isLoading: isLoadingProfile } = useQuery(
        ['profile', token],
        () => fetchProfile({ token }),
        {
            onSuccess: (data) => {
                setValue('firstName', data?.data?.first_name)
            },
        }
    )

    const { mutate: mutateUpdateProfile, isLoading: isLoadingUpdateProfile } =
        useMutation(fetchChangeProfile, {
            onSuccess: (data) => {
                Toast('success', data?.message?.response_message)
            },
        })

    const onSubmit = (data) => {
        const payload = {
            first_name: profile?.data?.first_name,
            last_name: profile?.data?.last_name,
            profile_picture_image_url: profile?.data?.profile_picture_image_url,
            phone: profile?.data?.phone,
            email: data.email,
            region: profile?.data?.region,
            address: profile?.data?.address,
        }
        mutateUpdateProfile({ token, payload })
    }

    return (
        <div
            className={`flex flex-col ${
                isMobile ? 'w-full' : 'w-3/4'
            } gap-5 p-2`}
        >
            {!isMobile && (
                <h1 className='text-black text-xl font-semibold'>
                    {newLang?.change} {newLang?.email}
                </h1>
            )}
            <span className='text-black-three text-ss'>
                {newLang?.makeSureEmail ??
                    `Make sure your email is active, to make the process of replacing
                a new email easier`}
            </span>
            <div className='mt-8 flex flex-col gap-5'>
                <div className={`single-input ${!isMobile && 'max-w-[50%]'}`}>
                    <div className='mb-3'>
                        <h5 className='label-text text-black font-semibold mb-2'>
                            {newLang?.registered ?? 'Registered'}{' '}
                            {newLang?.email}
                        </h5>
                        <InputText
                            className='w-full rounded-xl'
                            placeholder={'Andre.andrean@gmail.com'}
                            defaultValue={profile?.data?.email}
                            // onChange={(e) => setValue1(e.target.value)}
                            disabled
                        />
                    </div>
                    <div className='mb-3'>
                        <h5 className='label-text text-black font-semibold mb-2'>
                            {newLang?.new ?? 'New'} {newLang?.email}
                        </h5>
                        <InputText
                            className='w-full'
                            defaultValue={''}
                            // onChange={(e) => setValue1(e.target.value)}
                            {...register('email', { required: true })}
                        />
                        {errors.email && (
                            <p className='text-xs text-red-600'>
                                {errors.email.message}
                            </p>
                        )}
                    </div>
                    <span className='text-black text-ss'>
                        {newLang?.sendVerificationEmail ??
                            `We will send a verification code to this new Email`}
                    </span>
                </div>
            </div>
            <button
                className='btn bg-blue-five w-36 text-white'
                onClick={handleSubmit(onSubmit)}
            >
                {newLang?.save}
            </button>
        </div>
    )
}

export default Email
