import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from '../i18n/en.json'
import id from '../i18n/id.json'
import cn from '../i18n/cn.json'

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: en,
        },
        id: {
            translation: id,
        },
        cn: {
            translation: cn,
        },
    },
    fallbackLng: 'en',
})
