import { useQuery } from '@tanstack/react-query'
import { fetchTransaction } from 'Api/transaction'
import { AuthContext } from 'Context/authContext'
import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    changeCategory,
    changeFilter,
} from 'Store/profile/transaction/transactionFilter'
import CardContent from './components/cardContent'
import { changePaging } from 'Store/profile/transaction/transactionPaging'
import illustrationNoData from 'Assets/images/illustration-nodata.jpg'

const NewTransaction = () => {
    const { token, newLang } = useContext(AuthContext)
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState('pulsa')
    const [filterTab, setFilterTab] = useState('all')

    const { filter } = useSelector((state) => state.transactionFilter)
    const { options } = useSelector((state) => state.transactionPaging)

    const listTab = [
        {
            name: 'pulsa',
            lang: newLang?.phoneAirtime ?? 'Credit',
        },
        {
            name: 'data',
            lang: newLang?.phoneData ?? 'Phone Data',
        },
        {
            name: 'pascabayar',
            lang: newLang?.postpaid ?? 'Pascabayar',
        },
        {
            name: 'internet',
            lang: newLang?.internet ?? 'Internet',
        },
        {
            name: 'token',
            lang: newLang?.prepaidElectricity ?? 'Token Listrik',
        },
        {
            name: 'tv',
            lang: newLang?.tvPascabayar ?? 'Tv Pascabayar',
        },
        {
            name: 'bpjs',
            lang: newLang?.bpjs ?? 'BPJS',
        },
        {
            name: 'pdam',
            lang: newLang?.pdam ?? 'PDAM',
        },
        {
            name: 'e-money',
            lang: newLang?.emoney ?? 'E-Money',
        },
    ]

    const listFilter = [
        {
            name: 'all',
            lang: newLang?.all ?? 'All',
        },
        {
            name: 'success',
            lang: newLang?.success ?? 'Success',
        },
        {
            name: 'waiting payment',
            lang: newLang?.waitingPayment ?? 'Waiting Payment',
        },
        {
            name: 'cancelled',
            lang: newLang?.cancelled ?? 'Cancelled',
        },
        {
            name: 'failed',
            lang: newLang?.failed ?? 'Failed',
        },
    ]

    const {
        data: transactionListData,
        isLoading,
        refetch,
    } = useQuery(
        ['transactions', filter, options],
        () =>
            fetchTransaction({
                token,
                query: { ...filter },
                options: { ...options },
            }),
        {
            onSuccess: (data) => {
                // setTotalData(data.total_data)
                // console.log(data)
            },
        }
    )

    const handleChangeTab = (item) => {
        setActiveTab(item.name)
        dispatch(changeCategory({ category: item.name }))
        refetch()
    }

    const handleChangeFilter = (item) => {
        setFilterTab(item.name)
        dispatch(changeFilter({ status: item.name }))
        refetch()
    }

    const handleSeeMore = () => {
        dispatch(changePaging({ limit: options.limit + 10 }))
        refetch()
    }

    return (
        <div>
            <h2 className='text-xl font-semibold'>{newLang?.transaction}</h2>
            <div className='tabs mt-2'>
                {listTab.map((item, idx) => (
                    <a
                        key={idx}
                        className={`tab tab-bordered ${
                            activeTab == item.name && 'tab-active'
                        }`}
                        onClick={() => handleChangeTab(item)}
                    >
                        {item.lang}
                    </a>
                ))}
            </div>
            <div className='flex gap-2 mt-2'>
                {listFilter.map((item, idx) => (
                    <span
                        key={idx}
                        className={`px-3 py-2 font-bold text-[8px] rounded-[10px] border-[1px] border-blue-six cursor-pointer text-black-six ${
                            filterTab == item.name && 'bg-blue-six'
                        }`}
                        onClick={() => handleChangeFilter(item)}
                    >
                        {item.lang}
                    </span>
                ))}
            </div>
            <div className='pt-6 w-full'>
                {!isLoading && transactionListData.data?.length ? (
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                        {transactionListData.data?.map((item, idx) => {
                            return <CardContent key={idx} data={item} />
                        })}
                    </div>
                ) : (
                    <div className='relative flex justify-center'>
                        <h2 className='text-4xl font-bold'>No Data</h2>
                    </div>
                )}
            </div>
            {transactionListData?.data?.length &&
            options?.limit < transactionListData?.total_data ? (
                <div className='justify-center w-full text-center mt-2'>
                    <button
                        className='btn bg-blue-five border-none'
                        onClick={() => handleSeeMore()}
                    >
                        See more
                    </button>
                </div>
            ) : null}
        </div>
    )
}

export default NewTransaction
