import React, { useContext, useState } from 'react'
import { AuthContext } from 'Context/authContext'
import { useQuery, useQueries } from '@tanstack/react-query'
import { fetchListHomeSection } from 'Api/home'
import { fetchListAdvertiseCat } from 'Api/advertise'
import { Link, useNavigate } from 'react-router-dom'
import skeletonPreview from 'Assets/images/default_preview_thumbnail.svg'
import Card from './Card'

const HomeAds = () => {
    const { token, newLang } = useContext(AuthContext)
    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [listCat, setListCat] = useState([])

    const [query, setQuery] = useState({
        set_home_section: false,
        home_section: '',
        set_title: false,
        title: '',
        set_category: false,
        category: [],
        set_status: true,
        status: 1,
        set_tag: false,
        tags: '',
    })

    const [options, setOptions] = useState({
        limit: 10,
        page: 1,
        order: 'order_number',
        sort: 'ASC',
    })

    const {
        data: listHomeSectionData,
        isLoading: isLoadingListHomeSectionData,
    } = useQuery(
        ['list-home-section', query, token],
        () => fetchListHomeSection({ token, query, options }),
        {
            onSuccess: (data) => {
                let newListCat = []
                data.data?.map((item) => {
                    newListCat.push(item.id)
                })
                setListCat(newListCat)
            },
        }
    )

    const adsHomeSectionData = useQueries({
        queries:
            listCat?.map((id) => {
                return {
                    queryKey: ['section-ad', id],
                    queryFn: () =>
                        fetchListAdvertiseCat(token, query, options, id),
                    // enabled: !!listCat,
                }
            }) ?? [],
    })
    
    return (
        <>
            {adsHomeSectionData?.map((ads, idx) => {
                const data = {
                    id: listHomeSectionData?.data[idx]?.id,
                    imageThumbnail:
                        listHomeSectionData?.data[idx]?.image_thumbnail_url,
                    category: listHomeSectionData?.data[idx]?.title,
                    ads: ads?.data?.data,
                }
                if(data.ads?.length != 0) {
                    return (
                        <div className='flex flex-col mt-5'>
                            <div className='flex justify-between'>
                                <span className='text-2xl font-bold text-black-three'>
                                    {data.category}
                                </span>
                                <Link to={`advertise-list?id=${data.id}&name=${data.category}`}>
                                    <div
                                        className='px-2 py-1 rounded-xl bg-blue-five cursor-pointer hover:scale-105 ease-in-out duration-300'
                                        // onClick={() => navigate('advertise-list')}
                                    >
                                        <span className='text-xs text-white'>
                                            {newLang?.seeAll}
                                        </span>
                                    </div>
                                </Link>
                            </div>
    
                            <div className='w-full mt-5'>
                                {data?.id == "ce12c94a-8a97-4df0-ac7f-aac0c50fd975" ? (
                                    <div
                                        className='p-4 w-full mt-5'
                                        style={{
                                            backgroundImage: `url(${data.imageThumbnail})`,
                                            // backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                        }}
                                    >
                                        <Card data={data?.ads} type={'vipAds'}/>
                                    </div>
                                ) : (
                                    <div className='flex space-x-3 m-auto overflow-auto py-4 w-full'>
                                        <img
                                            className='block w-[155px] md:w-[210px] h-[224px] md:h-[300px] rounded-xl'
                                            src={data.imageThumbnail}
                                            alt=''
                                        />
                                        <Card data={data?.ads} type={'homeAds'}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                }
            })}
        </>
    )
}

export default HomeAds
