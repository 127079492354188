import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    is_include: false,
    carryings: [],
}

export const carrying = createSlice({
    name: 'carrying',
    initialState,
    reducers: {
        addCarrying: (state, action) => {
            state.carryings = [action.payload, ...state.carryings]
            state.is_include = true
        },
        deleteCarrying: (state, action) => {
            state.carryings = state.carryings.filter(
                (item) => action.payload != item.id
            )
            if (state.carryings.length == 0) state.is_include = false
        },
    },
})

export const { addCarrying, deleteCarrying } = carrying.actions

export default carrying.reducer
