import React, { Suspense, lazy } from 'react'
import { useRoutes } from 'react-router'
import Home from 'Pages/Home'
import Login from 'Pages/auth/Login'
import NewPassword from 'Pages/auth/NewPassword'
// import Register from 'Pages/auth/register'
import AboutUs from 'Pages/others/AboutUs'
import TermCondition from 'Pages/others/TermCondition'
import Faq from 'Pages/others/Faq'
import AdsList from 'Pages/AdsList'
import AdDetail from 'Pages/AdDetail'
import Modal from 'Components/Modal'
import ThumbnailGallery from 'Components/ThumbnailGallery'

import { MainLayout } from 'Layouts/main'
import AuthLayout from 'Layouts/auth'
import ProfileLayout from 'Layouts/profile'
import Profile from 'Pages/profile/Profile'
import Username from 'Pages/profile/Username'
import Firstname from 'Pages/profile/Firstname'
import Lastname from 'Pages/profile/Lastname'
import Phone from 'Pages/profile/Phone'
import Email from 'Pages/profile/Email'
import Password from 'Pages/profile/Password'
import Transaction from 'Pages/profile/TransactionOld/index'
import Notification from 'Pages/profile/Notification'
import Favorite from 'Pages/profile/Favorite'
import SearchPage from 'Pages/SearchPage'
import BannerDetail from 'Pages/BannerDetail'
import PrivacyPolicy from 'Pages/others/PrivacyPolicy'
import Register from 'Pages/auth/Register'
import DigitalProductLayout from 'Layouts/digiprod'
// import WaitingPayment from 'Pages/digiprod/WaitingPayment'
// import DetailPayment from 'Pages/digiprod/DetailPayment'
import NotFound from 'Pages/others/NotFound'
import Topup from 'Pages/digitalProduct/topUp/credit'
import WaitingPaymentTopUp from 'Pages/digitalProduct/topUp/waitingPayment'
import WaitingPaymentPulsa from 'Pages/digitalProduct/pulsa/waitingPayment'
import DetailPaymentPulsa from 'Pages/digitalProduct/pulsa/detailPayment'
import DetailPaymentTv from 'Pages/digitalProduct/tv/detailpayment'
import WaitingPaymentTv from 'Pages/digitalProduct/tv/waitingPayment'
import Tv from 'Pages/digitalProduct/tv/credit'
import Pulsa from 'Pages/digitalProduct/pulsa/credit'
import PaketData from 'Pages/digitalProduct/data/credit'
import DetailPaymentPaketData from 'Pages/digitalProduct/data/detailPayment'
import WaitingPaymentPaketData from 'Pages/digitalProduct/data/waitingPayment'
import TokenListrik from 'Pages/digitalProduct/token/credit'
import WaitingPaymentTokenListrik from 'Pages/digitalProduct/token/waitingPayment'
import DetailPaymentTokenListrik from 'Pages/digitalProduct/token/detailPayment'
import Emoney from 'Pages/digitalProduct/e-money/credit'
import DetailPaymentEmoney from 'Pages/digitalProduct/e-money/detailPayment'
import WaitingPaymentEmoney from 'Pages/digitalProduct/e-money/waitingPayment'
import Hp from 'Pages/digitalProduct/hp/credit'
import DetailPaymentHp from 'Pages/digitalProduct/hp/detailpayment'
import WaitingPaymentHp from 'Pages/digitalProduct/hp/waitingPayment'
import Internet from 'Pages/digitalProduct/internet/credit'
import WaitingPaymentInternet from 'Pages/digitalProduct/internet/waitingPayment'
import DetailPaymentInternet from 'Pages/digitalProduct/internet/detailpayment'
import Pdam from 'Pages/digitalProduct/pdam/credit'
import DetailPaymentPdam from 'Pages/digitalProduct/pdam/detailpayment'
import WaitingPaymentPdam from 'Pages/digitalProduct/pdam/waitingPayment'
import DetailPaymentBpjs from 'Pages/digitalProduct/bpjs/detailpayment'
import WaitingPaymentBpjs from 'Pages/digitalProduct/bpjs/waitingPayment'
import Bpjs from 'Pages/digitalProduct/bpjs/credit'
import Imei from 'Pages/Imei'
import Goods from 'Pages/Imei/goods'
import RegisterImei from 'Pages/Imei/imei'
import ImeiPayment from 'Pages/Imei/payment'
import NewTransaction from 'Pages/profile/Transaction/NewTransaction'
import TransactionPoint from 'Pages/profile/TransactionPoint/TransactionPoint'
import TransactionImei from 'Pages/profile/TransactionImei/TransactionImei'
import DetailPayment from 'Pages/digitalProduct/detailpayment'
import WaitingPayment from 'Pages/digitalProduct/waitingPayment'

const MainRoutes = () => {
    let routes = useRoutes([
        {
            path: '/',
            element: <MainLayout />,
            children: [
                { index: true, element: <Home /> },
                { path: 'search', element: <SearchPage /> },
                { path: 'about-us', element: <AboutUs /> },
                { path: 'terms-condition', element: <TermCondition /> },
                { path: 'faq', element: <Faq /> },
                { path: 'privacy-policy', element: <PrivacyPolicy /> },
                { path: 'advertise-list', element: <AdsList /> },
                { path: ':slug', element: <AdDetail /> },
                { path: 'thumb', element: <ThumbnailGallery /> },
                { path: 'banner/:id', element: <BannerDetail /> },
                { path: '*', element: <NotFound /> },
                {
                    path: 'profile',
                    element: (
                        // <ProtectedLayout>
                        <ProfileLayout />
                        // </ProtectedLayout>
                    ),
                    children: [
                        { index: true, element: <Profile /> },
                        { path: 'change-username', element: <Username /> },
                        { path: 'change-firstname', element: <Firstname /> },
                        { path: 'change-lastname', element: <Lastname /> },
                        { path: 'change-email', element: <Email /> },
                        { path: 'change-phone', element: <Phone /> },
                        { path: 'forgot-password', element: <Password /> },
                        { path: 'transaction', element: <NewTransaction /> },
                        { path: 'transaction-point', element: <TransactionPoint /> },
                        { path: 'transaction-imei', element: <TransactionImei /> },
                        { path: 'notification', element: <Notification /> },
                        { path: 'favorite', element: <Favorite /> },
                        // { path: 'new-transaction', element: <NewTransaction /> },
                    ],
                },
                {
                    // path: 'credit',
                    element: (
                        // <ProtectedLayout>
                        <DigitalProductLayout />
                        // </ProtectedLayout>
                    ),
                    children: [
                        { path: 'credit', element: <Pulsa /> },
                        { path: 'credit-tv', element: <Tv /> },
                        { path: 'credit-data', element: <PaketData /> },
                        { path: 'credit-token', element: <TokenListrik /> },
                        { path: 'credit-emoney', element: <Emoney /> },
                        { path: 'credit-hp', element: <Hp /> },
                        { path: 'credit-net', element: <Internet /> },
                        { path: 'credit-pdam', element: <Pdam /> },
                        { path: 'credit-bpjs', element: <Bpjs /> },
                    ],
                },
                // { path: 'detail-payment', element: <DetailPayment /> },
                // { path: 'waiting-payment', element: <WaitingPayment /> },
                { path: 'topup', element: <Topup /> },
                {
                    path: 'waiting-payment-topup',
                    element: <WaitingPaymentTopUp />,
                },
                {
                    path: 'detail-payment-pulsa',
                    element: <DetailPaymentPulsa />,
                },
                {
                    path: 'waiting-payment-pulsa',
                    element: <WaitingPaymentPulsa />,
                },
                {
                    path: 'detail-payment-tv',
                    element: <DetailPaymentTv />,
                },
                {
                    path: 'waiting-payment-tv',
                    element: <WaitingPaymentTv />,
                },
                {
                    path: 'detail-payment-data',
                    element: <DetailPaymentPaketData />,
                },
                {
                    path: 'waiting-payment-data',
                    element: <WaitingPaymentPaketData />,
                },
                {
                    path: 'detail-payment-token',
                    element: <DetailPaymentTokenListrik />,
                },
                {
                    path: 'waiting-payment-token',
                    element: <WaitingPaymentTokenListrik />,
                },
                {
                    path: 'detail-payment-emoney',
                    element: <DetailPaymentEmoney />,
                },
                {
                    path: 'waiting-payment-emoney',
                    element: <WaitingPaymentEmoney />,
                },
                {
                    path: 'detail-payment-hp',
                    element: <DetailPaymentHp />,
                },
                {
                    path: 'waiting-payment-hp',
                    element: <WaitingPaymentHp />,
                },
                {
                    path: 'detail-payment-net',
                    element: <DetailPaymentInternet />,
                },
                {
                    path: 'waiting-payment-net',
                    element: <WaitingPaymentInternet />,
                },
                {
                    path: 'detail-payment-pdam',
                    element: <DetailPaymentPdam />,
                },
                {
                    path: 'waiting-payment-pdam',
                    element: <WaitingPaymentPdam />,
                },
                {
                    path: 'detail-payment-bpjs',
                    element: <DetailPaymentBpjs />,
                },
                {
                    path: 'waiting-payment-bpjs',
                    element: <WaitingPaymentBpjs />,
                },
                // {
                //     path: 'imei',
                //     element: <Imei />,
                // },
                // {
                //     path: 'goods',
                //     element: <Goods />,
                // },
                // {
                //     path: 'register-imei',
                //     element: <RegisterImei />,
                // },
                // {
                //     path: 'payment-imei',
                //     element: <ImeiPayment />,
                // },
                // {
                //     path: 'detail-payment',
                //     element: <DetailPayment />,
                // },
                // {
                //     path: 'waiting-payment',
                //     element: <WaitingPayment />,
                // },
            ],
        },
        {
            element: <AuthLayout />,
            children: [
                { path: '/login', element: <Login /> },
                { path: '/register', element: <Register /> },
                { path: '/new-password', element: <NewPassword /> },
            ],
        },
    ])
    return routes
}

export default MainRoutes
