import React, { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { fetchFavorite } from 'Api/profile'
import { useQuery } from '@tanstack/react-query'
import { AuthContext } from 'Context/authContext'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const Favorite = () => {
    const { token, newLang } = useContext(AuthContext)
    const navigate = useNavigate()
    const [editState, setEditState] = useState(false)

    const [query, setQuery] = useState({
        set_home_section: false,
        home_section: '',
        set_title: false,
        title: '',
        set_category: false,
        category: [],
        set_tag: false,
        tags: '',
    })

    const [options, setOptions] = useState({
        limit: 1000,
        page: 1,
        order: 'created_at',
        sort: 'DESC',
    })

    const { data: favoriteData, isLoading: isLoadingFavoriteData } = useQuery(
        ['favorites'],
        () => fetchFavorite({ token, query, options }),
        {
            onSuccess: (data) => {},
        }
    )
    return (
        <div
            className={`flex flex-col ${
                isMobile ? 'w-full' : 'w-3/4'
            } gap-5 p-2`}
        >
            {!isMobile && (
                <h1 className='text-black text-xl font-semibold'>
                    {newLang?.favorite}
                </h1>
            )}
            <div className='flex gap-4'>
                {!editState ? (
                    <>
                        <span className='text-black-five text-base font-bold'>
                            12 {newLang?.ads}
                        </span>
                        <span
                            className='text-blue-four text-base font-bold cursor-pointer'
                            onClick={() => setEditState(!editState)}
                        >
                            {newLang?.setFavoriteAds ?? `Set Favorite Ads`}
                        </span>
                    </>
                ) : (
                    <div className='flex gap-4'>
                        <button className='btn text-white bg-blue-five normal-case text-xs font-bold rounded-lg max-h-[40px]'>
                            {newLang?.delete ?? 'Delete'}
                        </button>
                        <button
                            className='btn bg-white text-blue-five normal-case text-xs font-bold rounded-lg'
                            onClick={() => setEditState(!editState)}
                        >
                            {newLang?.cancel ?? 'Cancel'}
                        </button>
                    </div>
                )}
            </div>
            <div className='flex flex-wrap gap-2 w-full md:w-4/5 '>
                {/* list Ads */}
                {!isLoadingFavoriteData ? (
                    favoriteData?.data?.map((item) => (
                        <div
                            className='flex flex-nowrap p-3 border-2 border-gray-400 rounded-xl gap-4 w-full md:w-[350px] relative hover:scale-105 ease-in-out duration-300 cursor-pointer'
                            key={item.id}
                        >
                            <img
                                className='w-24 rounded-xl'
                                src={item?.image_website?.thumbnail}
                                alt=''
                            />
                            <div className='flex flex-col justify-between'>
                                <span className='text-black-three text-ss font-bold'>
                                    {item.title}
                                </span>
                                <span className='text-black-three text-ss'>
                                    {item.description_preview}
                                </span>
                                <div className='flex justify-between items-center'>
                                    <span class='badge bg-[#CDD9FC] border-none text-ss text-[#394978] font-bold'>
                                        {item.category_name}
                                    </span>
                                    <span class='text-black-six text-[8px]'>
                                        {moment(`${item.publish_from}`).format(
                                            'dddd DD MMMM YYYY h:mm a'
                                        )}
                                    </span>
                                </div>
                            </div>
                            {editState && (
                                <input
                                    type='checkbox'
                                    // checked='checked'
                                    className='checkbox w-5 h-5 absolute top-2 right-2 rounded-md '
                                />
                            )}
                        </div>
                    ))
                ) : (
                    <></>
                )}

                {/* <div className='flex flex-nowrap p-3 border-2 border-gray-400 rounded-xl gap-4 w-full md:w-[350px] relative hover:scale-105 ease-in-out duration-300 cursor-pointer'>
                    <img
                        className='w-24 rounded-xl'
                        src='https://images.unsplash.com/photo-1613187394626-cad4b9ca7f85?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
                        alt=''
                    />
                    <div className='flex flex-col justify-between'>
                        <span className='text-black-three text-ss font-bold'>
                            Judul Iklan 1
                        </span>
                        <span className='text-black-three text-ss'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Etiam eu turpis molestie,{' '}
                        </span>
                        <div className='flex justify-between items-center'>
                            <span class='badge bg-[#CDD9FC] border-none text-ss text-[#394978] font-bold'>
                                Badge
                            </span>
                            <span class='text-black-six text-[8px]'>
                                Selasa, 9 April 2022 17:48 WIB
                            </span>
                        </div>
                    </div>
                    {editState && (
                        <input
                            type='checkbox'
                            // checked='checked'
                            className='checkbox w-5 h-5 absolute top-2 right-2 rounded-md '
                        />
                    )}
                </div>
                <div className='flex flex-nowrap p-3 border-2 border-gray-400 rounded-xl gap-4 w-full md:w-[350px] relative hover:scale-105 ease-in-out duration-300 cursor-pointer'>
                    <img
                        className='w-24 rounded-xl'
                        src='https://images.unsplash.com/photo-1613187394626-cad4b9ca7f85?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
                        alt=''
                    />
                    <div className='flex flex-col justify-between'>
                        <span className='text-black-three text-ss font-bold'>
                            Judul Iklan 1
                        </span>
                        <span className='text-black-three text-ss'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Etiam eu turpis molestie,{' '}
                        </span>
                        <div className='flex justify-between items-center'>
                            <span class='badge bg-[#CDD9FC] border-none text-ss text-[#394978] font-bold'>
                                Badge
                            </span>
                            <span class='text-black-six text-[8px]'>
                                Selasa, 9 April 2022 17:48 WIB
                            </span>
                        </div>
                    </div>
                    {editState && (
                        <input
                            type='checkbox'
                            // checked='checked'
                            className='checkbox w-5 h-5 absolute top-2 right-2 rounded-md '
                        />
                    )}
                </div> */}
            </div>
        </div>
    )
}

export default Favorite
