import { api } from 'Utils/axios'

export const setCustomsDeclarationData = ({ combinePayload, token }) =>
    api
        .post('/custom-declaration/register', combinePayload, {
            headers: {
                language: 'EN',
                'nihao-token': token,
            },
        })
        .then((res) => res?.data)

export const fetchImeiList = ({ token, filter, options }) => {
    return api
        .post(
            '/custom-declaration/list',
            {
                filter: {
                    ...filter,
                },
                ...options,
            },
            {
                headers: {
                    language: localStorage.getItem('lang'),
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}

export const fetchPaymentCustomDeclaration = ({ token, id }) => {
    return api
        .put(
            `custom-declaration/payment/${id}`,
            {},
            {
                headers: {
                    language: 'EN',
                    'nihao-token': token,
                },
            }
        )
        .then((res) => res?.data)
}
