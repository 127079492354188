import React, { useContext, useState } from 'react'
import eyeSvg from 'Assets/images/eye.svg'
import heartSvg from 'Assets/images/heart.svg'
import heartWhiteSvg from 'Assets/images/heart-white.svg'
import shareSvg from 'Assets/images/share.svg'
import AdsDataJson from 'Constants/relatedAds.json'
import ThumbnailGallery from 'Components/ThumbnailGallery'
import { AuthContext } from 'Context/authContext'
import { fetchAdvertise, fetchListAdvertise } from 'Api/advertise'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { fetchBanner } from 'Api/home'

const BannerDetail = () => {
    const { token } = useContext(AuthContext)
    let { id } = useParams()
    const navigate = useNavigate()

    const [query, setQuery] = useState({
        set_title: false,
        title: '',
        set_category: false,
        // category: 1,
        category: [],
        set_tag: false,
        tags: '',
        set_status: true,
        status: 1,
        set_deleted: true,
    })

    const [options, setOptions] = useState({
        limit: 10,
        page: 1,
        order: 'created_at',
        sort: 'ASC',
    })

    const { data: advertisesData, isLoading: isLoadingAdvertisesData } =
        useQuery(
            ['banner-advertise', token],
            () => fetchBanner({ token, id }),
            {
                onSuccess: (data) => {
                },
            }
        )

    const { data: advertiseListData, isLoading: isLoadingAdvertiseListData } =
        useQuery(
            ['advertises', query, token],
            () => fetchListAdvertise({ token, query, options }),
            {
                onSuccess: (data) => {},
            }
        )

    return (
        <div className='flex pt-[100px] md:pt-[200px] max-w-screen-xl m-auto p-4 md:p-0 min-h-screen'>
            <div className='flex flex-row w-full'>
                <div className='flex flex-col gap-4'>
                    <div className='w-full'>
                        {!isLoadingAdvertisesData && (
                            <img className='mr-2 aspect-16/4' src={advertisesData.data?.banner
                                ?.thumbnail_web} alt='' />
                        )}
                    </div>
                    <div className='w-full md:w-2/3 flex flex-col space-y-4 mt-4'>
                        {!isLoadingAdvertisesData && (
                            <h1 className='text-black text-xl font-bold'>
                                {advertisesData.data?.title}
                            </h1>
                        )}

                        <div className='flex items-center gap-2'>
                            {!isLoadingAdvertisesData && (
                                <span className='badge text-white p-2 text-[8px] font-normal'>
                                    {advertisesData.data?.category_name}
                                </span>
                            )}
                            {!isLoadingAdvertisesData && (
                                <span className='text-black-five text-ss'>
                                    {moment(
                                        `${advertisesData.data?.created_at}`
                                    ).format('MMMM DD YYYY, h:mm a')}
                                </span>
                            )}
                        </div>
                        {!isLoadingAdvertisesData && (
                            <div
                                className='text-black-five text-xs'
                                dangerouslySetInnerHTML={{
                                    __html: advertisesData.data?.description,
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerDetail
