import React, { useContext, useEffect, useState } from 'react'
import GopaySvg from 'Assets/images/gopay.svg'
import BcaSvg from 'Assets/images/bca.svg'
import BriVaSvg from 'Assets/images/bri-va.svg'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { InputNumber } from 'primereact/inputnumber'
import { AuthContext } from 'Context/authContext'
import { useMutation, useQueries, useQuery } from '@tanstack/react-query'
import {
    fetchInquiry,
    fetchPaymentChannel,
    fetchPaymentMethod,
    fetchPrefix,
    fetchPriceList,
    fetchTypes,
} from 'Api/payment'
import Toast from 'Utils/toast'
import { toValue } from 'Utils/conversion'
import { fetchPoint } from 'Api/profile'

const schema = yup
    .object({
        number: yup
            .string()
            .required('Nominal is Required')
            .min(9, 'Minimum 9 digits')
            .max(12, 'Maximum 12 digits'),
    })
    .required()

const PaketData = () => {
    const navigate = useNavigate()
    const { token, newLang } = useContext(AuthContext)
    const [nominal, setNominal] = useState()
    const [brand, setBrand] = useState('')
    const [number, setNumber] = useState('')
    const [methodId, setMethodId] = useState()
    const [paymentChannel, setPaymentChannel] = useState()
    const [price, setPrice] = useState()
    const [sku, setSku] = useState()

    const [priceListState, setPriceListState] = useState([])
    const [typesState, setTypesState] = useState([])
    const [paymentMethodState, setPaymentMethodState] = useState([])
    const [paymentChannelState, setPaymentChannelState] = useState([])

    const [filter, setFilter] = useState({
        set_category: true,
        category: 'data',
        set_brand: false,
        brand: '',
        set_product_name: false,
        product_name: '',
        set_type: false,
        type: '',
        set_seller_name: false,
        seller_name: '',
        set_is_available: false,
        is_available: false,
    })

    const [options, setOptions] = useState({
        limit: 100,
        page: 1,
        order: 'created_at',
        sort: 'DESC',
    })

    useEffect(() => {
        const isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'))
        !isLoggedin && navigate('/')
    }, [])

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    // Prefix
    const { isLoading: isLoadingPrefix, mutate: mutatePrefix } = useMutation(
        fetchPrefix,
        {
            onSuccess: async (data) => {
                if (data?.message?.response_code == '00') {
                    const newFilter = {
                        ...filter,
                        set_brand: true,
                        brand: data.data.brand,
                    }
                    await setFilter(newFilter)
                    await setBrand(data.data.brand)
                    mutatePriceList({ token, filter: newFilter, options })
                    mutateTypes({
                        token,
                        brand: data.data.brand,
                        category: 'Data',
                    })
                    mutateMethod({ token })
                } else {
                    setPriceListState([])
                    setTypesState([])
                    setPaymentMethodState()
                    setPaymentChannelState([])
                    setNominal()
                    setNumber()
                    setPaymentChannel()
                    setBrand()
                }
            },
        }
    )

    // Price List
    const {
        isLoading: isLoadingPriceList,
        mutate: mutatePriceList,
        data: priceListData,
    } = useMutation(fetchPriceList, {
        onSuccess: (data) => {
            setPriceListState(data.data)
        },
    })

    // Types
    const {
        isLoading: isLoadingTypes,
        mutate: mutateTypes,
        data: typesData,
    } = useMutation(fetchTypes, {
        onSuccess: (data) => {
            setTypesState(data.data)
        },
    })

    // Payment Method
    const {
        isLoading: isLoadingMethod,
        mutate: mutateMethod,
        data: methodData,
    } = useMutation(fetchPaymentMethod, {
        onSuccess: (data) => {
            if (data?.message?.response_code == '00') {
                setPaymentMethodState(data.data)
            } else {
                setPaymentMethodState([])
            }
        },
    })

    // Payment Channel
    const channelData = useQueries({
        queries:
            methodData?.data?.map((id) => {
                return {
                    queryKey: ['payment-channel', id],
                    queryFn: () => fetchPaymentChannel({ token, id, nominal }),
                    keepPreviousData: false,
                }
            }) ?? [],
    })

    // Inquiry
    const {
        isLoading: isLoadingInquiry,
        mutate: mutateInquiry,
        data: inquiryData,
    } = useMutation(fetchInquiry, {
        onSuccess: (data) => {
            if (data?.message?.response_code == '00') {
                navigate('/detail-payment', {
                    state: {
                        channel: paymentChannel,
                        data: data.data,
                        method_id: methodId,
                    },
                })
            }
        },
    })

    // Point
    const { data: point, isLoading: isLoadingPoint } = useQuery(
        ['point', token],
        () => fetchPoint({ token }),
        {
            onSuccess: (data) => {},
        }
    )

    const handleChange = (value) => {
        mutatePrefix({ token, number: value })
        setNumber(value)
    }

    const handleChangeType = async (value) => {
        if (value != 'none') {
            const newFilter = {
                ...filter,
                set_type: true,
                type: value,
            }
            await setFilter(newFilter)
            mutatePriceList({ token, filter: newFilter, options })
        } else {
            const newFilter = {
                ...filter,
                set_type: false,
                type: '',
            }
            await setFilter(newFilter)
            mutatePriceList({ token, filter: newFilter, options })
        }
    }

    const handleCheked = async (item) => {
        await setNominal(item.sell_price)
        await setPrice(item.sell_price)
        await setSku(item.buyer_sku_code)
        // await setPaymentGateway()
        mutateMethod({ token })
    }

    const handleChangeRadio = (item, obj) => {
        setMethodId(obj.method_id)
        // setChannelId(item.id)
        if (item == 'point') {
            setPaymentChannel({
                name: `Point ${point.data}`,
            })
        } else {
            setPaymentChannel(item)
        }
    }

    const onSubmit = (data) => {
        let newNumber = data.number
        if (data.number[0] == 8) newNumber = `0${data.number}`
        const payload = {
            type_transaction: 'prepaid',
            buyer_sku_code: sku,
            destination_number: newNumber,
        }
        mutateInquiry({ token, payload })
    }

    const handleKeyPress = (e) => {
        let newNumber = e.target.value
        if (newNumber[0] == 8) newNumber = `0${newNumber}`
        if (e.key === 'Enter') {
            mutatePrefix({ token, number: newNumber })
            setNumber(newNumber)
        }
    }

    return (
        <div className='flex flex-col px-5 gap-4'>
            <div className='phone w-full flex flex-col'>
                <h2 className='text-xl font-bold'>
                    1. {newLang?.enterPhone ?? 'Enter your cellphone number'}
                </h2>
                <div className='w-full flex items-end gap-2'>
                    <div className='w-9/12 md:w-2/3'>
                        <div className='form-control w-full'>
                            <label className='label'>
                                <span className='label-text'>
                                    {newLang?.phoneNumber ?? 'Phone Number'}
                                </span>
                            </label>
                            <input
                                type='number'
                                placeholder={
                                    newLang?.phoneTextHint ??
                                    'Enter the first digit 08**'
                                }
                                className='input input-bordered w-full'
                                // onChange={(e) => handleChange(e.target.value)}
                                onKeyDown={(e) => handleKeyPress(e)}
                                pattern='[0-9.]+'
                                {...register('number', { required: true })}
                            />
                            {errors.number && (
                                <p className='text-xs text-red-600'>
                                    {errors.number.message}
                                </p>
                            )}
                            <span className='label-text'>
                                {newLang?.pleaseEnterContinue ??
                                    'Press enter to continue'}
                            </span>
                            <span className='label-text'>
                                {newLang?.phoneTextHint ??
                                    'Enter the first digit 08**'}
                            </span>
                        </div>
                    </div>
                    {brand && (
                        <div className='w-3/12 md:w-1/3'>
                            <div className='flex border-2 border-slate-300 h-12 p-2 rounded-md justify-center m-auto'>
                                {brand}
                            </div>
                        </div>
                    )}
                </div>
                <div className='w-full flex items-end gap-2 mt-3'>
                    <select
                        className='select border-2 border-slate-300 w-1/2 max-w-xs'
                        onChange={(e) => handleChangeType(e.target.value)}
                    >
                        <option selected>none</option>
                        {!isLoadingTypes &&
                            typesState?.map((item, idx) => {
                                return <option key={idx}>{item}</option>
                            })}
                    </select>
                </div>
            </div>
            <div className='nominal w-full flex flex-col'>
                <h2 className='text-xl font-bold'>
                    2. {newLang?.chooseNominal ?? 'Choose Nominal'}
                </h2>
                <div className='grid grid-cols-3 mt-2 gap-4'>
                    {!isLoadingPriceList &&
                        priceListState?.map((item, idx) => {
                            return (
                                <label
                                    className='group label w-full bg-[#F4F4F4] rounded-xl p-4 cursor-pointer gap-2 text-center'
                                    key={idx}
                                >
                                    <div className='flex w-1/12'>
                                        <input
                                            type='radio'
                                            name='radio-6 radio-price'
                                            className='radio w-5 h-5 group-checked:bg-red-400'
                                            onChange={() => handleCheked(item)}
                                        />
                                    </div>
                                    <div className='w-11/12'>
                                        <div className='flex flex-col'>
                                            <span className='text-sm font-semibold text-blue-five'>
                                                {item.product_name}
                                            </span>
                                            <span className='text-sm font-semibold text-slate-500'>
                                                {item.description}
                                            </span>
                                        </div>
                                        <div className='mt-2'>
                                            <span className='text-sm font-semibold bg-blue-five text-white rounded-full px-2 py-1'>
                                                Rp {toValue(item.sell_price)}
                                            </span>
                                        </div>
                                    </div>
                                </label>
                                // </div>
                            )
                        })}
                </div>
            </div>
            {!isLoadingMethod && !!nominal && (
                <div className='metode w-full flex flex-col gap-4'>
                    <h2 className='text-xl font-bold'>
                        3.{' '}
                        {newLang?.selectPaymentMethod ??
                            'Select Payment method'}
                    </h2>

                    {channelData?.map((item, idx) => {
                        const obj = {
                            method_id: paymentMethodState[idx]?.id,
                            name: paymentMethodState[idx]?.payment_method,
                            channels: item?.data?.data,
                        }
                        return (
                            <div
                                className='payment w-full flex flex-col gap-4'
                                key={idx}
                            >
                                <h3 className='text-xs font-bold'>
                                    {obj.name}
                                </h3>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                                    {obj.channels?.map((item, idx) => {
                                        return (
                                            <div
                                                className='form-control border-2 rounded-xl p-2 w-full md:w-auto'
                                                key={idx}
                                            >
                                                <label className='label cursor-pointer gap-2 justify-evenly'>
                                                    <div className='w-2/12 text-center'>
                                                        <input
                                                            type='radio'
                                                            name='radio-6'
                                                            className='radio w-5 h-5 checked:bg-blue-four'
                                                            onChange={() =>
                                                                handleChangeRadio(
                                                                    item,
                                                                    obj
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className='w-10/12'>
                                                        <div className='flex gap-2'>
                                                            <div className='flex flex-row gap-2 items-center'>
                                                                <img
                                                                    className='w-10'
                                                                    src={
                                                                        item
                                                                            ?.payment_gateway
                                                                            ?.manual_transfer
                                                                            ?.bank_logo_url
                                                                    }
                                                                    alt=''
                                                                />
                                                                <span className='label-text text-base font-normal'>
                                                                    {item.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        )
                                    })}
                                </div>
                                <h3 className='text-xs font-bold'>Point</h3>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                                    <div
                                        className='form-control border-2 rounded-xl p-2 w-full md:w-auto'
                                        key={idx}
                                    >
                                        <label className='label cursor-pointer gap-2 justify-evenly'>
                                            <div className='w-2/12 text-center'>
                                                <input
                                                    type='radio'
                                                    name='radio-6'
                                                    className='radio w-5 h-5 checked:bg-blue-four'
                                                    onChange={() =>
                                                        handleChangeRadio(
                                                            'point',
                                                            {
                                                                method_id:
                                                                    'point',
                                                            }
                                                        )
                                                    }
                                                    disabled={point.data <= 0}
                                                />
                                            </div>
                                            <div className='w-10/12'>
                                                <div className='flex gap-2'>
                                                    <div className='flex flex-row gap-2 items-center'>
                                                        <span className='label-text text-base font-bold'>
                                                            Point
                                                        </span>
                                                        <span className='label-text text-base font-normal'>
                                                            {toValue(
                                                                point.data
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
            <div className='mt-5'>
                <button
                    className='btn bg-blue-five text-white rounded-full min-w-[200px]'
                    onClick={handleSubmit(onSubmit)}
                    disabled={!!!number || !!!nominal || !!!paymentChannel}
                >
                    {newLang?.buyNow ?? 'Buy Now'}
                </button>
            </div>
        </div>
    )
}

export default PaketData
