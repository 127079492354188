import { Outlet } from 'react-router-dom'
import LoginDesc from 'Assets/images/login-desc.svg'

const AuthLayout = () => {
    return (
        <div className='login flex flex-row h-screen'>
            <Outlet />
            <div className='hidden md:flex flex-col justify-around items-center bg-[#F5F0F0] w-3/5 p-14'>
                <img src={LoginDesc} alt='' />
                {/* <span className='text-black font-bold text-2xl'>
                    Deskripsi 1
                </span>
                <span className='text-base text-black font-normal text-center'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam eu turpis molestie, Lorem ipsum dolor sit amet,
                    consectetur
                </span> */}
            </div>
        </div>
    )
}

export default AuthLayout
