import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    options: {
        limit: 10,
        page: 1,
        order: 'created_at',
        sort: 'DESC',
    },
}

export const transactionImeiPaging = createSlice({
    name: 'transactionImeiPaging',
    initialState,
    reducers: {
        changeImeiPaging: (state, action) => {
            state.options = {
                ...state.options,
                limit: action.payload.limit,
            }
        },
    },
})

export const { changeImeiPaging } = transactionImeiPaging.actions

export default transactionImeiPaging.reducer
