import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    is_include: false,
    goods: [],
}

export const good = createSlice({
    name: 'good',
    initialState,
    reducers: {
        addGood: (state, action) => {
            state.goods = [action.payload, ...state.goods]
            state.is_include = true
        },
        deleteGood: (state, action) => {
            state.goods = state.goods.filter(
                (good) => action.payload != good.id
            )
            if (state.goods.length == 0) state.is_include = false
        },
    },
})

export const { addGood, deleteGood } = good.actions

export default good.reducer
