import React, { useContext, useEffect, useState } from 'react'
import {
    Link,
    Outlet,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom'
import CopySvg from 'Assets/images/copy.svg'
import { isMobile } from 'react-device-detect'
import { AuthContext } from 'Context/authContext'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchProfile } from 'Api/profile'
import Toast from 'Utils/toast'
import { setLogout } from 'Api/auth'
import { useDispatch, useSelector } from 'react-redux'
import { setIsNotAuthenticated } from 'Store/authenticated'

const ProfileLayout = () => {
    let { pathname } = useLocation()
    const { token, newLang } = useContext(AuthContext)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const isLoggedin = useSelector((state) => state.authenticated.isLogin)
    const isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'))
    useEffect(() => {
        !isLoggedin && navigate('/')
    }, [])

    const listMenu = [
        {
            id: 1,
            name: newLang?.editProfile,
            link: '/profile',
        },
        {
            id: 2,
            name: newLang?.password,
            link: '/profile/forgot-password',
        },
        {
            id: 3,
            name: newLang?.transaction,
            link: '/profile/transaction',
        },
        {
            id: 4,
            name: newLang?.transactionPoint ?? 'Transaction Point',
            link: '/profile/transaction-point',
        },
        {
            id: 5,
            name: newLang?.transactionBC22 ?? 'Transaction Other',
            link: '/profile/transaction-imei',
        },
        {
            id: 6,
            name: newLang?.notification ?? 'Notification',
            link: '/profile/notification',
        },
        {
            id: 7,
            name: newLang?.favorite,
            link: '/profile/favorite',
        },
        {
            id: 8,
            name: newLang?.logout,
            link: false,
        },
    ]

    const { data: profile, isLoading: isLoadingProfile } = useQuery(
        ['profile', token],
        () => fetchProfile({ token }),
        {
            onSuccess: (data) => {},
        }
    )

    const { mutate: mutateLogout, isLoading: isLoadingLogout } = useMutation(
        setLogout,
        {
            onSuccess: (data) => {
                dispatch(setIsNotAuthenticated())
                Toast('success', data?.message?.response_message)
                navigate('/')
            },
        }
    )

    const handleLogout = () => {
        mutateLogout({ token })
    }

    return (
        <>
            <div className='pt-[100px] md:pt-[200px] max-w-screen-xl m-auto flex mb-[100px] min-h-screen'>
                {!isMobile && (
                    <div className='w-1/4 flex flex-col'>
                        <div className='user flex flex-col text-center items-center gap-5'>
                            {isLoadingProfile ? (
                                <img
                                    className='rounded-full w-32'
                                    src='https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
                                    alt=''
                                />
                            ) : (
                                <img
                                    className='rounded-full w-32'
                                    src={
                                        profile?.data?.profile_picture_image_url
                                    }
                                    alt=''
                                />
                            )}

                            <span className='text-black text-xl font-semibold'>
                                {!isLoadingProfile && profile?.data?.username}
                            </span>
                            <div className='space-y-2'>
                                <span className='text-black text-sm font-normal'>
                                    {newLang?.refferalCode}
                                </span>
                                <div className='flex justify-around'>
                                    <span className='text-black text-sm font-semibold mr-2'>
                                        {profile?.data?.referral_code}
                                    </span>
                                    <img
                                        className='cursor-pointer'
                                        src={CopySvg}
                                        alt=''
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                profile?.data?.referral_code
                                            )
                                            Toast('success', 'copied')
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='list flex flex-col m-auto w-full mt-10'>
                            <ul className='space-y-8 m-auto items-center'>
                                {listMenu.map((item) => {
                                    return (
                                        <li
                                            className='cursor-pointer flex group relative'
                                            key={item.id}
                                        >
                                            {pathname == item.link && (
                                                <div className='w-2 h-5 bg-blue-five absolute -left-8'></div>
                                            )}
                                            <div className='w-2 h-5 bg-blue-five invisible group-hover:visible absolute -left-8'></div>
                                            {item.link ? (
                                                <Link to={item.link}>
                                                    <span className='text-black hover:text-blue-five text-sm font-bold group-hover:ml-2 ease-in-out duration-300'>
                                                        {item.name}
                                                    </span>
                                                </Link>
                                            ) : (
                                                <div
                                                    onClick={() =>
                                                        handleLogout()
                                                    }
                                                >
                                                    <span className='text-black hover:text-blue-five text-sm font-bold group-hover:ml-2 ease-in-out duration-300'>
                                                        {item.name}
                                                    </span>
                                                </div>
                                            )}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                )}
                <Outlet />
            </div>
        </>
    )
}

export default ProfileLayout
