import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    families: [],
}

export const family = createSlice({
    name: 'family',
    initialState,
    reducers: {
        addFamily: (state, action) => {
            state.families = [action.payload, ...state.families]
        },
        deleteFamily: (state, action) => {
            state.families = state.families.filter(
                (item) => action.payload != item.id
            )
        },
    },
})

export const { addFamily, deleteFamily } = family.actions

export default family.reducer
